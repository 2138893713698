import { Currency } from '@uniswap/sdk'
import React from 'react'
import { ONE_BIPS } from '../../constants'
import { Field } from '../../state/mint/actions'
import { Box, Flex, Text } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export function PoolPriceBar({
  currencies,
  noLiquidity,
  poolTokenPercentage,
  price
}: {
  currencies: { [field in Field]?: Currency }
  noLiquidity?: boolean
  poolTokenPercentage?: number
  price?: number
}) {
  const { t } = useTranslation()
  return (
    <Flex width="100%" flexWrap="wrap" cursor="default">
      <Box bg="border.secondary" width={'100%'} height="1px" my="12px" />
      <Flex justify="space-between" width="100%" pb="12px">
        <Text color="font.primary" fontSize={14} fontWeight={500}>
          {currencies[Field.CURRENCY_B]?.symbol} per {currencies[Field.CURRENCY_A]?.symbol}
        </Text>
        <Text color="font.primary" fontSize={14} fontWeight={400}>
          {price?.toFixed(8) || '-'}
        </Text>
      </Flex>
      <Flex justify="space-between" width="100%" pb="12px">
        <Text color="font.primary" fontSize={14} fontWeight={500}>
          {currencies[Field.CURRENCY_A]?.symbol} per {currencies[Field.CURRENCY_B]?.symbol}
        </Text>
        <Text color="font.primary" fontSize={14} fontWeight={400}>
          {price ? (1 / price).toFixed(8) : '-'}
        </Text>
      </Flex>
      <Flex justify="space-between" width="100%">
        <Text color="font.primary" fontSize={14} fontWeight={500}>
          {t('liquidity.share_of_pool')}
        </Text>
        <Text color="font.primary" fontSize={14} fontWeight={400}>
          {noLiquidity && price
            ? '100'
            : ((poolTokenPercentage ?? 0) < ONE_BIPS ? '<0.01' : poolTokenPercentage?.toFixed(2)) ?? '0'}
          %
        </Text>
      </Flex>
    </Flex>
  )
}
