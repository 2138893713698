import React, { useMemo } from 'react'

import { Trade } from '../../fcl-react'

import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'

import { SwapCallbackError } from './styleds'
import { AdvancedSwapDetails } from './AdvancedSwapDetails'
import MainButton from '../../components/Common/MainButton'
import { Box } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

export default function SwapModalFooter({
  trade,
  onConfirm,
  allowedSlippage,
  swapErrorMessage
}: {
  trade: Trade
  allowedSlippage: number
  onConfirm: () => void
  swapErrorMessage: string | undefined
}) {
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const severity = warningSeverity(priceImpactWithoutFee)
  const { t } = useTranslation()

  return (
    <>
      <AdvancedSwapDetails trade={trade} defaultIsOpen />
      <Box mt="20px">
        <MainButton onClick={onConfirm}>
          {severity > 2 ? t('swap.button.swapAnyway') : t('swap.button.confirm')}
        </MainButton>
        {swapErrorMessage ? <SwapCallbackError error={swapErrorMessage} /> : null}
      </Box>
    </>
  )
}
