import { Web3Provider } from '@ethersproject/providers'
import { ChainId } from '../utils'
import { useWeb3React as useWeb3ReactCore } from '@web3-react/core'
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useState } from 'react'
import { NetworkContextName } from '../constants'
import React, { useCallback, useMemo, createContext, useContext } from 'react'
import BloctoSDK from '@blocto/sdk'

// workaround for connecting to sepolia
const bloctoSDK = new BloctoSDK({
  ethereum: {
    chainId: '0xaa36a7',
    rpc: 'https://sepolia.infura.io/v3/6b11bdd3c1d741f6887571aa54125f6a'
  }
})

const supportedChains = [
  {
    chainId: '0xaa36a7',
    rpcUrls: ['https://sepolia.infura.io/v3/6b11bdd3c1d741f6887571aa54125f6a']
  },
  {
    chainId: '0x61',
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545']
  }
]

bloctoSDK?.ethereum?.loadSwitchableNetwork(supportedChains)

const AccountContext = createContext({
  chainId: 0,
  account: '',
  active: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  activate: (type: 'blocto' | 'metamask', network: 'ETHEREUM' | 'BSC') => {}
} as { chainId: number; account: string; active: boolean; activate: (type: 'blocto' | 'metamask', network: 'ETHEREUM' | 'BSC') => {} })
const { Provider } = AccountContext

export const AccountContextProvider = ({ children }: { children: any }) => {
  const [chainId, setChainId] = useState(0)
  const [account, setAccount] = useState('')
  const [connectorType, setConnectorType] = useState('')

  const activate = useCallback(async (type: 'blocto' | 'metamask', network: 'ETHEREUM' | 'BSC') => {
    if (process.env.REACT_APP_NETWORK !== 'testnet') return
    setConnectorType(type)
    if (type === 'blocto') {
      await bloctoSDK.ethereum?.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network === 'ETHEREUM' ? '0xaa36a7' : '0x61' }]
      })
      const address = await bloctoSDK?.ethereum?.enable()
      setAccount(address?.[0] || '')
      setChainId(network === 'ETHEREUM' ? 11155111 : 97)
    } else {
      const address = await window.ethereum?.request({
        method: 'eth_requestAccounts'
      })
      setAccount(address[0])
      setChainId(network === 'ETHEREUM' ? 11155111 : 97)
    }
  }, [])

  const provider = connectorType === 'blocto' ? bloctoSDK?.ethereum : window.ethereum
  const value = useMemo(
    () => ({
      chainId,
      account,
      active: !!account,
      activate,
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      library: process.env.REACT_APP_NETWORK === 'testnet' && provider ? new Web3Provider(provider) : undefined
    }),
    [account, activate, chainId, provider]
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return <Provider value={value}>{children}</Provider>
}

export const useCustomEthereum = () => useContext(AccountContext)

export function useActiveWeb3React(): Web3ReactContextInterface<Web3Provider> & { chainId?: ChainId } {
  const context = useWeb3ReactCore<Web3Provider>()
  const customContext = useCustomEthereum()
  const contextNetwork = useWeb3ReactCore<Web3Provider>(NetworkContextName)
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  return customContext.active ? customContext : context.active ? context : contextNetwork
}
