import React from 'react'
import { Button } from '@chakra-ui/react'

interface ButtonMainProps {
  onClick?: () => void
  children: React.ReactNode
  background?: string
  color?: string
  innerRef?: React.RefObject<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
  variant?: string
}

const ButtonMain = ({
  children,
  onClick,
  background = 'interaction.primary',
  color = 'font.inverse',
  innerRef,
  disabled = false,
  isLoading = false,
  variant = 'solid'
}: ButtonMainProps) => {
  return (
    <>
      <Button
        onClick={onClick}
        as="button"
        width="100%"
        p="16px"
        borderRadius="12px"
        fontWeight={600}
        fontSize="16px"
        height="auto"
        color={color}
        background={background}
        justifyContent="center"
        ref={innerRef}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        disabled={disabled}
        isLoading={isLoading}
        variant={variant}
        transition=".2s all"
        pointerEvents={disabled ? 'none' : 'auto'}
        _hover={{
          transform: disabled ? '' : 'scale(0.98)',
          bg: 'interaction.primary.hovered'
        }}
        _active={{ transform: disabled ? '' : 'scale(0.96)' }}
        _focus={{
          boxShadow: 'none'
        }}
        _disabled={{
          bg: 'interaction.primary.disabled'
        }}
      >
        {children}
      </Button>
    </>
  )
}
export default ButtonMain
