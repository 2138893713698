const script = `\
import BloctoIdo from 0xIDOADDRESS

transaction() {
	prepare(user: AuthAccount) {
		user.save(<- BloctoIdo.createNewUser(), to: BloctoIdo.BloctoIdoUserStoragePath)
		user.link<&{BloctoIdo.UserPublic}>(BloctoIdo.BloctoIdoUserPublicPath, target: BloctoIdo.BloctoIdoUserStoragePath)
	}
}
`

export default script
