import React, { useState, useRef, useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import QuestionHelper from '../QuestionHelper'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import { Button, Center, Flex } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

enum SlippageError {
  InvalidInput = 'InvalidInput',
  RiskyLow = 'RiskyLow',
  RiskyHigh = 'RiskyHigh'
}

const FancyButton = styled.button`
  color: ${({ theme }) => theme.text1};
  align-items: center;
  border-radius: 9px;
  font-size: 1rem;
  width: auto;
  min-width: 3.5rem;
  border: 1px solid ${({ theme }) => theme.bg3};
  outline: none;
  background: ${({ theme }) => theme.bg1};
`

const Option = ({ active, onClick, children }: { active: boolean; onClick: () => void; children: React.ReactNode }) => (
  <Button
    as={Center}
    mr="8px"
    p="8px 16px"
    bgColor={active ? 'interaction.primary' : 'interaction.secondary'}
    color={active ? 'font.inverse' : 'font.primary'}
    onClick={onClick}
    borderRadius="8px"
    fontWeight={500}
    cursor="pointer"
    textAlign="center"
    flexGrow={1}
  >
    {children}
  </Button>
)

const Input = styled.input`
  background: ${({ theme }) => theme.bg1};
  font-size: 14px;
  width: auto;
  outline: none;
  margin-right: 3px;
  font-weight: 500;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.red1 : theme.text1)};
  text-align: right;
  ::placeholder {
    color: #bfc2c6;
  }
`

const OptionCustom = styled(FancyButton)<{ active?: boolean; warning?: boolean }>`
  position: relative;
  padding: 6px 16px;
  flex: 1;
  min-width: 100px;
  border: ${({ active, warning }) => active && `${warning ? '1.5px' : '1px'} solid ${warning ? '#FF5555' : '#E1E4E8'}`};

  input {
    width: 100%;
    height: 100%;
    border: 0px;
  }
`

export interface SlippageTabsProps {
  rawSlippage: number
  setRawSlippage: (rawSlippage: number) => void
  deadline: number
  setDeadline: (deadline: number) => void
}

export default function SlippageTabs({ rawSlippage, setRawSlippage }: SlippageTabsProps) {
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const inputRef = useRef<HTMLInputElement>()

  const [slippageInput, setSlippageInput] = useState('')

  const slippageInputIsValid =
    slippageInput === '' || (rawSlippage / 100).toFixed(2) === Number.parseFloat(slippageInput).toFixed(2)

  let slippageError: SlippageError | undefined
  if (slippageInput !== '' && !slippageInputIsValid) {
    slippageError = SlippageError.InvalidInput
  } else if (slippageInputIsValid && rawSlippage < 100) {
    slippageError = SlippageError.RiskyLow
  } else if (slippageInputIsValid && rawSlippage > 1000) {
    slippageError = SlippageError.RiskyHigh
  } else {
    slippageError = undefined
  }

  function parseCustomSlippage(value: string) {
    setSlippageInput(value)

    try {
      const valueAsIntFromRoundedFloat = Number.parseInt((Number.parseFloat(value) * 100).toString())
      if (!Number.isNaN(valueAsIntFromRoundedFloat) && valueAsIntFromRoundedFloat <= 8000) {
        setRawSlippage(valueAsIntFromRoundedFloat)
      }
    } catch {}
  }

  return (
    <AutoColumn gap="md">
      <AutoColumn gap="sm">
        <RowFixed>
          <TYPE.black fontWeight={400} fontSize={16} color={theme.text2}>
            {t('settings.slippage_tolerance')}
          </TYPE.black>
          <QuestionHelper content={t('settings.slippage_tolerance_help')} />
        </RowFixed>
        <Flex flexWrap="wrap" rowGap="16px">
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(50)
            }}
            active={rawSlippage === 50}
          >
            0.5%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(100)
            }}
            active={rawSlippage === 100}
          >
            1%
          </Option>
          <Option
            onClick={() => {
              setSlippageInput('')
              setRawSlippage(500)
            }}
            active={rawSlippage === 500}
          >
            5%
          </Option>
          <OptionCustom active={![100, 200, 500].includes(rawSlippage)} warning={!slippageInputIsValid} tabIndex={-1}>
            <RowBetween>
              {/* https://github.com/DefinitelyTyped/DefinitelyTyped/issues/30451 */}
              <Input
                ref={inputRef as any}
                placeholder={(rawSlippage / 100).toFixed(2)}
                value={slippageInput}
                onBlur={() => {
                  parseCustomSlippage((rawSlippage / 100).toFixed(2))
                }}
                onChange={e => parseCustomSlippage(e.target.value)}
                color={!slippageInputIsValid ? 'red' : ''}
              />
              %
            </RowBetween>
          </OptionCustom>
        </Flex>
        {!!slippageError && (
          <RowBetween
            style={{
              fontSize: '14px',
              paddingTop: '7px',
              color: slippageError === SlippageError.InvalidInput ? '#FF5555' : '#0075FF'
            }}
          >
            {slippageError === SlippageError.InvalidInput
              ? t('settings.slippage_tolerance_invalid')
              : slippageError === SlippageError.RiskyLow
              ? t('settings.slippage_tolerance_might_fail')
              : t('settings.slippage_tolerance_might_different')}
          </RowBetween>
        )}
      </AutoColumn>
    </AutoColumn>
  )
}
