import { useState, useEffect } from 'react'

export default function useGeoLocation(): string {
  const [location, setLocation] = useState('')

  useEffect(() => {
    fetch(`https://guide.blocto.app/v1/api/geoip?ts=${Date.now()}`)
      .then(response => response.json())
      .then(response => setLocation(response.result.ip.country))
      .catch(console.log)
  }, [])

  return location
}
