import { Steps } from './action'
import { StepState, StepID } from '../../types'
import { IDOUserInfo } from '../../fcl-react'
export default function processTimeline(timeline: Steps, userInfo: IDOUserInfo): Steps {
  const now = Date.now() / 1000
  const calcKYC = () => {
    const start = timeline.kyc.startTime
    const end = timeline.kyc.endTime
    const nextStart = timeline.pool.startTime
    switch (true) {
      case now < start:
        return {
          state: StepState.unactive,
          ongoing: false,
          open: true,
          showOptions: false,
          startTime: start,
          endTime: end
        }
      case start <= now && now < end:
        return {
          state: StepState.ongoing,
          ongoing: true,
          open: true,
          showOptions: true,
          startTime: start,
          endTime: end
        }
      case end <= now:
        if (userInfo) {
          const passed = !!Object.keys(userInfo).length
          return {
            state: passed ? StepState.passed : StepState.failed,
            ongoing: false,
            open: now < nextStart,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        }
        return {
          state: StepState.failed,
          ongoing: false,
          open: false,
          showOptions: false,
          startTime: start,
          endTime: end
        }
      default:
        return {
          state: StepState.unactive,
          ongoing: false,
          open: false,
          showOptions: false,
          startTime: start,
          endTime: end
        }
    }
  }
  const calcPool = () => {
    const start = timeline.pool.startTime
    const end = timeline.pool.endTime
    const nextStart = timeline.deposit.startTime
    if (!userInfo?.hasOwnProperty('poolType')) {
      return {
        state: StepState.unactive,
        ongoing: start <= now && now < end,
        open: start <= now && now < nextStart,
        showOptions: false,
        startTime: start,
        endTime: end
      }
    } else {
      switch (true) {
        case now < start:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        case start <= now && now < end:
          if (userInfo?.hasOwnProperty('poolType')) {
            return {
              state: StepState.ongoing,
              ongoing: true,
              open: true,
              showOptions: true,
              startTime: start,
              endTime: end
            }
          } else {
            return {
              state: StepState.ongoing,
              ongoing: true,
              open: true,
              showOptions: false,
              startTime: start,
              endTime: end
            }
          }
        case end <= now:
          const passed = !!userInfo?.poolType
          return {
            state: passed ? StepState.passed : StepState.failed,
            ongoing: false,
            open: now < nextStart,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        default:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
      }
    }
  }
  const calcDeposit = () => {
    const start = timeline.deposit.startTime
    const end = timeline.deposit.endTime
    const nextStart = timeline.complete.startTime
    if (!userInfo?.poolType || Number(userInfo.quota) === 0) {
      return {
        state: StepState.unactive,
        ongoing: start <= now && now < end,
        open: start <= now && now < nextStart,
        showOptions: false,
        startTime: start,
        endTime: end
      }
    } else {
      switch (true) {
        case now < start:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        case start <= now && now < end:
          return {
            state: StepState.ongoing,
            ongoing: true,
            open: true,
            showOptions: true,
            startTime: start,
            endTime: end
          }
        case end <= now:
          const passed = Number(userInfo?.deposited) > 0
          return {
            state: passed ? StepState.passed : StepState.failed,
            ongoing: false,
            open: now < nextStart,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        default:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
      }
    }
  }
  const calcComplete = () => {
    const start = timeline.complete.startTime
    const end = timeline.complete.endTime
    if (Number(userInfo?.deposited) === 0 || !userInfo?.deposited) {
      return {
        state: StepState.unactive,
        ongoing: start <= now && now < end,
        open: start <= now && now < end,
        showOptions: false,
        startTime: start,
        endTime: end
      }
    } else {
      const distributed = !!userInfo?.distributed
      switch (true) {
        case now < start:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        case start <= now && now < end:
          return {
            state: distributed ? StepState.passed : StepState.ongoing,
            ongoing: true,
            open: true,
            showOptions: distributed,
            startTime: start,
            endTime: end
          }
        case end <= now:
          return {
            state: distributed ? StepState.passed : StepState.failed,
            ongoing: false,
            open: true,
            showOptions: false,
            startTime: start,
            endTime: end
          }
        default:
          return {
            state: StepState.unactive,
            ongoing: false,
            open: false,
            showOptions: false,
            startTime: start,
            endTime: end
          }
      }
    }
  }

  return {
    [StepID.kyc]: calcKYC(),
    [StepID.pool]: calcPool(),
    [StepID.deposit]: calcDeposit(),
    [StepID.complete]: calcComplete()
  }
}
