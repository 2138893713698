import React, { useCallback } from 'react'
import Box from '../layout/Box'
import styled, { css } from 'styled-components'
import BLTIcon from '../../../assets/images/blt-logo.png'
import { Project } from '../../../state/IDO/action'

const Entry = styled.div`
  margin-bottom: 30px;
`

const Caption = styled.div`
  margin-bottom: 10px;
  color: #7f7f7f;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img<{ size?: number }>`
  border-radius: 50%;
  ${({ size = 24 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`

const Text = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 20px;
`

const SaleBlock = ({ iconUrl, poolConfig, price, symbol }: Project) => {
  const amounts = {
    UNLIMITED: parseFloat(poolConfig.UNLIMITED.amount),
    LIMITED: parseFloat(poolConfig.LIMITED.amount)
  }
  const admissions = {
    UNLIMITED: parseFloat(poolConfig.UNLIMITED.selectFee),
    LIMITED: parseFloat(poolConfig.LIMITED.selectFee)
  }

  const sameAmount = amounts.UNLIMITED === amounts.LIMITED
  const sameAdmission = admissions.UNLIMITED === admissions.LIMITED

  const formatPrice = useCallback((price: number) => {
    if (price > 1_000_000_000_000) {
      return `${(price / 1_000_000_000_000).toLocaleString()}T`
    } else if (price > 1_000_000_000) {
      return `${(price / 1_000_000_000).toLocaleString()}B`
    } else if (price > 1_000_000) {
      return `${(price / 1_000_000).toLocaleString()}M`
    }
    return price.toLocaleString()
  }, [])

  return (
    <Box>
      <Entry>
        <Caption>
          Tokens Offered
          {!sameAmount && (
            <>
              <br />
              (Standard / Unlimited Pool)
            </>
          )}
        </Caption>
        <Flex>
          <Icon src={iconUrl} />
          <Text>
            {sameAmount
              ? formatPrice(amounts.LIMITED + amounts.UNLIMITED)
              : `${formatPrice(amounts.LIMITED)}/${formatPrice(amounts.UNLIMITED)}`}
          </Text>
        </Flex>
      </Entry>
      <Entry>
        <Caption>Per ${symbol}</Caption>
        <Flex>
          <Icon src="https://raw.githubusercontent.com/portto/assets/main/color/flow/tusdt.svg" />
          <Text>{price.toLocaleString()} tUSDT</Text>
        </Flex>
      </Entry>
      <Entry>
        <Caption>Pay in</Caption>
        <Flex>
          <Icon src="https://raw.githubusercontent.com/portto/assets/main/color/flow/tusdt.svg" />
          <Text>tUSDT</Text>
        </Flex>
      </Entry>
      <Entry>
        <Caption>
          Admission Fee
          {!sameAdmission && (
            <>
              <br />
              (Standard / Unlimited Pool)
            </>
          )}
        </Caption>
        <Flex>
          <Icon src={BLTIcon} />
          <Text>
            {sameAdmission
              ? formatPrice(admissions.LIMITED)
              : `${formatPrice(admissions.LIMITED)} / ${formatPrice(admissions.UNLIMITED)}`}{' '}
            $BLT
          </Text>
        </Flex>
      </Entry>
    </Box>
  )
}

export default SaleBlock
