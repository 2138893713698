import { Currency } from '@uniswap/sdk'
import React from 'react'
// import styled from 'styled-components'
import CurrencyLogo from '../CurrencyLogo'
import { Flex } from '@chakra-ui/react'

// const Wrapper = styled.div<{ margin: boolean; sizeraw: number }>`
//   position: relative;
//   display: flex;
//   flex-direction: row;
//   margin-right: ${({ sizeraw, margin }) => margin && (sizeraw + 8).toString() + 'px'};
// `

interface DoubleCurrencyLogoProps {
  margin?: boolean
  size?: number
  currency0?: Currency
  currency1?: Currency
}

export default function DoubleCurrencyLogo({ currency0, currency1, size = 24 }: DoubleCurrencyLogoProps) {
  return (
    <Flex direction={'row'} gap="4px">
      {currency0 && <CurrencyLogo currency={currency0} size={size.toString() + 'px'} />}
      {currency1 && <CurrencyLogo currency={currency1} size={size.toString() + 'px'} />}
    </Flex>
  )
}
