import { useMemo, useState, useEffect } from 'react'
import { useFclReact } from './useFclReact'
import { replaceContractAddresses } from './env'
import useSwapPairs from './useSwapPairs'
import useFlowTokenMetadata from './useFlowTokenMetadata'
import { FlowTokenMetadata, PairDetail } from '../types'
import { ETHREUM_CHAIN_ID_CONFING } from '../connectors'
// @todo: move network to a global context so to make switching network easier
const NETWORK = process.env.REACT_APP_NETWORK ?? 'mainnet'
const chainId = ETHREUM_CHAIN_ID_CONFING[NETWORK]

export const scriptBuilder = (tokens: FlowTokenMetadata[], swapPairs: PairDetail[]) => {
  const importTokensSnippet = tokens.map(({ name, address }) => `import ${name} from ${address}`).join('\n')
  const importSwapPairsSnipprt = swapPairs.map(({ name, address }) => `import ${name} from ${address}`).join('\n')

  const balances: string[] = []

  const declareTokenBalancesSnippet = tokens
    .map(({ balancePath, name }, id) => {
      const refSymbol = `tokenRef${id}`
      const symbol = `tokenBalance${id}`
      balances.push(symbol)
      return `
      let ${refSymbol} = account.capabilities.borrow<&{FungibleToken.Balance}>(${balancePath})
      let ${symbol} = ${refSymbol} == nil ? 0.0 : ${refSymbol}!.balance
      `
    })
    .join('\n')
  const declareSwapPairBalancesSnippet = swapPairs
    .map(({ name }, id) => {
      const refSymbol = `swapPairRef${id}`
      const symbol = `swapPairBalance${id}`
      balances.push(symbol)
      return `
      let ${refSymbol} = account.capabilities.borrow<&{FungibleToken.Balance}>(${name}.TokenPublicBalancePath)
      let ${symbol} = ${refSymbol} == nil ? 0.0 : ${refSymbol}!.balance
      `
    })
    .join('\n')

  return `
import FungibleToken from 0xFUNGIBLETOKENADDRESS 
${importTokensSnippet}
${importSwapPairsSnipprt}

access(all) fun main(address: Address): [UFix64] {
    // Get the accounts' public account objects
    let account = getAccount(address)

    ${declareTokenBalancesSnippet}
    ${declareSwapPairBalancesSnippet}

    return [${balances.join(', ')}]
}
  `
}

export function useTokenBalances(
  address: string | undefined,
  nonce?: number | undefined
): { [tokenAddress: string]: number | undefined } {
  const { fcl, types } = useFclReact()
  const [balances, setBalances] = useState<{ [tokenAddress: string]: number | undefined }>({})
  const tokens = useFlowTokenMetadata()
  const swapPairs = useSwapPairs()
  const addressesList = useMemo<string[]>(
    () =>
      tokens
        .map(({ address }) => address)
        .concat(swapPairs.map(({ address }) => address))
        .map(address => replaceContractAddresses(address, chainId)),
    [tokens, swapPairs]
  )

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!address || !fcl) {
      const initialBalances = Object.fromEntries(addressesList.map(address => [address, undefined]))
      setBalances(initialBalances)

      return callback
    }

    let script = scriptBuilder(tokens, swapPairs)
    script = replaceContractAddresses(script, chainId)

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(address, types.Address)])])
      .then(fcl.decode)
      .then((results: [string, string, string, string, string, string, string]) => {
        isSubscribed &&
          setBalances(prev => {
            const mapping = Object.fromEntries(
              addressesList.map((address, index) => [address, parseFloat(results[index])])
            )

            return {
              ...prev,
              ...mapping
            }
          })
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [addressesList, fcl, swapPairs, tokens, types, address, nonce])

  return useMemo(() => balances, [balances])
}
