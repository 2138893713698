import React from 'react'
import styled from 'styled-components'
import { ExternalLink } from '../../theme'
import { ListItem } from './layout'
import { Image, Box, Center } from '@chakra-ui/react'

const SubHeader = styled.div`
  color: ${({ theme }) => theme.text1};
  margin-top: 10px;
  font-size: 12px;
`

export default function Option({
  link = null,
  clickable = true,
  onClick = null,
  color,
  header,
  subheader = null,
  icon,
  active = false,
  id
}: {
  link?: string | null
  clickable?: boolean
  onClick?: null | (() => void)
  color: string
  header: React.ReactNode
  subheader: React.ReactNode | null
  icon: string
  active?: boolean
  id: string
}) {
  const content = (
    <ListItem id={id} onClick={onClick} clickable={clickable && !active} active={active} justifyContent="space-between">
      <Center>
        <Image src={icon} alt="icon" boxSize="32px" mr="12px" />
        {header}
        {subheader && <SubHeader>{subheader}</SubHeader>}
      </Center>
      {active && <Box backgroundColor="#0075FF" boxSize="5px" borderRadius="5px" />}
    </ListItem>
  )
  if (link) {
    return <ExternalLink href={link}>{content}</ExternalLink>
  }

  return content
}
