import { useMemo, useState, useEffect } from 'react'
import useGeoLocation from './useGeoLocation'
import banned from '../constants/banned'
import { useUserStakedBLT } from '../fcl-react/useUserStakedBLT'

export function useIDOVerify(minStakingAmount: number) {
  const location = useGeoLocation()
  const { stakedLocked, stakedUnlocked } = useUserStakedBLT()

  const [data, setData] = useState<{
    verifyPassed: boolean
  }>({
    verifyPassed: false
  })

  useEffect(() => {
    const isBanned = banned.includes(location)
    const staked = stakedLocked + stakedUnlocked
    setData({
      verifyPassed: !isBanned && staked >= minStakingAmount
    })
  }, [location, stakedLocked, stakedUnlocked, minStakingAmount])

  return useMemo(() => data, [data])
}
