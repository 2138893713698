import { BLOCKED_PRICE_IMPACT_NON_EXPERT } from '../constants'
import { ALLOWED_PRICE_IMPACT_HIGH, ALLOWED_PRICE_IMPACT_LOW, ALLOWED_PRICE_IMPACT_MEDIUM } from '../constants'
import { Field } from '../state/swap/actions'
import { Trade } from '../fcl-react'
import { basisPointsToPercent } from './index'

// computes price breakdown for the trade
export function computeTradePriceBreakdown(trade?: Trade): { priceImpactWithoutFee?: number; realizedLPFee?: number } {
  return {
    priceImpactWithoutFee: trade?.priceImpact,
    realizedLPFee:
      (trade?.inputAmount ?? 0) *
      0.003 *
      ((trade?.route.filter(({ symbol }) => symbol !== 'FUSD' && symbol !== 'USDC').length ?? 2) - 1)
  }
}

// computes the minimum amount out and maximum amount in for a trade given a user specified allowed slippage in bips
export function computeSlippageAdjustedAmounts(
  trade: Trade | undefined,
  allowedSlippage: number
): { [field in Field]?: number } {
  const pct = basisPointsToPercent(allowedSlippage) // (1.0 * allowedSlippage) / 10000
  return {
    [Field.INPUT]: trade?.inputAmount ? trade?.inputAmount * (1 + pct) * 1.003 : undefined,
    [Field.OUTPUT]: trade?.outputAmount ? trade?.outputAmount * (1 - pct) * 0.997 : undefined
  }
}

export function warningSeverity(priceImpact: number | undefined): 0 | 1 | 2 | 3 | 4 {
  if (!((priceImpact ?? 0) < BLOCKED_PRICE_IMPACT_NON_EXPERT)) return 4
  if (!((priceImpact ?? 0) < ALLOWED_PRICE_IMPACT_HIGH)) return 3
  if (!((priceImpact ?? 0) < ALLOWED_PRICE_IMPACT_MEDIUM)) return 2
  if (!((priceImpact ?? 0) < ALLOWED_PRICE_IMPACT_LOW)) return 1
  return 0
}

export function formatExecutionPrice(trade?: Trade, inverted?: boolean): string {
  if (!trade) {
    return ''
  }
  return inverted
    ? `${(1 / trade.executionPrice).toFixed(8)} ${trade.inputCurrency.symbol} per ${trade.outputCurrency.symbol}`
    : `${trade.executionPrice.toFixed(8)} ${trade.outputCurrency.symbol} per ${trade.inputCurrency.symbol}`
}
