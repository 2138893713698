import React from 'react'
import ErrorPage from '../components/ErrorPage'
import { ReactComponent } from '../assets/images/404.svg'
import { useTranslation } from 'react-i18next'

const Maintain = () => {
  const { t } = useTranslation()
  return <ErrorPage Img={ReactComponent} title={t('notfound.title')} description={t('notfound.description')} />
}

export default Maintain
