import React, { useCallback } from 'react'
import { Trade } from '../../fcl-react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../TransactionConfirmationModal'
import TeleportModalFooter from './TeleportModalFooter'
import TeleportModalHeader from './TeleportModalHeader'
import { useTranslation } from 'react-i18next'

export default function ConfirmTeleportModal({
  trade,
  onConfirm,
  onDismiss,
  teleportErrorMessage,
  isOpen,
  attemptingTxn,
  txHash
}: {
  isOpen: boolean
  trade: Trade | undefined
  originalTrade: Trade | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  onAcceptChanges: () => void
  onConfirm: () => void
  teleportErrorMessage: string | undefined
  onDismiss: () => void
}) {
  const { t } = useTranslation()
  const modalHeader = useCallback(() => {
    return trade ? <TeleportModalHeader trade={trade} /> : null
  }, [trade])

  const modalBottom = useCallback(() => {
    return trade ? (
      <TeleportModalFooter onConfirm={onConfirm} trade={trade} teleportErrorMessage={teleportErrorMessage} />
    ) : null
  }, [onConfirm, teleportErrorMessage, trade])

  // text to show while loading
  const pendingText = t('teleport.modal.pending', {
    INPUT_AMOUNT: trade?.inputAmount?.toString(),
    INPUT_CURRENCY: trade?.inputCurrency?.symbol,
    OUTPUT_AMOUNT: trade?.outputAmount?.toString(),
    OUTPUT_CURRENCY: trade?.outputCurrency?.symbol
  })

  const confirmationContent = useCallback(
    () =>
      teleportErrorMessage ? (
        <TransactionErrorContent
          title={t('teleport.modal.teleport_failed')}
          onDismiss={onDismiss}
          message={teleportErrorMessage}
        />
      ) : (
        <ConfirmationModalContent
          title={t('teleport.modal.confirmation')}
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, teleportErrorMessage, t]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      trade={trade}
    />
  )
}
