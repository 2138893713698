import { Currency } from '@uniswap/sdk'
import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import Column from '../Column'
import CurrencyLogo from '../CurrencyLogo'
import { FadedSpan, MenuItem } from './styleds'
import { TokenInfoName } from '../../types'
import mapCoreSymbol from '../../utils/mapCoreSymbol'

function currencyKey(currency: TokenInfoName): string {
  return currency.symbol
}

function CurrencyRow({
  token,
  onSelect,
  isDisabled,
  otherSelected,
  style
}: {
  token: TokenInfoName
  onSelect: () => void
  isDisabled: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const key = currencyKey(token)

  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      onClick={() => (isDisabled ? null : onSelect())}
      disabled={isDisabled}
      selected={otherSelected}
    >
      <CurrencyLogo currency={token} size={'24px'} />
      <Column>
        <Text title={token.name} fontWeight={500}>
          {token.symbol}
        </Text>
      </Column>
      <FadedSpan />
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  tokens,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  isMainSelector
}: {
  height: number
  tokens: TokenInfoName[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: TokenInfoName) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  isMainSelector?: boolean
}) {
  const Row = useCallback(
    ({ data, index, style }) => {
      const token: TokenInfoName = data[index]
      const isDisabledfunc = () => {
        if (isMainSelector) {
          // main selector can't be disabled
          return false
        } else {
          // only return matching currency
          return mapCoreSymbol(token.symbol) !== mapCoreSymbol(otherCurrency?.symbol)
        }
      }
      const otherSelectedfunc = () => {
        return Boolean(otherCurrency && mapCoreSymbol(otherCurrency.symbol) === mapCoreSymbol(token.symbol))
      }
      const isDisabled = isDisabledfunc()
      const otherSelected = otherSelectedfunc()
      const handleSelect = () => onCurrencySelect(token)
      return (
        <CurrencyRow
          style={style}
          token={token}
          isDisabled={isDisabled}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [onCurrencySelect, otherCurrency, isMainSelector]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={tokens}
      itemCount={tokens.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
