import React from 'react'
import styled from 'styled-components'
import { RowFixed } from '../Row'
import { Center, Image } from '@chakra-ui/react'
import AscIcon from '../../assets/icon/view/sorting_hight.svg'
import DescIcon from '../../assets/icon/view/sorting_low.svg'

export const FilterWrapper = styled(RowFixed)`
  padding: 8px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.text1};
  border-radius: 8px;
  user-select: none;
  & > * {
    user-select: none;
  }
  :hover {
    cursor: pointer;
  }
`

export default function SortButton({
  toggleSortOrder,
  ascending
}: {
  toggleSortOrder: () => void
  ascending: boolean
}) {
  return (
    <Center
      cursor="pointer"
      boxSize="28px"
      borderRadius="full"
      bgColor="interaction.secondary"
      onClick={toggleSortOrder}
    >
      <Image boxSize="14px" src={ascending ? AscIcon : DescIcon} alt={ascending ? 'asc' : 'desc'} />
    </Center>
  )
}
