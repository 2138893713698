import { Contract } from '@ethersproject/contracts'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { Token, Currency, ETHER } from '@uniswap/sdk'
import { TokenAddressMap } from '../constants/lists'
import Big from 'bignumber.js'

// returns the checksummed address if the address is valid, otherwise returns false
export function isFlowAddress(value: any): string | false {
  return /^0x[\da-f]{16}$/.test(value) ? value : false
}

export const isAddress = isFlowAddress

export function isEthereumAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  SEPOLIA = 11155111
}

const ETHERSCAN_PREFIXES: { [chainId in ChainId]: string } = {
  1: '',
  3: 'ropsten.',
  4: 'rinkeby.',
  5: 'goerli.',
  42: 'kovan.',
  11155111: 'sepolia.'
}

const BSCSCAN_PREFIXES: { [key: number]: string } = {
  56: '',
  97: 'testnet.'
}

const FVS_PREFIXES: { [key: number]: string } = {
  1: 'mainnet',
  3: 'testnet',
  4: 'testnet',
  5: 'testnet',
  11155111: 'testnet',
  42: 'testnet',
  56: 'mainnet',
  97: 'testnet'
}

export function getEtherscanLink(
  chainId?: ChainId,
  data?: string,
  type?: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${ETHERSCAN_PREFIXES[chainId ?? ChainId.MAINNET] || ''}etherscan.io`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function getBSCscanLink(
  chainId?: number,
  data?: string,
  type?: 'transaction' | 'token' | 'address' | 'block'
): string {
  const prefix = `https://${BSCSCAN_PREFIXES[chainId ?? 56] || ''}bscscan.com`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'address':
    default: {
      return `${prefix}/address/${data}`
    }
  }
}

export function getFvsLink(
  chainId: ChainId | undefined,
  data: string | undefined,
  type: 'transaction' | 'token' | 'account' | 'block'
): string {
  const prefix =
    chainId === ChainId.MAINNET || Number(chainId) === 56
      ? 'https://www.flowdiver.io'
      : `https://flow-view-source.com/${FVS_PREFIXES[chainId ?? ChainId.MAINNET]}`

  switch (type) {
    case 'transaction': {
      return `${prefix}/tx/${data}`
    }
    case 'token': {
      return `${prefix}/token/${data}`
    }
    case 'block': {
      return `${prefix}/block/${data}`
    }
    case 'account':
    default: {
      return `${prefix}/account/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = address
  if (!parsed) {
    return ''
  }
  return `${parsed.slice(0, chars)}...${parsed.slice(-chars)}`
}

export function shortenENSName(name: string | null): string {
  if (!name) {
    return ''
  }

  return name.replace('.blocto.eth', '').replace('.blocto.xyz', '')
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenEthereumAddress(address: string, chars = 4): string {
  const parsed = isEthereumAddress(address)
  if (!parsed) {
    return ''
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): number {
  return (1.0 * num) / 10000.0
}

export function calculateSlippageAmount(value: number, slippage: number): [number, number] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [(value * (10000 - slippage)) / 10000, (value * (10000 + slippage)) / 10000]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract | undefined {
  if (!isEthereumAddress(address) || address === AddressZero) {
    return
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function getAptosExplorerLink(chainId: ChainId | undefined, hash: string, type: 'txn' = 'txn'): string {
  const cluster = chainId === 1 ? `?network=mainnet` : '?network=testnet'
  return `https://explorer.aptoslabs.com/${type}/${hash}${cluster}`
}

export function division(dividendValue: string | number, divisorValue: string | number): number {
  const dividend = new Big(dividendValue)
  const divisor = new Big(divisorValue)
  const result = dividend.div(divisor).toNumber()
  return result
}

export function subtract(num1: string | number, num2: string | number): number {
  const bigNum1 = new Big(num1)
  const bigNum2 = new Big(num2)
  const result = bigNum1.minus(bigNum2).toNumber()
  return result
}
export function plus(num1: string | number, num2: string | number): number {
  const bigNum1 = new Big(num1)
  const bigNum2 = new Big(num2)
  const result = bigNum1.plus(bigNum2).toNumber()
  return result
}

export const isEvmChain = (chainId: number): boolean => {
  return Object.values(ChainId).includes(chainId)
}
// @todo we want to handle this behavior in blocto-sdk
export const removeSessionAddressByChain = (chainName: string): void => {
  const storedValue = localStorage.getItem('sdk.session')
  if (storedValue === null) {
    console.error('No value found for "sdk.session" in localStorage.')
    return
  }

  try {
    const session = JSON.parse(storedValue)
    delete session?.value?.address?.[chainName]
    const updatedValue = JSON.stringify(session)

    if (!updatedValue) {
      throw new Error('Failed to stringify the updated session object.')
    }

    localStorage.setItem('sdk.session', updatedValue)
    console.log(`Removed ${chainName} address from "sdk.session" successfully.`)
  } catch (error) {
    console.error('An error occurred while removing address:', error)
  }
}
