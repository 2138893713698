import { useMemo, useState, useEffect } from 'react'
import { TokenInfo } from '../types'
import { TeleportFees } from './types'
import { useFclReact } from './useFclReact'
import getTeleportFees from './scripts/getTeleportFees'
import { replaceContractAddresses } from './env'

/**
 * Returns the best trade for the exact amount of tokens in to the given token out
 */
export function useTeleportFees(currencyIn?: TokenInfo, currencyOut?: TokenInfo): TeleportFees | undefined {
  const { fcl, types, chainId } = useFclReact()
  const [fees, setFees] = useState<TeleportFees | undefined>()

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!currencyIn?.symbol || !currencyOut?.symbol) {
      return callback
    }

    const currencyInKey = `${currencyIn?.symbol}_${currencyIn?.network}`
    const currencyOutKey = `${currencyOut?.symbol}_${currencyOut?.network}`

    const script = replaceContractAddresses(getTeleportFees[currencyInKey]?.[currencyOutKey] ?? '', chainId)

    if (!script) {
      return callback
    }

    fcl
      .send([fcl.script(script)])
      .then(fcl.decode)
      .then(([inwardFee, outwardFee]: [string, string]) => {
        isSubscribed &&
          setFees({
            inwardFee: parseFloat(inwardFee),
            outwardFee: parseFloat(outwardFee)
          })
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [currencyIn, currencyOut, fcl, types, chainId])

  return useMemo(() => fees, [fees])
}
