import { useMemo } from 'react'
import { TokenInfo, Pair } from '../types'
import { LIQUIDITY_TOKENS } from '../constants/lists'
import { usePoolAmounts } from '../fcl-react'

export enum PairState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
  INVALID
}

export function usePairs(currencies: [TokenInfo, TokenInfo][]): [PairState, Pair | null][] {
  const pairs = currencies.map(result => {
    const liquidityToken = LIQUIDITY_TOKENS[`${(result[0] as TokenInfo).address}:${(result[1] as TokenInfo).address}`]

    return {
      liquidityToken,
      token0: result[0],
      token1: result[1]
    }
  })

  const poolAmounts = usePoolAmounts(pairs.length ? pairs[0].liquidityToken : undefined)

  return useMemo(() => {
    return pairs.map(result => {
      return [
        result.liquidityToken ? PairState.EXISTS : PairState.NOT_EXISTS,
        {
          ...result,
          price: poolAmounts[result.token1.symbol ?? 'FLOW'] / poolAmounts[result.token0.symbol ?? 'tUSDT'] || undefined
        }
      ]
    })
  }, [poolAmounts, pairs])
}

export function usePair(tokenA?: TokenInfo, tokenB?: TokenInfo): [PairState, Pair | null] {
  return usePairs(tokenA && tokenB ? [[tokenA, tokenB]] : [])[0] || [PairState.INVALID, null]
}
