import React, { useRef } from 'react'
import { Text } from 'rebass'
import { useOnClickOutside } from '../../hooks/useOnClickOutside'
import { ApplicationModal } from '../../state/application/actions'
import { useModalOpen, useToggleSettingsMenu } from '../../state/application/hooks'
import { useUserTransactionTTL, useUserSlippageTolerance } from '../../state/user/hooks'
import TransactionSettings from '../TransactionSettings'
import { Popover, PopoverTrigger, PopoverContent, Box, Image, Button } from '@chakra-ui/react'
import SettingIcon from '../../assets/icon/content_object/tool.svg'
import { useTranslation } from 'react-i18next'

export default function SettingsTab() {
  const node = useRef<HTMLDivElement>()
  const open = useModalOpen(ApplicationModal.SETTINGS)
  const toggle = useToggleSettingsMenu()
  const { t } = useTranslation()

  // const theme = useContext(ThemeContext)
  const [userSlippageTolerance, setUserslippageTolerance] = useUserSlippageTolerance()

  const [ttl, setTtl] = useUserTransactionTTL()

  useOnClickOutside(node, open ? toggle : undefined)

  return (
    <Popover placement="bottom-end" offset={[27, 8]}>
      <PopoverTrigger>
        <Button>
          <Image boxSize="20px" src={SettingIcon} alt="setting" />
        </Button>
      </PopoverTrigger>
      <PopoverContent maxWidth="100vw" border="none" width="350px" boxShadow="0px 0px 20px rgba(35, 37, 40, 0.05)">
        <Box p="20px">
          <Text fontWeight={500} fontSize={16} mb="20px">
            {t('settings.title')}
          </Text>
          <TransactionSettings
            rawSlippage={userSlippageTolerance}
            setRawSlippage={setUserslippageTolerance}
            deadline={ttl}
            setDeadline={setTtl}
          />
        </Box>
      </PopoverContent>
    </Popover>
  )
}
