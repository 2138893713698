import { useMemo, useState, useEffect } from 'react'
import { useFclReact } from './useFclReact'
import { TokenInfo } from '../types'
import getTotalSupply from './scripts/getTotalSupply'
import { replaceContractAddresses } from './env'

export function useTotalSupply(token?: TokenInfo): number | undefined {
  const { fcl, chainId } = useFclReact()
  const [totalSupply, setTotalSupply] = useState<number>()

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!token?.address) {
      return callback
    }

    fcl
      .send([fcl.script(replaceContractAddresses(getTotalSupply[token?.symbol] ?? '', chainId))])
      .then(fcl.decode)
      .then((result: string) => {
        isSubscribed && setTotalSupply(parseFloat(result))
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [token, fcl, chainId])

  return useMemo(() => totalSupply, [totalSupply])
}
