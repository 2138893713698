import React from 'react'
import styled from 'styled-components'
import Title from './Title'
import { useProjectState, useAppDispatch } from '../../../state/IDO/hook'
import { StepID } from '../../../types'
import { updateOpenSection } from '../../../state/IDO/action'
import arror from '../../../assets/images/arror-expand.svg'
import SwapStepName from './SwapStepName'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import Countdown from 'react-countdown'
import { Collapse } from 'react-collapse'

const Margin = styled.div`
  margin: 3px 20px 40px 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 3px 20px 40px 12px;
  `}
`
interface ContentWrapperProps {
  show: boolean
}

const CollpaseTheme = styled.div<ContentWrapperProps>`
  opacity: ${props => (props.show ? '1' : '0')};
  transform: ${props => (props.show ? 'translateY(0)' : 'translateY(-10px)')};
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  .ReactCollapse--collapse {
    transition: height 0.5s ease-in-out;
  }
`
const ArrorWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  justify-content: center;
  margin-left: 24px;
  transform: ${props => (props.show ? 'rotate(0deg)' : 'rotate(180deg)')};
  transition: transform 0.5s ease-in-out;
  & img {
    width: 16px;
  }
`
const TimeLabel = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 15px;
`
const TimeLabelCollpase = styled.div<ContentWrapperProps>`
  max-height: ${props => (props.show ? '100px' : '0px')};
  opacity: ${props => (props.show ? '1' : '0')};
  overflow: hidden;
  transition: max-height 0.8s ease-in-out, opacity 0.8s ease-in-out;
`
const ContentWrapper = styled.div`
  padding-top: 30px;
`
const ContentDuringTime = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #141414;
  margin-bottom: 20px;
`
const ErrorBox = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #d90909;
  width: 100%;
  background: #fff8f8;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`
const HintBox = styled.div`
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  background: #f8fbff;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
`

const endInRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return 'Ended!'
  } else {
    return (
      <TimeLabel>
        End in - {days}d:{hours}h:{minutes}m:{seconds.toString().padStart(2, '0')}s
      </TimeLabel>
    )
  }
}

export default function CollapseWrapper({
  children,
  id,
  error,
  hint,
  projectName
}: {
  children: any
  id: StepID
  error?: string
  hint?: any
  projectName: string
}) {
  const thisStep = useProjectState(projectName).state[id]
  const dispatch = useAppDispatch()
  dayjs.extend(isBetween)
  const haveExactDates = thisStep.startTime && thisStep.endTime
  return (
    <Margin id={id}>
      {haveExactDates &&
        dayjs().isBetween(dayjs.unix(thisStep.startTime), dayjs.unix(thisStep.endTime)) &&
        thisStep.showOptions && (
          <TimeLabelCollpase show={thisStep.open}>
            <Countdown date={new Date(thisStep.endTime * 1000)} renderer={endInRenderer} />
          </TimeLabelCollpase>
        )}
      <Title
        onClick={() => {
          dispatch(updateOpenSection({ projectName: projectName, id: id, open: !thisStep.open }))
        }}
        style={{ cursor: 'pointer' }}
      >
        {SwapStepName(id)}
        <ArrorWrapper show={thisStep.open}>
          <img src={arror} alt="arror" />
        </ArrorWrapper>
      </Title>
      <CollpaseTheme show={thisStep.open}>
        <Collapse isOpened={thisStep.open} style={{ transition: 'height 0.5s linear' }}>
          <ContentWrapper>
            {!!error && <ErrorBox>{error}</ErrorBox>}
            {!!hint && <HintBox>{hint}</HintBox>}
            {haveExactDates && (
              <ContentDuringTime>
                {`${dayjs.unix(thisStep.startTime).format('YYYY-MM-DD HH:mm')} - ${dayjs
                  .unix(thisStep.endTime)
                  .format('YYYY-MM-DD HH:mm')} (UTC${
                  dayjs().utcOffset() < 0 ? dayjs().utcOffset() / 60 : '+' + dayjs().utcOffset() / 60
                })`}
              </ContentDuringTime>
            )}
            {children}
          </ContentWrapper>
        </Collapse>
      </CollpaseTheme>
    </Margin>
  )
}
