const scriptFlowUsdt = `\
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

access(all) fun main(): [UFix64] {
    let poolAmounts = FlowSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptFusdUsdt = `\
import FusdUsdtSwapPair from 0xFUSDUSDTSWAPPAIRADDRESS

access(all) fun main(): [UFix64] {
    let poolAmounts = FusdUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptBltUsdt = `\
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

access(all) fun main(): [UFix64] {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptRevvFlow = `\
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

access(all) fun main(): [UFix64] {
    let poolAmounts = RevvFlowSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptStarlyUsdt = `\
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS
access(all) fun main(): [UFix64] {
    let poolAmounts = StarlyUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scriptSprtUsdt = `\
import SprtUsdtSwapPair from 0xSPRT_USDT_SWAP_PAIR_ADDRESS

access(all) fun main(): [UFix64] {
    let poolAmounts = SprtUsdtSwapPair.getPoolAmounts()
    return [poolAmounts.token1Amount, poolAmounts.token2Amount]
}
`

const scripts: { [tokenName: string]: string } = {
  'FLOW:tUSDT': scriptFlowUsdt,
  'FUSD:tUSDT': scriptFusdUsdt,
  'BLT:tUSDT': scriptBltUsdt,
  'REVV:FLOW': scriptRevvFlow,
  'STARLY:tUSDT': scriptStarlyUsdt,
  'SPRT:tUSDT': scriptSprtUsdt
}

export default scripts
