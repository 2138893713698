import { useState, useEffect } from 'react'
import { TradeType } from '@uniswap/sdk'
import { TokenInfo } from '../types'
import { Trade } from './types'
import useSwapQuote from './useSwapQuote'

export function useTrade(
  amount?: number,
  currencyIn?: TokenInfo,
  currencyOut?: TokenInfo,
  isExactIn?: boolean
): Trade | undefined {
  const [trade, setTrade] = useState<Trade | undefined>()
  const getSwapQuote = useSwapQuote()

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!currencyIn?.symbol || !currencyOut?.symbol) {
      return callback
    }

    if (!amount) {
      setTrade({
        tradeType: isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
        inputCurrency: currencyIn,
        outputCurrency: currencyOut,
        route: [],
        inputAmount: 0,
        outputAmount: 0,
        executionPrice: 0,
        priceImpact: 0
      })
      return
    }

    getSwapQuote({ currencyIn, currencyOut, amount, isExactIn })
      .then(({ quote, currentPrice, route }) => {
        const executionPrice = isExactIn ? quote / amount : amount / quote
        const priceImpactParsed = (Math.max(currentPrice - executionPrice, 0) / currentPrice) * 100
        isSubscribed &&
          setTrade({
            tradeType: isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
            inputCurrency: currencyIn,
            outputCurrency: currencyOut,
            route,
            inputAmount: isExactIn ? amount : quote,
            outputAmount: isExactIn ? quote : amount,
            executionPrice: executionPrice,
            priceImpact: priceImpactParsed
          })
      })
      .catch((error: Error) => {
        console.log(error)

        setTrade({
          tradeType: isExactIn ? TradeType.EXACT_INPUT : TradeType.EXACT_OUTPUT,
          inputCurrency: currencyIn,
          outputCurrency: currencyOut,
          route: [],
          inputAmount: isExactIn ? amount : 0,
          outputAmount: isExactIn ? 0 : amount,
          executionPrice: 0,
          priceImpact: 100
        })
      })

    return callback
  }, [amount, currencyIn, currencyOut, isExactIn, getSwapQuote])

  return trade
}
