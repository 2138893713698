import React, { useCallback } from 'react'
import TransactionConfirmationModal, {
  ConfirmationModalContent,
  TransactionErrorContent
} from '../../TransactionConfirmationModal'
import TeleportModalFooter from './ModalFooter'
import { AutoColumn } from '../../Column'

function TeleportModalHeader({ type, amount, name }: { type: 'POOL' | 'DEPOSIT'; amount?: string; name?: string }) {
  return (
    <AutoColumn gap="md">{type === 'DEPOSIT' ? `Deposit ${amount || 0} tUSDT` : `${name} Pool Selected`}</AutoColumn>
  )
}

export default function ConfirmIDOActionModal({
  onConfirm,
  onDismiss,
  errorMessage,
  isOpen,
  attemptingTxn,
  txHash,
  type,
  amount,
  name
}: {
  isOpen: boolean
  attemptingTxn: boolean
  txHash: string | undefined
  onConfirm: () => void
  errorMessage: string | undefined
  onDismiss: () => void
  type: 'POOL' | 'DEPOSIT'
  amount?: string
  name?: string
}) {
  const modalHeader = useCallback(() => {
    return <TeleportModalHeader type={type} amount={amount} name={name} />
  }, [type, amount, name])

  const modalBottom = useCallback(() => {
    return <TeleportModalFooter onConfirm={onConfirm} teleportErrorMessage={errorMessage} hideEstimate />
  }, [onConfirm, errorMessage])

  // text to show while loading
  const pendingText = `Sending transaction`

  const confirmationContent = useCallback(
    () =>
      errorMessage ? (
        <TransactionErrorContent onDismiss={onDismiss} message={errorMessage} />
      ) : (
        <ConfirmationModalContent
          title="Confirm Transaction"
          onDismiss={onDismiss}
          topContent={modalHeader}
          bottomContent={modalBottom}
        />
      ),
    [onDismiss, modalBottom, modalHeader, errorMessage]
  )

  return (
    <TransactionConfirmationModal
      isOpen={isOpen}
      onDismiss={onDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
