import { createAction } from '@reduxjs/toolkit'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT'
}

export const selectCurrency = createAction<{ field: Field; currencyId: string }>('teleport/selectCurrency')
export const switchCurrencies = createAction<void>('teleport/switchCurrencies')
export const typeInput = createAction<{ field: Field; typedValue: string }>('teleport/typeInput')
export const replaceTeleportState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
}>('teleport/replaceTeleportState')
