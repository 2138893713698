import { createReducer } from '@reduxjs/toolkit'
import { loadProject, loadProjects, Project, updateIDOState, updateOpenSection, loadUserInfo } from './action'
import processTimeline from './processTimeline'
const initialState: {
  projects: {
    [name: string]: Project
  }
} = {
  projects: {}
}

export default createReducer(initialState, builder => {
  builder
    .addCase(loadProject.fulfilled, (state, action) => {
      const { name, project } = action.payload
      state.projects[name] = project
    })
    .addCase(loadProjects.fulfilled, (state, action) => {
      state.projects = action.payload
    })
    .addCase(updateIDOState, (state, action) => {
      const { projectName, timeline } = action.payload
      state.projects[projectName].timeline = timeline
    })
    .addCase(updateOpenSection, (state, action) => {
      const { projectName, id, open } = action.payload
      state.projects[projectName].timeline[id] = { ...state.projects[projectName].timeline[id], open: open }
    })
    .addCase(loadUserInfo, (state, action) => {
      const { projectName, userInfo } = action.payload
      if (state.projects[projectName]) {
        state.projects[projectName].userInfo = userInfo || {}
        state.projects[projectName].timeline = processTimeline(state.projects[projectName].timeline, userInfo)
      }
    })
})
