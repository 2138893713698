import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { darken } from 'polished'
import { useTranslation } from 'react-i18next'

import styled from 'styled-components'

import { Flex, Box, Collapse, List, ListItem as ChakraListItem, Image, Link } from '@chakra-ui/react'
import { Fade as Hamburger } from 'hamburger-react'
import WalletButton from './WalletButton'
import RecentButton from './RecentButton'
import { Mobile, Desktop } from '../Common/RWD'
import logoBeta from '../../assets/svg/bloctoswap-logo-beta.svg'

const activeClassName = 'ACTIVE'

const StyledNavLink = styled(NavLink).attrs({
  activeClassName
})`
  ${({ theme }) => theme.flexRowNoWrap}
  align-items: left;
  border-radius: 3rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: ${({ theme }) => theme.text2};
  font-size: 1rem;
  width: fit-content;
  margin: 0 16px;
  font-weight: 500;
  transition: transform 0.2s;

  &.${activeClassName} {
    border-radius: 12px;
    font-weight: 600;
    color: ${({ theme }) => theme.text1};
  }

  :hover,
  :focus {
    transform: scale(0.98);
    color: ${({ theme }) => darken(0.1, theme.text1)};
  }
  :active {
    transform: scale(0.96);
  }
`

const ListItem = ({ children, ...rest }: any) => (
  <ChakraListItem
    d="flex"
    alignItems="center"
    px="8px"
    py="16px"
    cursor="pointer"
    transition=".2s all"
    _hover={{ bg: '#f8f8f8' }}
    {...rest}
  >
    <Box width="100%">{children}</Box>
  </ChakraListItem>
)

export default function Header({ isTeleport }: { isTeleport: boolean }) {
  const { t } = useTranslation()
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <>
      <Flex
        width="100%"
        position="fixed"
        zIndex="banner"
        backgroundColor="rgba(255, 255, 255, 0.9)"
        backdropFilter="blur(20px)"
        flexDirection="column"
        boxShadow={showDropdown ? '0px 4px 4px rgba(0, 0, 0, 0.1)' : 'none'}
        transition="box-shadow 0.5s"
      >
        <Flex
          height={{ base: '77px', md: '79px' }}
          pl={{ base: '20px', md: '32px' }}
          pr={{ base: '10px', md: '32px' }}
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          borderBottom="1px solid"
          borderColor="border.tertiary"
        >
          <Link as={NavLink} to="/">
            <Image src={logoBeta} alt="bloctoswap" />
          </Link>

          <Flex alignItems="center">
            <Mobile>
              <Hamburger toggled={showDropdown} toggle={setShowDropdown} color="#232528" size={24} rounded />
            </Mobile>
            <Desktop>
              <Flex alignItems="center">
                <StyledNavLink id={`swap-nav-link`} to={'/swap'}>
                  {t('header.swap')}
                </StyledNavLink>
                <StyledNavLink
                  id={`pool-nav-link`}
                  to={'/pool'}
                  isActive={(match, { pathname }) =>
                    Boolean(match) ||
                    pathname.startsWith('/add') ||
                    pathname.startsWith('/remove') ||
                    pathname.startsWith('/create') ||
                    pathname.startsWith('/find')
                  }
                >
                  {t('header.pool')}
                </StyledNavLink>
                <StyledNavLink id={`stake-nav-link`} to={'/teleport'}>
                  {t('header.teleport')}
                </StyledNavLink>
                <StyledNavLink id={`stake-nav-link`} to={'/IDO'}>
                  {t('header.launchpad')}
                </StyledNavLink>

                <Flex>
                  <Box ml="8px">
                    <RecentButton />
                  </Box>
                  <Box ml="16px">
                    <WalletButton isTeleport={isTeleport} />
                  </Box>
                </Flex>
              </Flex>
            </Desktop>
          </Flex>
        </Flex>

        <Mobile>
          <Collapse in={showDropdown}>
            <List
              onClick={() => {
                setShowDropdown(false)
              }}
            >
              <ListItem as={StyledNavLink} width="100%" id={`swap-nav-link`} to={'/swap'}>
                {t('header.swap')}
              </ListItem>
              <ListItem
                as={StyledNavLink}
                width="100%"
                id={`pool-nav-link`}
                to={'/pool'}
                isActive={(match: any, { pathname }: any) =>
                  Boolean(match) ||
                  pathname.startsWith('/add') ||
                  pathname.startsWith('/remove') ||
                  pathname.startsWith('/create') ||
                  pathname.startsWith('/find')
                }
              >
                {t('header.pool')}
              </ListItem>
              <ListItem as={StyledNavLink} width="100%" id={`stake-nav-link`} to={'/teleport'}>
                {t('header.teleport')}
              </ListItem>
              <ListItem as={StyledNavLink} width="100%" id={`stake-nav-link`} to={'/IDO'}>
                {t('header.launchpad')}
              </ListItem>
            </List>
          </Collapse>
        </Mobile>
      </Flex>

      <Box height={{ base: '77px', md: '79px' }} />

      <Mobile>
        <Flex
          px="20px"
          height="86px"
          width="100%"
          position="fixed"
          bottom="0"
          backgroundColor="white"
          alignItems="center"
          zIndex="banner"
          justifyContent="flex-end"
        >
          <Box width={{ base: '50%', sm: 'auto' }} minWidth="fit-content" flexShrink={0} mr="8px">
            <RecentButton />
          </Box>
          <Box width={{ base: '50%', sm: 'auto' }} minWidth="fit-content" flexShrink={1}>
            <WalletButton isTeleport={isTeleport} />
          </Box>
        </Flex>
      </Mobile>
    </>
  )
}
