import { useCallback, useMemo } from 'react'
import { PairDetail, TokenInfo } from '../types'
import { AVAILABLE_ROUTES } from './constants'
import useSwapPairs from './useSwapPairs'

export interface RouterPlan {
  from: string
  to: string
  pathDetails: Array<{ from: string; to: string; pair?: PairDetail }>
  path: TokenInfo[]
}

export default function useSwapRouter() {
  const pairs = useSwapPairs()

  const plan = useCallback(
    (from, to): RouterPlan | undefined => {
      if (!from || !to) return
      const findPair = (t0: string, t1: string) =>
        pairs.find(
          ({ token0, token1 }) =>
            token0 &&
            token1 &&
            ((token0.symbol === t0 && token1.symbol === t1) || (token1.symbol === t0 && token0.symbol === t1))
        )
      const pair = findPair(from, to)
      if (pair) {
        const { token0, token1 } = pair
        const path = token0.symbol === from ? [token0, token1] : [token1, token0]
        return { from, to, pathDetails: [{ from, to, pair }], path }
      }
      const candidate = AVAILABLE_ROUTES.find(r => r.from === from && r.to === to)
      if (!candidate) return
      const pathDetails = candidate.path
        .map((token, index) => (index ? [candidate.path[index - 1], token] : []))
        .slice(1)
        .map(([_from, _to]) => ({
          from: _from.symbol,
          to: _to.symbol,
          pair: findPair(_from.symbol, _to.symbol)
        }))
      if (!pathDetails.every(d => d && d.pair)) return

      return { from, to, pathDetails, path: candidate.path }
    },
    [pairs]
  )

  return useMemo(() => ({ plan }), [plan])
}
