import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'

export function useIDOState(): AppState['ido'] {
  return useSelector<AppState, AppState['ido']>(state => state.ido)
}
export function useProjectState(projectName: string): any {
  const ido = useSelector<AppState, AppState['ido']>(state => state.ido)
  return { state: { ...ido.projects[projectName].timeline } }
}

export const useAppDispatch = () => useDispatch<AppDispatch>()
