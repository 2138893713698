import { useMemo } from 'react'
import { useFclReact } from './useFclReact'
import addLiquidity from './transactions/addLiquidity'
import { Pair } from '../types'
import { replaceContractAddresses } from './env'

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useAddLiquidity(): Function | undefined {
  const { fcl, authorization, types, chainId } = useFclReact()

  return useMemo(() => {
    return (pair: Pair, amount1In: number, amount2In: number, amount1Min: number, amount2Min: number) => {
      const isSealed = false
      return fcl
        .send([fcl.getBlock(isSealed)])
        .then(fcl.decode)
        .then((block: any) =>
          fcl.send([
            fcl.transaction(
              replaceContractAddresses(addLiquidity[pair.token0.symbol ?? ''][pair.token1.symbol ?? ''], chainId)
            ),
            fcl.args([
              fcl.arg(amount1In.toFixed(8), types.UFix64),
              fcl.arg(amount2In.toFixed(8), types.UFix64),
              fcl.arg(amount1Min.toFixed(8), types.UFix64),
              fcl.arg(amount2Min.toFixed(8), types.UFix64)
            ]),
            fcl.limit(200),
            fcl.proposer(authorization),
            fcl.authorizations([authorization, authorization]),
            fcl.payer(authorization),
            fcl.ref(block.id)
          ])
        )
        .catch((error: Error) => {
          // if the user rejected the tx, pass this along
          if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
            throw new Error('Transaction rejected.')
          } else {
            // otherwise, the error was unexpected and we need to convey that
            console.error(`Add failed`, error, addLiquidity[pair.token0.symbol ?? ''][pair.token1.symbol ?? ''])
            throw new Error(`Add failed: ${error.message}`)
          }
        })
    }
  }, [authorization, fcl, types, chainId])
}
