import React from 'react'
import Box from '../layout/Box'
import styled from 'styled-components'

const Root = styled(Box)`
  order: 2;
`
const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 30px;
`

const Rule = styled.li`
  line-height: 1.25em;
  margin-bottom: 12px;
`

const RulesBlock = ({ rules }: { rules: string[] }) => {
  return (
    <Root>
      <Title>Read the Rules</Title>
      <ol>
        {rules.map((rule, index) => (
          <Rule key={index}>{rule}</Rule>
        ))}
      </ol>
    </Root>
  )
}

export default RulesBlock
