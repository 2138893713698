import { Currency } from '@uniswap/sdk'
import React, { useState, useCallback, useRef, useEffect } from 'react'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import TeleportSearchModal from '../SearchModal/TeleportSearchModal'
import CurrencyLogo from '../CurrencyLogo'
import { Input as NumericalInput } from '../NumericalInput'
import { useTranslation } from 'react-i18next'
import { Pair, TokenInfo } from '../../types'
import { useTokenBalance } from '../../state/wallet/hooks'
import { useFclReact } from '../../fcl-react'

import { Box, Center, Image, Flex } from '@chakra-ui/react'
import ButtonSecondary from '../ButtonSecondary'
import ArrowDown from '../../assets/icon/navigation/arror_collapse.svg'

interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect?: (currency: Currency) => void
  currency?: TokenInfo | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  hideInput?: boolean
  otherCurrency?: TokenInfo | null
  id: string
  showCommonBases?: boolean
  showTeleportTokens?: boolean
  showLiquidityTokens?: boolean
  customBalanceText?: string
  isTeleport?: boolean
  isMainSelector?: boolean
  isEstimated?: boolean
}

export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  hideInput = false,
  otherCurrency,
  id,
  showCommonBases,
  showTeleportTokens,
  showLiquidityTokens,
  customBalanceText,
  isTeleport = false,
  isMainSelector = false,
  isEstimated = false
}: CurrencyInputPanelProps) {
  const { t } = useTranslation()
  const balanceRef = useRef<HTMLDivElement>(null)
  const [banlanceHeight, setBanlanceHeight] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const { account } = useFclReact()
  const selectedCurrencyBalance = useTokenBalance(currency ?? undefined)
  const showMaxBtn = account && currency && selectedCurrencyBalance !== 0 && showMaxButton && label !== 'To'

  const handleDismissSearch = useCallback(() => {
    setModalOpen(false)
  }, [setModalOpen])

  const switchNetName = useCallback((name: string) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
  }, [])

  useEffect(() => {
    if (balanceRef.current !== null && account && !hideBalance && !!currency && selectedCurrencyBalance != null) {
      setBanlanceHeight(((balanceRef.current as unknown) as HTMLElement).offsetHeight)
    }
  }, [account, hideBalance, currency, balanceRef, selectedCurrencyBalance])

  return (
    <>
      <Box id={id} p="16px" border="1px solid" borderColor="border.secondary" borderRadius="12px">
        <Box
          mb="20px"
          fontSize="14px"
          color="font.tertiary"
          justifyContent="space-between"
          display={banlanceHeight > 40 ? 'grid' : 'flex'}
          gap={banlanceHeight > 40 ? '8px' : ''}
          gridAutoFlow={banlanceHeight > 40 ? 'column' : ''}
        >
          <Box>{label || t('currency.label_input')}</Box>
          {account && !hideBalance && !!currency && selectedCurrencyBalance != null && (
            <Center
              onClick={onMax}
              ref={balanceRef}
              maxW={banlanceHeight > 40 ? '175px' : 'auto'}
              fontSize={banlanceHeight > 40 ? '12px' : '14px'}
            >
              <Box w={banlanceHeight > 40 ? '90%' : 'auto'}>
                {(customBalanceText ?? t('currency.balance')) + selectedCurrencyBalance?.toString()}
              </Box>
              {showMaxBtn && (
                <Box ml="8px">
                  <ButtonSecondary p="4px 12px" onClick={onMax}>
                    {t('currency.max')}
                  </ButtonSecondary>
                </Box>
              )}
            </Center>
          )}
        </Box>

        <Center width="100%">
          {!hideInput && (
            <>
              {isEstimated && !!value && (
                <Box fontWeight={600} fontSize="24px">
                  ≈
                </Box>
              )}
              <NumericalInput
                className="token-amount-input"
                value={value}
                onUserInput={val => {
                  onUserInput(val)
                }}
              />
            </>
          )}
          <ButtonSecondary
            background={disableCurrencySelect ? 'white' : undefined}
            disabled={disableCurrencySelect}
            onClick={() => {
              if (!disableCurrencySelect) {
                setModalOpen(true)
              }
            }}
          >
            <Center>
              {pair ? null : currency ? <CurrencyLogo currency={currency} size={'24px'} /> : null}
              {pair ? (
                <Center>
                  <CurrencyLogo currency={pair.token0} size={'24px'} style={{ margin: '0 4px' }} />
                  {pair?.token0.symbol}:
                  <CurrencyLogo currency={pair.token1} size={'24px'} style={{ margin: '0 4px' }} />
                  {pair?.token1.symbol}
                </Center>
              ) : (
                <Flex flexDirection="column" ml="8px" alignItems="flex-start">
                  <Box fontSize="14px">
                    {(currency && currency.symbol && currency.symbol.length > 20
                      ? currency.symbol.slice(0, 4) +
                        '...' +
                        currency.symbol.slice(currency.symbol.length - 5, currency.symbol.length)
                      : currency?.symbol) || t('currency.selectToken')}
                  </Box>
                  {isTeleport ? (
                    <Box color="font.secondary" fontSize="12px" fontWeight="400">
                      {currency?.network && switchNetName(currency.network)}
                    </Box>
                  ) : null}
                </Flex>
              )}
              {!disableCurrencySelect && (
                <Box ml="8px">
                  <Image src={ArrowDown} boxSize="13px" />
                </Box>
              )}
            </Center>
          </ButtonSecondary>
        </Center>
      </Box>

      {!disableCurrencySelect &&
        onCurrencySelect &&
        (isTeleport ? (
          <TeleportSearchModal
            isOpen={modalOpen}
            onDismiss={handleDismissSearch}
            onCurrencySelect={onCurrencySelect}
            otherSelectedCurrency={otherCurrency}
            isMainSelector={isMainSelector}
          />
        ) : (
          <CurrencySearchModal
            isOpen={modalOpen}
            onDismiss={handleDismissSearch}
            onCurrencySelect={onCurrencySelect}
            selectedCurrency={currency}
            otherSelectedCurrency={otherCurrency}
            showCommonBases={showCommonBases}
            showTeleportTokens={showTeleportTokens}
            showLiquidityTokens={showLiquidityTokens}
          />
        ))}
    </>
  )
}
