import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { TransactionResponse } from '@ethersproject/providers'
import { useCallback, useMemo } from 'react'
import { TELEPORT_ADDRESS_MAP } from '../constants'
import { Trade } from '../fcl-react'
import { useTokenAllowance } from '../data/Allowances'
import {
  useTransactionAdder as useETHTransactionAdder,
  useHasPendingApproval as useETHHasPendingApproval
} from '../state/transactionsEthereum/hooks'
import {
  useTransactionAdder as useBSCTransactionAdder,
  useHasPendingApproval as useBSCHasPendingApproval
} from '../state/transactionsBsc/hooks'
import { calculateGasMargin } from '../utils'
import { useTokenContract } from './useContract'
import { useActiveWeb3React } from './index'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  PENDING,
  APPROVED
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export function useApproveCallback(trade?: Trade, spender?: string): [ApprovalState, () => Promise<void>] {
  const { account, chainId } = useActiveWeb3React()
  const token = trade?.inputCurrency
  const amountToApprove = BigNumber.from(trade?.inputAmount.toFixed(trade.inputCurrency.decimals).replace('.', '') ?? 0)
  const currentAllowance = useTokenAllowance(token, account ?? undefined, spender)

  const isBSC = (chainId as number) === 56 || (chainId as number) === 97
  const useHasPendingApproval = isBSC ? useBSCHasPendingApproval : useETHHasPendingApproval
  const pendingApproval = useHasPendingApproval(token?.address, spender)

  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amountToApprove || !spender) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.lt(amountToApprove)
      ? pendingApproval
        ? ApprovalState.PENDING
        : ApprovalState.NOT_APPROVED
      : ApprovalState.APPROVED
  }, [amountToApprove, currentAllowance, pendingApproval, spender])

  const tokenContract = useTokenContract(token?.address)
  const addETHTransaction = useETHTransactionAdder()
  const addBSCTransaction = useBSCTransactionAdder()
  const addTransaction = isBSC ? addBSCTransaction : addETHTransaction

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!tokenContract) {
      console.error('tokenContract is null')
      return
    }

    if (!amountToApprove) {
      console.error('missing amount to approve')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }

    let useExact = false
    const estimatedGas = await tokenContract.estimateGas.approve(spender, MaxUint256).catch(() => {
      // general fallback for tokens who restrict approval amounts
      useExact = true
      return tokenContract.estimateGas.approve(spender, amountToApprove.toString())
    })

    return tokenContract
      .approve(spender, useExact ? amountToApprove.toString() : MaxUint256, {
        gasLimit: calculateGasMargin(estimatedGas)
      })
      .then((response: TransactionResponse) => {
        addTransaction(response, {
          summary: 'Approve ' + token.symbol,
          approval: { tokenAddress: token.address, spender: spender }
        })
      })
      .catch((error: Error) => {
        console.debug('Failed to approve token', error)
        throw error
      })
  }, [approvalState, token, tokenContract, amountToApprove, spender, addTransaction])

  return [approvalState, approve]
}

// wraps useApproveCallback in the context of a teleport
export function useApproveCallbackFromTrade(trade?: Trade) {
  return useApproveCallback(
    trade,
    TELEPORT_ADDRESS_MAP[trade?.inputCurrency.address || '0xdAC17F958D2ee523a2206206994597C13D831ec7']
  )
}
