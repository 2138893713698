import React from 'react'
import { Price } from '../../types'
import { Flex, Center, Image, Button } from '@chakra-ui/react'
import ChangeIcon from '../../assets/icon/view/change.svg'

interface TradePriceProps {
  price?: Price
  showInverted: boolean
  setShowInverted: (showInverted: boolean) => void
}

export default function TradePrice({ price, showInverted, setShowInverted }: TradePriceProps) {
  const formattedPrice = !price?.price
    ? null
    : showInverted
    ? (price?.price ?? 0).toFixed(4)
    : (1.0 / (price?.price ?? 1)).toFixed(4)

  const show = Boolean(price?.baseCurrency && price?.quoteCurrency)
  const label = showInverted
    ? `${price?.quoteCurrency?.symbol} per ${price?.baseCurrency?.symbol}`
    : `${price?.baseCurrency?.symbol} per ${price?.quoteCurrency?.symbol}`

  return (
    <Flex fontWeight={400} fontSize="14px" style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
      {show ? (
        <>
          {formattedPrice ?? '-'} {label}
          <Button>
            <Center
              ml="8px"
              onClick={() => setShowInverted(!showInverted)}
              boxSize="28px"
              borderRadius="full"
              bgColor="interaction.secondary"
            >
              <Image src={ChangeIcon} boxSize="14px" />
            </Center>
          </Button>
        </>
      ) : (
        '-'
      )}
    </Flex>
  )
}
