import React from 'react'
import { Step, StepsContainer } from './layout/Steps'
import styled from 'styled-components'
import Title from './layout/Title'
import { StepID } from '../../types'

const StepMargin = styled.div`
  margin-top: 34px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`
const RoundButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  background: #f9f9f9;
  border-radius: 20px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #141414;
  text-decoration: none;
`

export default function IDOJourney({ metadata }: { metadata: any }) {
  const shouldHideSubscribe = window?.ethereum?.isBlocto && process.env.REACT_APP_NETWORK !== 'testnet'
  return (
    <>
      <TitleContainer>
        <div>
          <Title>Start your Journey</Title>
          <p>Subscribe and add reminders to your calendar now!</p>
        </div>
        {shouldHideSubscribe || (
          <RoundButton href={metadata?.calendarUrl || metadata?.website} target="_blank" rel="noopener noreferrer">
            Subscribe
          </RoundButton>
        )}
      </TitleContainer>

      <StepMargin>
        <StepsContainer>
          <Step id={StepID.kyc} projectName={metadata.name} />
          <Step id={StepID.pool} projectName={metadata.name} />
          <Step id={StepID.deposit} projectName={metadata.name} />
          <Step id={StepID.complete} projectName={metadata.name} />
        </StepsContainer>
      </StepMargin>
    </>
  )
}
