import styled from 'styled-components'

export default styled.h2`
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  margin: 0;
`
