import React, { useCallback, useEffect, useState } from 'react'
import Box from './layout/Box'
import dayjs from 'dayjs'
import styled, { css } from 'styled-components'
import StatusLabel from './StatusLabel'
import { NavLink } from 'react-router-dom'
import { Project } from '../../state/IDO/action'

const StyledNavLink = styled(NavLink)`
  display: inline-block;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
  padding: 10px 30px;

  background: #3398ff;
  border-radius: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    margin-top: 32px;
    text-align: center;
  `};
`

const Block = styled(Box)`
  padding: 40px 30px;
`

const Header = styled.div`
  display: flex;
  align-items: start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `};
`

const Time = styled.div`
  flex: 1;
  margin-bottom: 32px;
  order: 1;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 20px 0;
    order: 2;
  `};
`

const RelativeTime = styled.div`
  margin-bottom: 10px;
  font-weight: bold;
`

const AbsoluteTime = styled.div`
  color: #7f7f7f;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.75rem;
  margin-left: 1.25rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 16px 0;
    margin-left: 16px;
  `}
`

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img<{ size?: number }>`
  border-radius: 50%;
  ${({ size = 24 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`

const Description = styled.p`
  color: #7f7f7f;
`

const Info = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin: 0;
  `}
`

const InfoCard = styled.div`
  background: #ffffff;
  border: 1px solid #f9f9f9;
  border-radius: 10px;
  padding: 16px 12px;
  margin: 5px;
  flex: 1;

  & > div:first-child {
    color: #7f7f7f;
    margin-bottom: 8px;
  }
  & > div:last-child {
    font-weight: bold;
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 4px 0;
    display: flex;
    justify-content: space-between;
  `}
`

const CustomStatusLabel = styled(StatusLabel)`
  order: 2;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    order: 1
  `};
`

const ProjectListItem = ({ name, price, symbol, descriptions, start, end, status, iconUrl, poolConfig }: Project) => {
  const amounts = {
    UNLIMITED: parseFloat(poolConfig.UNLIMITED.amount),
    LIMITED: parseFloat(poolConfig.LIMITED.amount)
  }
  const admissions = {
    UNLIMITED: parseFloat(poolConfig.UNLIMITED.selectFee),
    LIMITED: parseFloat(poolConfig.LIMITED.selectFee)
  }

  const sameAmount = amounts.UNLIMITED === amounts.LIMITED
  const sameAdmission = admissions.UNLIMITED === admissions.LIMITED
  const offset = (new Date().getTimezoneOffset() * -1) / 60
  const utc = `UTC${offset >= 0 ? '+' : '-'}${offset}`

  const [now, setNow] = useState(Date.now())
  const formatPrice = useCallback((price: number) => {
    if (price > 1_000_000_000_000) {
      return `${(price / 1_000_000_000_000).toLocaleString()}T`
    } else if (price > 1_000_000_000) {
      return `${(price / 1_000_000_000).toLocaleString()}B`
    } else if (price > 1_000_000) {
      return `${(price / 1_000_000).toLocaleString()}M`
    }
    return price.toLocaleString()
  }, [])

  const ralativeTime = useCallback(
    target => {
      let diff = dayjs(target * 1000).diff(now)
      diff /= 1000
      const days = Math.floor(diff / 86400)
      diff %= 86400
      const hours = Math.floor(diff / 3600)
        .toString()
        .padStart(2, '0')
      diff %= 3600
      const minutes = Math.floor(diff / 60)
        .toString()
        .padStart(2, '0')
      diff %= 60
      const seconds = Math.floor(diff)
        .toString()
        .padStart(2, '0')
      return `${days}d:${hours}h:${minutes}m:${seconds}s`
    },
    [now]
  )

  useEffect(() => {
    const interval = setInterval(() => setNow(Date.now()), 1000)
    return () => clearInterval(interval)
  }, [])

  return (
    <Block>
      <Header>
        <Time>
          {start && end && (
            <RelativeTime>
              {now >= start * 1000
                ? now >= end * 1000
                  ? `Ended`
                  : `End in - ${ralativeTime(end)}`
                : `Start in - ${ralativeTime(start)}`}
            </RelativeTime>
          )}
          <AbsoluteTime>
            {start ? dayjs(new Date(start * 1000)).format('YYYY-MM-DD HH:mm') : '?'} ~{' '}
            {end ? dayjs(new Date(end * 1000)).format('YYYY-MM-DD HH:mm') : '?'} ({utc})
          </AbsoluteTime>
        </Time>
        <CustomStatusLabel status={status}>{status}</CustomStatusLabel>
      </Header>
      <TitleGroup>
        <Icon src={iconUrl} size={40} />
        <Title>{name} Launchpool</Title>
      </TitleGroup>
      <Description>{descriptions[0]}</Description>
      <Info>
        <InfoCard>
          <div>Requirement</div>
          <div>KYC at {name}</div>
        </InfoCard>
        <InfoCard>
          <div>Tokens Offered</div>
          <div>
            {sameAmount
              ? formatPrice(amounts.LIMITED + amounts.UNLIMITED)
              : `${formatPrice(amounts.LIMITED)} ~ ${formatPrice(amounts.UNLIMITED)}`}
          </div>
        </InfoCard>
        <InfoCard>
          <div>Per ${symbol}</div>
          <div>{price.toLocaleString()} tUSDT</div>
        </InfoCard>
        <InfoCard>
          <div>Pay in</div>
          <div>tUSDT</div>
        </InfoCard>

        <InfoCard>
          <div>Admission Fee</div>
          <div>
            {sameAdmission
              ? formatPrice(admissions.LIMITED)
              : `${formatPrice(admissions.LIMITED)}~${formatPrice(admissions.UNLIMITED)}`}{' '}
            $BLT
          </div>
        </InfoCard>
      </Info>
      <StyledNavLink to={`/IDO/${name}`}>{name} Launchpool Detail</StyledNavLink>
    </Block>
  )
}

export default ProjectListItem
