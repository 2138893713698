import { ChainId } from '../utils'
import { Network } from '../types'

export enum TeleportStatus {
  NOTFOUND = 'not_found',
  PENDING_APPROVAL = 'pending_approval',
  PENDING = 'pending',
  SENT = 'send',
  CONFIRMED = 'confirmed'
}

export interface TeleportResult {
  status: TeleportStatus
  tx_hash: string
}

const BLOCTO_API_URL: { readonly [chainId in ChainId]: string } = {
  [ChainId.MAINNET]: 'https://api.blocto.app',
  [ChainId.RINKEBY]: 'https://api-dev.blocto.app',
  [ChainId.ROPSTEN]: 'https://api-dev.blocto.app',
  [ChainId.GÖRLI]: 'https://api-dev.blocto.app',
  [ChainId.KOVAN]: 'https://api-dev.blocto.app',
  [ChainId.SEPOLIA]: 'https://api-dev.blocto.app'
}

export const getTeleportStatus = async (
  chainId: ChainId,
  source: Network,
  hash: string,
  token?: string
): Promise<TeleportResult> => {
  try {
    const baseUrl = `${BLOCTO_API_URL[chainId]}/checkTeleportTxStatus`
    const query = `?blockchain=${source.toLowerCase()}&txHash=${hash}${token ? `&token=${token}` : ''}`
    const result: TeleportResult = await fetch(`${baseUrl}${query}`, {
      method: 'GET',
      mode: 'cors',
      headers: { accept: 'application/json' }
    }).then(res => res.json())
    return result
  } catch (error) {
    console.log(error)
    return {
      status: TeleportStatus.NOTFOUND,
      // eslint-disable-next-line
      tx_hash: ''
    }
  }
}
