import { createReducer } from '@reduxjs/toolkit'
import { updateBalances } from './actions'

export interface WalletState {
  readonly balances: { [tokenName: string]: number | undefined }
}

const initialState: WalletState = {
  balances: {}
}

export default createReducer<WalletState>(initialState, builder =>
  builder.addCase(updateBalances, (state, { payload: balances }) => {
    return {
      ...state,
      balances
    }
  })
)
