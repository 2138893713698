import React, { useEffect } from 'react'
import styled from 'styled-components'
import ProjectListItem from '../../components/IDO/ProjectListItem'
import { useIDOAPI } from '../../fcl-react/useIDOAPI'
import { loadProjects } from '../../state/IDO/action'
import { useAppDispatch, useIDOState } from '../../state/IDO/hook'
import { Center } from '@chakra-ui/react'
import { ReactComponent as EmptyIcon } from '../../assets/images/no-transfer.svg'

export const Root = styled.div`
  width: 100%;
  max-width: 1020px;
  padding: 10px;
  min-height: calc(100vh - 650px);
`
export default function IDOList() {
  const { getProjectConfigs } = useIDOAPI()
  const { projects } = useIDOState()
  const dispatch = useAppDispatch()

  useEffect(() => {
    // always scroll to top when visit
    window.scrollTo({ top: 0 })
  }, [])

  useEffect(() => {
    let promise: any
    getProjectConfigs().then(configs => {
      const isStillListPage = window.location.hash === '#/IDO/' || window.location.hash === '#/IDO'
      if (isStillListPage) promise = dispatch(loadProjects(configs))
    })
    return () => {
      if (promise) promise.abort()
    }
  }, [getProjectConfigs, dispatch])

  return (
    <Root>
      {!Object.values(projects).length && (
        <Center my="100px" flexDirection="column" color="font.tertiary">
          <EmptyIcon />
          <Center mt="20px">No content yet</Center>
        </Center>
      )}
      {Object.values(projects).map(project => (
        <ProjectListItem key={project.name} {...project} />
      ))}
    </Root>
  )
}
