import React from 'react'
import { Box, Slide, ScaleFade, useOutsideClick, Button } from '@chakra-ui/react'
import { Mobile, Desktop } from '../Common/RWD'

const HeaderButton = ({
  children,
  modal,
  disclosure,
  variant = 'primary',
  color,
  width
}: {
  children: React.ReactNode
  modal: React.ReactNode
  disclosure: any
  variant: string
  color?: string
  width?: string
}) => {
  const ref = React.useRef() as React.MutableRefObject<HTMLInputElement>
  const { isOpen, onToggle, onClose } = disclosure
  useOutsideClick({
    ref: ref,
    handler: onClose
  })

  return (
    <Box position="relative">
      <Button onClick={onToggle} size="rounded" variant={variant} color={color} width={width}>
        {children}
      </Button>

      <Desktop>
        <ScaleFade initialScale={0.9} in={isOpen} unmountOnExit ref={ref}>
          <Box
            borderRadius="12px"
            overflow="auto"
            mt="34px"
            position="absolute"
            right="0"
            width="450px"
            maxHeight="calc(100vh - 100px)"
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
          >
            {modal}
          </Box>
        </ScaleFade>
      </Desktop>
      <Mobile>
        <Slide direction="bottom" in={isOpen} style={{ zIndex: '1300' }} unmountOnExit ref={ref}>
          <Box
            maxHeight="calc(100vh - 100px)"
            borderRadius="12px 12px 0px"
            overflow="auto"
            mt="34px"
            boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
          >
            {modal}
          </Box>
        </Slide>
      </Mobile>
    </Box>
  )
}
export default HeaderButton
