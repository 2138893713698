import React, { useCallback, useContext, useEffect } from 'react'
import { X } from 'react-feather'
import styled, { ThemeContext } from 'styled-components'
import { PopupContent } from '../../state/application/actions'
import { useRemovePopup } from '../../state/application/hooks'
import TransactionPopup from './TransactionPopup'

export const StyledClose = styled(X)`
  position: absolute;
  right: 10px;
  top: 10px;

  :hover {
    cursor: pointer;
  }
`
export const Popup = styled.div`
  display: inline-block;
  width: 100%;
  padding: 16px;
  background-color: white;
  position: relative;
  border-radius: 12px;
  padding: 16px;
  overflow: hidden;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: 290px;
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  `};
`

export default function PopupItem({
  removeAfterMs,
  content,
  popKey
}: {
  removeAfterMs: number | null
  content: PopupContent
  popKey: string
}) {
  const removePopup = useRemovePopup()
  const removeThisPopup = useCallback(() => removePopup(popKey), [popKey, removePopup])
  useEffect(() => {
    if (removeAfterMs === null) return undefined

    const timeout = setTimeout(() => {
      removeThisPopup()
    }, removeAfterMs)

    return () => {
      clearTimeout(timeout)
    }
  }, [removeAfterMs, removeThisPopup])

  const theme = useContext(ThemeContext)

  let popupContent
  if ('txn' in content) {
    const {
      txn: { hash, success, summary, network }
    } = content
    popupContent = <TransactionPopup hash={hash} success={success} summary={summary} network={network} />
  }

  return (
    <Popup>
      {!removeAfterMs && <StyledClose color={theme.text2} onClick={removeThisPopup} />}
      {popupContent}
    </Popup>
  )
}
