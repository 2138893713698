const script = `\
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS).capabilities
    .borrow<&{TeleportedTetherToken.TeleportUser}>(/public/teleportedTetherTokenTeleportUser)
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptBEP20BLT = `\
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS).capabilities
      .borrow<&{TeleportCustodyBSC.TeleportUser}>(TeleportCustodyBSC.TeleportAdminTeleportUserPath)
       ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`
const scriptETHEREUMBLT = `\
import TeleportCustodyEthereum from 0xTELEPORT_ETHEREUM_BLT_ADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS).capabilities
      .borrow<&{TeleportCustodyEthereum.TeleportUser}>(TeleportCustodyEthereum.TeleportAdminTeleportUserPath)
       ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptAPTOSBLT = `\
import TeleportCustodyAptos from 0xTELEPORTAPTOSADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS).capabilities
      .borrow<&{TeleportCustodyAptos.TeleportUser}>(TeleportCustodyAptos.TeleportAdminTeleportUserPath)
       ?? panic("failed to borrow TeleportCustodyAptos.TeleportUser")

  return [teleportUserRef.unlockFee, teleportUserRef.lockFee]
}
`

const scriptREVV = `\
import TeleportCustody from 0xREVVTELEPORTCUSTODYADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORTREVVADMINADDRESS).capabilities
    .borrow<&{TeleportCustody.TeleportUser}>(TeleportCustody.TeleportUserPublicPath)
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const scriptSPRT = `\
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

// This script reads the Vault balances of two accounts.
access(all) fun main(): [UFix64] {
  let teleportUserRef = getAccount(0xTELEPORT_SPRT_ADMIN_ADDRESS).capabilities
    .borrow<&{TeleportedSportiumToken.TeleportUser}>(/public/TeleportedSportiumTokenTeleportUser)
      ?? panic("Could not borrow a reference to Receiver")

  return [teleportUserRef.inwardFee, teleportUserRef.outwardFee]
}
`

const sciprts: { [token1Name: string]: { [token2Name: string]: string } } = {
  USDT_ETHEREUM: {
    // eslint-disable-next-line
    tUSDT_FLOW: script
  },
  // eslint-disable-next-line
  tUSDT_FLOW: {
    USDT_ETHEREUM: script
  },
  BLT_FLOW: {
    BLT_BSC: scriptBEP20BLT,
    BLT_APTOS: scriptAPTOSBLT,
    BLT_ETHEREUM: scriptETHEREUMBLT
  },
  BLT_BSC: {
    BLT_FLOW: scriptBEP20BLT
  },
  BLT_APTOS: {
    BLT_FLOW: scriptAPTOSBLT
  },
  BLT_ETHEREUM: {
    BLT_FLOW: scriptETHEREUMBLT
  },
  REVV_FLOW: {
    REVV_ETHEREUM: scriptREVV
  },
  REVV_ETHEREUM: {
    REVV_FLOW: scriptREVV
  },
  SPRT_ETHEREUM: {
    SPRT_FLOW: scriptSPRT
  },
  SPRT_FLOW: {
    SPRT_ETHEREUM: scriptSPRT
  }
}

export default sciprts
