import { configureStore } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'

import application from './application/reducer'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactionsFlow from './transactionsFlow/reducer'
import transactionsEthereum from './transactionsEthereum/reducer'
import transactionsBsc from './transactionsBsc/reducer'
import transactionsAptos from './transactionsAptos/reducer'
import swap from './swap/reducer'
import teleport from './teleport/reducer'
import wallet from './wallet/reducer'
import mint from './mint/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import ido from './IDO/reducer'
import thunk, { ThunkMiddleware } from 'redux-thunk'

const PERSISTED_KEYS: string[] = [
  'user',
  'transactionsFlow',
  'transactionsEthereum',
  'transactionsBsc',
  'transactionsAptos'
]

const store = configureStore({
  reducer: {
    application,
    user,
    transactionsFlow,
    transactionsEthereum,
    transactionsBsc,
    transactionsAptos,
    swap,
    teleport,
    wallet,
    mint,
    burn,
    multicall,
    ido
  },
  middleware: [thunk as ThunkMiddleware, save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS })
})

store.dispatch(updateVersion())

export default store

export type AppState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
