import styled from 'styled-components'

export default styled.div`
  position: relative;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.05);
  border-radius: 20px;
  margin: 10px;
  padding: 40px 30px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    border-radius: 0;
    margin: 0;
    box-shadow: none;
    border-bottom: 1px solid #eee;
    padding: 40px 16px;
  `}
`
