import { TradeType } from '@uniswap/sdk'
import React from 'react'
import { Field } from '../../state/swap/actions'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { Trade } from '../../fcl-react'
import { computeSlippageAdjustedAmounts, computeTradePriceBreakdown } from '../../utils/prices'
import QuestionHelper from '../QuestionHelper'
import FormattedPriceImpact from './FormattedPriceImpact'
import { Flex, Box, Collapse, Center, useDisclosure, Image } from '@chakra-ui/react'
import CurrencyLogo from '../CurrencyLogo'
import ArrowIcon from '../../assets/icon/navigation/arror_collapse.svg'
import { useTranslation } from 'react-i18next'

const Dots = () => (
  <Box
    flex={1}
    h="1px"
    mx="12px"
    backgroundImage="linear-gradient(to right, transparent 50%, #E1E4E8 50%)"
    backgroundSize="4px 100%"
    _last={{
      display: 'none'
    }}
  />
)
const DetailRow = ({ children }: { children: React.ReactNode }) => (
  <Flex
    alignItems={{ base: 'flex-start', sm: 'center' }}
    flexDirection={{ base: 'column', sm: 'row' }}
    justifyContent="space-between"
    flexWrap="wrap"
    mb="12px"
    _last={{ mb: '0' }}
  >
    {children}
  </Flex>
)

function TradeSummary({
  trade,
  allowedSlippage,
  defaultIsOpen = false
}: {
  trade: Trade
  allowedSlippage: number
  defaultIsOpen?: boolean
}) {
  const { priceImpactWithoutFee, realizedLPFee } = computeTradePriceBreakdown(trade)
  const isExactIn = trade.tradeType === TradeType.EXACT_INPUT
  const slippageAdjustedAmounts = computeSlippageAdjustedAmounts(trade, allowedSlippage)
  const { t } = useTranslation()

  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  return (
    <>
      <Box width="100%">
        {trade.route.length > 2 && (
          <Box p="16px" width="100%" mb="12px" bgColor="background.tertiary" borderRadius="12px">
            <Box fontWeight="500" fontSize="16px" mb="12px">
              {t('swap.detail.router')}
            </Box>
            <Center width="100%" p="8px" borderRadius="full" bgColor="white">
              {trade.route.map(token => (
                <React.Fragment key={token.symbol}>
                  <CurrencyLogo currency={token} />
                  <Dots />
                </React.Fragment>
              ))}
            </Center>
          </Box>
        )}

        <Box p="16px" width="100%" bgColor="background.tertiary" borderRadius="12px">
          <Center justifyContent="space-between" onClick={onToggle} fontWeight="500" fontSize="16px" cursor="pointer">
            {t('swap.detail.priceInfo')}
            <Center transition="transform .2s" transform={isOpen ? 'rotate(180deg)' : 'none'}>
              <Image src={ArrowIcon} alt="↓" boxSize="14px" />
            </Center>
          </Center>
          <Collapse in={isOpen}>
            <Flex
              flexDirection="column"
              pt="12px"
              mt="12px"
              borderTop="1px solid"
              borderColor="border.secondary"
              fontSize="14px"
            >
              <DetailRow>
                <Center fontWeight="500">
                  {isExactIn ? t('swap.detail.minimum_received') : t('swap.detail.maximum_sold')}
                  <QuestionHelper content={t('swap.detail.help.receive')} />
                </Center>
                <Box>
                  {isExactIn
                    ? `${slippageAdjustedAmounts[Field.OUTPUT]?.toFixed(8)} ${trade.outputCurrency.symbol}` ?? '-'
                    : `${slippageAdjustedAmounts[Field.INPUT]?.toFixed(8)} ${trade.inputCurrency.symbol}` ?? '-'}
                </Box>
              </DetailRow>

              <DetailRow>
                <Center fontWeight="500">
                  {t('swap.detail.priceImpact')}
                  <QuestionHelper content={t('swap.detail.help.impact')} />
                </Center>
                <FormattedPriceImpact priceImpact={priceImpactWithoutFee} />
              </DetailRow>

              <DetailRow>
                <Center fontWeight="500">
                  {t('swap.detail.providerFee')}
                  <QuestionHelper content={t('swap.detail.help.providerFee')} />
                </Center>
                <Box>{realizedLPFee ? `${realizedLPFee.toFixed(8)} ${trade.inputCurrency.symbol}` : '-'}</Box>
              </DetailRow>
            </Flex>
          </Collapse>
        </Box>
      </Box>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade
  defaultIsOpen?: boolean
}

export function AdvancedSwapDetails({ trade, defaultIsOpen }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  return (
    <Flex>
      {trade && <TradeSummary trade={trade} allowedSlippage={allowedSlippage} defaultIsOpen={defaultIsOpen} />}
    </Flex>
  )
}
