import { useMemo, useState, useEffect } from 'react'
import { useFclReact } from './useFclReact'
import getStorageFee from './scripts/getStorageFee'
import { replaceContractAddresses } from './env'

export function useStorageFee(address: string | undefined, nonce?: number | undefined): number {
  const { fcl, types, chainId } = useFclReact()
  const [storageFee, setStorageFee] = useState<number>(0.001)

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!address || !fcl) {
      setStorageFee(0.001)

      return callback
    }

    const script = replaceContractAddresses(getStorageFee, chainId)

    fcl
      .send([fcl.script(script), fcl.args([fcl.arg(address, types.Address)])])
      .then(fcl.decode)
      .then((result: string) => {
        isSubscribed && setStorageFee(parseFloat(result))
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [address, fcl, types, nonce, chainId])

  return useMemo(() => storageFee, [storageFee])
}
