import { ChainId } from '../utils'

enum ContractId {
  BLOCTO_TOKEN_ADDRESS = 'BLOCTO_TOKEN_ADDRESS',
  BLOCTO_SWAP_LISTING_ADDRESS = 'BLOCTO_SWAP_LISTING_ADDRESS',
  BLT_USDT_SWAP_PAIR_ADDRESS = 'BLT_USDT_SWAP_PAIR_ADDRESS',
  FLOW_STORAGE_FEES_ADDRESS = 'FLOW_STORAGE_FEES_ADDRESS',
  FLOW_SWAP_PAIR_ADDRESS = 'FLOW_SWAP_PAIR_ADDRESS',
  FLOW_TOKEN_ADDRESS = 'FLOW_TOKEN_ADDRESS',
  FLOW_USD_ADDRESS = 'FLOW_USD_ADDRESS',
  FUNGIBLE_TOKEN_ADDRESS = 'FUNGIBLE_TOKEN_ADDRESS',
  FUSD_USDT_SWAP_PAIR_ADDRESS = 'FUSD_USDT_SWAP_PAIR_ADDRESS',
  IDO_ADDRESS = 'IDO_ADDRESS',
  NON_FUNGIBLE_TOKEN_ADDRESS = 'NON_FUNGIBLE_TOKEN_ADDRESS',
  REVV_FLOW_SWAP_PAIR_ADDRESS = 'REVV_FLOW_SWAP_PAIR_ADDRESS',
  REVV_TOKEN_ADDRESS = 'REVV_TOKEN_ADDRESS',
  REVV_TELEPORT_CUSTODY_ADDRESS = 'REVV_TELEPORT_CUSTODY_ADDRESS',
  SPRT_USDT_SWAP_PAIR_ADDRESS = 'SPRT_USDT_SWAP_PAIR_ADDRESS',
  STARLY_TOKEN_ADDRESS = 'STARLY_TOKEN_ADDRESS',
  STARLY_USDT_SWAP_PAIR_ADDRESS = 'STARLY_USDT_SWAP_PAIR_ADDRESS',
  TELEPORT_BLT_ADMIN_ADDRESS = 'TELEPORT_BLT_ADMIN_ADDRESS',
  TELEPORT_BSC_BLT_ADDRESS = 'TELEPORT_BSC_BLT_ADDRESS',
  TELEPORT_ETHEREUM_BLT_ADDRESS = 'TELEPORT_ETHEREUM_BLT_ADDRESS',
  TELEPORT_APTOS_BLT_ADDRESS = 'TELEPORT_APTOS_BLT_ADDRESS',
  TELEPORT_REVV_ADMIN_ADDRESS = 'TELEPORT_REVV_ADMIN_ADDRESS',
  TELEPORT_SPRT_ADMIN_ADDRESS = 'TELEPORT_SPRT_ADMIN_ADDRESS',
  TELEPORT_USDT_ADMIN_ADDRESS = 'TELEPORT_USDT_ADMIN_ADDRESS',
  TELEPORTED_SPRT_ADDRESS = 'TELEPORTED_SPRT_ADDRESS',
  TELEPORTED_USDT_ADDRESS = 'TELEPORTED_USDT_ADDRESS',
  TOKEN_POOL_ADDRESS = 'TOKEN_POOL_ADDRESS',
  USDC_TOKEN_ADDRESS = 'USDC_TOKEN_ADDRESS',
  USDC_USDT_SWAP_PAIR_ADDRESS = 'USDC_USDT_SWAP_PAIR_ADDRESS'
}

type ContractAddressList = {
  readonly [contractId in ContractId]: string
}

const FAKE_ADDRESSES: ContractAddressList = {
  [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
  [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0x217b60138188a81d',
  [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
  [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
  [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
  [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
  [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
  [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
  [ContractId.IDO_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
  [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0x96d9806d5deac85f',
  [ContractId.REVV_TOKEN_ADDRESS]: '0xcdb09c8fef7e3c15',
  [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0x8c4ae10401c19ec0',
  [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
  [ContractId.STARLY_TOKEN_ADDRESS]: '0xf63219072aaddd50',
  [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x22d84efc93a8b21a',
  [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x967a0fb3c949cbc5',
  [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
  [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x653cfe36d146e7be',
  [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0x6ae7d47f631f5ab3',
  [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0xb097866d87d01550',
  [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xc26a86fd71f30119',
  [ContractId.TELEPORTED_SPRT_ADDRESS]: '0xb097866d87d01550',
  [ContractId.TELEPORTED_USDT_ADDRESS]: '0x2d270db9ac8c7fef',
  [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
  [ContractId.USDC_TOKEN_ADDRESS]: '0xa983fecbed621163',
  [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x481744401ea249c0'
}

const CONTRACT_ADDRESSES: { [chainId: number]: ContractAddressList } = {
  [ChainId.MAINNET]: {
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x0f9df91c9121c460',
    [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0x923f5c3c39a7649d',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0xfcb06a5ae5b21a2d',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0xe467b9dd11fa00df',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0xc6c77b9f5c7a378f',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x1654653399040a61',
    [ContractId.FLOW_USD_ADDRESS]: '0x3c5959b568896393',
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0xf233dcee88fe0abe',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x87f3f233f34b0733',
    [ContractId.IDO_ADDRESS]: '0x49fc55f59a2d1703',
    [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0x1d7e57aa55817448',
    [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0x5e284fb7cff23a3f',
    [ContractId.REVV_TOKEN_ADDRESS]: '0xd01e482eb680ec9f',
    [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0x04ee69443dedf0e4',
    [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x60e1db11f37ac469',
    [ContractId.STARLY_TOKEN_ADDRESS]: '0x142fa6570b62fd97',
    [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x6efab66df92c37e4',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x73d494fc6fe4b127',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x0ac14a822e54cc4e',
    [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x714000cf4dd1c4ed',
    [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0x08a13c66a11dea60',
    [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0xdcad421ef2d27e07',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0x78fea665a361cf0e',
    [ContractId.TELEPORTED_SPRT_ADDRESS]: '0x475755d2c9dccc3a',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0xcfdd90d4a00f7b5b',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x08f9e2ed8b0d548a',
    [ContractId.USDC_TOKEN_ADDRESS]: '0xb19436aae4d94622',
    [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x9c6f94adf47904b5'
  },
  [ChainId.SEPOLIA]: {
    [ContractId.BLOCTO_TOKEN_ADDRESS]: '0x653cfe36d146e7be',
    [ContractId.BLOCTO_SWAP_LISTING_ADDRESS]: '0xd9854329b7edf136',
    [ContractId.BLT_USDT_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
    [ContractId.FLOW_STORAGE_FEES_ADDRESS]: '0x8c5303eaa26202d6',
    [ContractId.FLOW_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
    [ContractId.FLOW_TOKEN_ADDRESS]: '0x7e60df042a9c0868',
    [ContractId.FLOW_USD_ADDRESS]: '0xe223d8a629e49c68',
    [ContractId.FUNGIBLE_TOKEN_ADDRESS]: '0x9a0766d93b6608b7',
    [ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS]: '0x3502a5dacaf350bb',
    [ContractId.IDO_ADDRESS]: '0xa2d8020d03e30815',
    [ContractId.NON_FUNGIBLE_TOKEN_ADDRESS]: '0x631e88ae7f1d7c20',
    [ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS]: '0x96d9806d5deac85f',
    [ContractId.REVV_TOKEN_ADDRESS]: '0xcdb09c8fef7e3c15',
    [ContractId.REVV_TELEPORT_CUSTODY_ADDRESS]: '0x8c4ae10401c19ec0',
    [ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS]: '0x0f5be7fc963db41b',
    [ContractId.STARLY_TOKEN_ADDRESS]: '0xf63219072aaddd50',
    [ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS]: '0x95c5ae7e2a9c29ac',
    [ContractId.TELEPORT_BLT_ADMIN_ADDRESS]: '0x967a0fb3c949cbc5',
    [ContractId.TELEPORT_BSC_BLT_ADDRESS]: '0x653cfe36d146e7be',
    [ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS]: '0x653cfe36d146e7be',
    [ContractId.TELEPORT_APTOS_BLT_ADDRESS]: '0x653cfe36d146e7be',
    [ContractId.TELEPORT_REVV_ADMIN_ADDRESS]: '0x6ae7d47f631f5ab3',
    [ContractId.TELEPORT_SPRT_ADMIN_ADDRESS]: '0x6d68fb7db988aea8',
    [ContractId.TELEPORT_USDT_ADMIN_ADDRESS]: '0xc26a86fd71f30119',
    [ContractId.TELEPORTED_SPRT_ADDRESS]: '0xb097866d87d01550',
    [ContractId.TELEPORTED_USDT_ADDRESS]: '0x2d270db9ac8c7fef',
    [ContractId.TOKEN_POOL_ADDRESS]: '0x36c8c86d6a928fc0',
    [ContractId.USDC_TOKEN_ADDRESS]: '0xa983fecbed621163',
    [ContractId.USDC_USDT_SWAP_PAIR_ADDRESS]: '0x481744401ea249c0'
  },
  [ChainId.KOVAN]: FAKE_ADDRESSES,
  [ChainId.ROPSTEN]: FAKE_ADDRESSES
}

export const replaceContractAddresses = (script: string, env: ChainId): string => {
  if (!script) {
    return script
  }

  const addresses = CONTRACT_ADDRESSES[env] || {}

  return script
    .replaceAll('0xBLTADDRESS', addresses[ContractId.BLOCTO_TOKEN_ADDRESS])
    .replaceAll('0xBLOCTO_SWAP_LISING_ADDRESS', addresses[ContractId.BLOCTO_SWAP_LISTING_ADDRESS])
    .replaceAll('0xBLTUSDTSWAPPAIRADDRESS', addresses[ContractId.BLT_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xFLOWSTORAGEFEESADDRESS', addresses[ContractId.FLOW_STORAGE_FEES_ADDRESS])
    .replaceAll('0xFLOWSWAPPAIRADDRESS', addresses[ContractId.FLOW_SWAP_PAIR_ADDRESS])
    .replaceAll('0xFLOWTOKENADDRESS', addresses[ContractId.FLOW_TOKEN_ADDRESS])
    .replaceAll('0xFUNGIBLETOKENADDRESS', addresses[ContractId.FUNGIBLE_TOKEN_ADDRESS])
    .replaceAll('0xFUSDADDRESS', addresses[ContractId.FLOW_USD_ADDRESS])
    .replaceAll('0xFUSDUSDTSWAPPAIRADDRESS', addresses[ContractId.FUSD_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xIDOADDRESS', addresses[ContractId.IDO_ADDRESS])
    .replaceAll('0xNONFUNGIBLETOKENADDRESS', addresses[ContractId.NON_FUNGIBLE_TOKEN_ADDRESS])
    .replaceAll('0xREVV_FLOW_SWAP_PAIR_ADDRESS', addresses[ContractId.REVV_FLOW_SWAP_PAIR_ADDRESS])
    .replaceAll('0xREVVTOKENADDRESS', addresses[ContractId.REVV_TOKEN_ADDRESS])
    .replaceAll('0xREVVTELEPORTCUSTODYADDRESS', addresses[ContractId.REVV_TELEPORT_CUSTODY_ADDRESS])
    .replaceAll('0xSPRT_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.SPRT_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xSTARLYTOKENADDRESS', addresses[ContractId.STARLY_TOKEN_ADDRESS])
    .replaceAll('0xSTARLY_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.STARLY_USDT_SWAP_PAIR_ADDRESS])
    .replaceAll('0xTELEPORTBLTADMINADDRESS', addresses[ContractId.TELEPORT_BLT_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTBSCBLTADDRESS', addresses[ContractId.TELEPORT_BSC_BLT_ADDRESS])
    .replaceAll('0xTELEPORT_ETHEREUM_BLT_ADDRESS', addresses[ContractId.TELEPORT_ETHEREUM_BLT_ADDRESS])
    .replaceAll('0xTELEPORTAPTOSBLTADDRESS', addresses[ContractId.TELEPORT_APTOS_BLT_ADDRESS])
    .replaceAll('0xTELEPORTEDUSDTADDRESS', addresses[ContractId.TELEPORTED_USDT_ADDRESS])
    .replaceAll('0xTELEPORTREVVADMINADDRESS', addresses[ContractId.TELEPORT_REVV_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTAPTOSADDRESS', addresses[ContractId.TELEPORT_APTOS_BLT_ADDRESS])
    .replaceAll('0xTELEPORTUSDTADMINADDRESS', addresses[ContractId.TELEPORT_USDT_ADMIN_ADDRESS])
    .replaceAll('0xTELEPORTED_SPRT_ADDRESS', addresses[ContractId.TELEPORTED_SPRT_ADDRESS])
    .replaceAll('0xTELEPORT_SPRT_ADMIN_ADDRESS', addresses[ContractId.TELEPORT_SPRT_ADMIN_ADDRESS])
    .replaceAll('0xTOKENPOOLADDRESS', addresses[ContractId.TOKEN_POOL_ADDRESS])
    .replaceAll('0xUSDC_TOKEN_ADDRESS', addresses[ContractId.USDC_TOKEN_ADDRESS])
    .replaceAll('0xUSDC_USDT_SWAP_PAIR_ADDRESS', addresses[ContractId.USDC_USDT_SWAP_PAIR_ADDRESS])
}
