const script = `\
import FlowStorageFees from 0xFLOWSTORAGEFEESADDRESS

access(all) fun main(address: Address): UFix64 {
    let account = getAccount(address)
    let megabytes = FlowStorageFees.convertUInt64StorageBytesToUFix64Megabytes(account.storage.used)
    let calculated = FlowStorageFees.storageCapacityToFlow(megabytes)
    let minimum = FlowStorageFees.minimumStorageReservation

    return calculated > minimum ? calculated : minimum
}
`

export default script
