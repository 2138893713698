import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@chakra-ui/react'
import { ReactComponent as AlertIcon } from '../../assets/icon/status/alert_outline.svg'

function BetaWarning({ fullWidth = false }: { fullWidth?: boolean }) {
  const { t } = useTranslation()

  return (
    <Box
      mt="28px"
      mb="40px"
      color="font.tertiary"
      maxWidth={fullWidth ? 'auto' : '390px'}
      fontSize="14px"
      lineHeight="20px"
    >
      <Flex alignItems="center">
        <AlertIcon style={{ fill: '#BFC2C6', width: '20px', marginTop: fullWidth ? 'auto' : '2px' }} />
        <Box ml="8px">{t('footer.betawarn')}</Box>
      </Flex>
    </Box>
  )
}

export default BetaWarning
