import { currentEnv } from '../constants'

export const BLT_CONTRACT_ADDR: string =
  currentEnv === 'mainnet'
    ? '0xfbab9fb68bd2103925317b6a540baa20087b1e7a7a4eb90badee04abb6b5a16f'
    : '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3'

export const BLT_TOKEN_ADDR = `0x1::coin::CoinStore<${BLT_CONTRACT_ADDR}::blt::Blt>`

export const TELEPORT_TO_FLOW = `${BLT_CONTRACT_ADDR}::teleport::teleport_to_flow`

export const APTOS_NODE_URL = `https://fullnode.${currentEnv === 'mainnet' ? 'mainnet' : 'testnet'}.aptoslabs.com/v1`
