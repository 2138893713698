import { Currency } from '@uniswap/sdk'
import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import mapCoreSymbol from '../../utils/mapCoreSymbol'
import Column from '../Column'
import NetworkLogo from '../NetworkLogo'
import { FadedSpan, MenuItem } from './styleds'

function CurrencyRow({
  network,
  isDisabled,
  onSelect,
  style
}: {
  network: string
  isDisabled: boolean
  onSelect: () => void
  style: CSSProperties
}) {
  // only show add or remove buttons if not on selected list
  return (
    <MenuItem style={style} className={`network-item-${network}`} onClick={onSelect} disabled={isDisabled}>
      <NetworkLogo network={network} size={24} />
      <Column>
        <Text title={network} fontWeight={500}>
          {network}
        </Text>
      </Column>
      <FadedSpan />
    </MenuItem>
  )
}

export default function NetworkList({
  height,
  networks,
  onNetworkSelect,
  currentSelection,
  otherCurrency,
  fixedListRef
}: {
  height: number
  networks: string[]
  onNetworkSelect: (network: string) => void
  currentSelection?: any
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}) {
  const Row = useCallback(
    ({ data, index, style }) => {
      const network: string = data[index]
      const handleSelect = () => {
        onNetworkSelect(network)
      }
      const otherCurrencyNetwork = (otherCurrency as any)?.network
      const symbol = otherCurrency?.symbol

      const validBLTNetworkPairs = [
        ['FLOW', 'BSC'],
        ['BSC', 'FLOW'],
        ['FLOW', 'ETHEREUM'],
        ['ETHEREUM', 'FLOW'],
        ['FLOW', 'APTOS']
      ]
      const isSameToken = Boolean(symbol) && mapCoreSymbol(currentSelection?.current?.symbol) === mapCoreSymbol(symbol)
      const isDisabled =
        isSameToken && !validBLTNetworkPairs.find(pair => pair[0] === otherCurrencyNetwork && pair[1] === network)
      return <CurrencyRow style={style} isDisabled={isDisabled} network={network} onSelect={handleSelect} />
    },
    [onNetworkSelect, otherCurrency, currentSelection]
  )

  const itemKey = useCallback((index: number, data: any) => {
    return data[index]
  }, [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={networks}
      itemCount={networks.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
