import React from 'react'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedSwapDetails, AdvancedSwapDetailsProps } from './AdvancedSwapDetails'
import { Collapse } from '@chakra-ui/react'
import { useSwapState } from '../../state/swap/hooks'

export default function AdvancedSwapDetailsDropdown({ trade, ...rest }: AdvancedSwapDetailsProps) {
  const lastTrade = useLastTruthy(trade)
  const { typedValue } = useSwapState()

  return (
    <Collapse in={!!trade && trade?.inputAmount !== Number.MAX_VALUE && trade?.inputAmount !== 0 && !!typedValue}>
      <AdvancedSwapDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </Collapse>
  )
}
