import { useCallback, useMemo } from 'react'
import * as types from '@onflow/types'
import { replaceContractAddresses } from './env'
import { useFclReact } from './useFclReact'
import getIDOProjectConfigs from './scripts/getIDOProjectConfigs'
import getIDOProjectConfig from './scripts/getIDOProjectConfig'
import getIDOUserInfo from './scripts/getIDOUserInfo'
import checkIDOInitialized from './scripts/checkIDOInitialized'
import getIDOTokenInfo from './scripts/getIDOTokenInfo'
import getIDOValidStake from './scripts/getIDOValidStake'
import initIDO from './transactions/initIDO'
import { IDOProjectConfig, IDOUserInfo, CapabilityPath } from './types'
import { TransactionResponse } from '../types'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'

const selectPoolScriptBuilder = ({
  contractName,
  address,
  storagePath,
  receiverPath,
  balancePath
}: {
  contractName: string
  address: string
  storagePath: CapabilityPath
  receiverPath: CapabilityPath
  balancePath: CapabilityPath
}): string => `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoIdo from 0xIDOADDRESS
import BloctoToken from 0xBLTADDRESS
import ${contractName} from ${address}

transaction(name: String, poolType: String) {
	prepare(signer: AuthAccount) {
    // enable ido token
    if signer.borrow<&${contractName}.Vault>(from: /storage/${storagePath.identifier}) == nil {
      signer.save(<-${contractName}.createEmptyVault(), to: /storage/${storagePath.identifier})

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      signer.link<&${contractName}.Vault{FungibleToken.Receiver}>(
        /${receiverPath.domain}/${receiverPath.identifier},
        target: /storage/${storagePath.identifier}
      )

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      signer.link<&${contractName}.Vault{FungibleToken.Balance}>(
        /${balancePath.domain}/${balancePath.identifier},
        target: /storage/${storagePath.identifier}
      )
    }

    // select pool
    let bloctoIdoUserRef = signer
      .borrow<&BloctoIdo.User>(from: BloctoIdo.BloctoIdoUserStoragePath)
      ?? panic("faield to borrow user")

    let vaultRef = signer.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
      ?? panic("Could not borrow reference to the owner's Vault!")

    let activity =  BloctoIdo.getActivity(name)!
    let poolConfig = activity.poolConfig
    let vault <- vaultRef.withdraw(amount: poolConfig[poolType]!.selectFee)

    bloctoIdoUserRef.selectPool(name: name, poolType: poolType, vault: <- (vault as! @BloctoToken.Vault))
  }
}`

export function useIDOAPI() {
  const { fcl, chainId, account, authorization } = useFclReact()
  const addTransaction = useTransactionAdder()

  const initializeIDOUser = useCallback(
    async (): Promise<string | null> =>
      account
        ? fcl
            .send([fcl.getBlock(false)])
            .then(fcl.decode)
            .then((block: any) =>
              fcl.send([
                fcl.transaction(replaceContractAddresses(initIDO, chainId)),
                fcl.proposer(authorization),
                fcl.authorizations([authorization]),
                fcl.payer(authorization),
                fcl.ref(block.id),
                fcl.limit(200)
              ])
            )
            .then((response: TransactionResponse) =>
              fcl
                .tx(response.transactionId)
                .onceSealed()
                .then(() => response.transactionId)
            )
        : null,
    [fcl, chainId, authorization, account]
  )

  const selectPool = useCallback(
    async (name, poolType) => {
      const tokenInfo = await fcl
        .send([fcl.script(replaceContractAddresses(getIDOTokenInfo, chainId)), fcl.args([fcl.arg(name, types.String)])])
        .then(fcl.decode)
        .catch((error: Error) => {
          console.error(error)
        })

      return fcl
        .send([fcl.getBlock(false)])
        .then(fcl.decode)
        .then((block: any) =>
          fcl.send([
            fcl.transaction(replaceContractAddresses(selectPoolScriptBuilder(tokenInfo), chainId)),
            fcl.args([fcl.arg(name, types.String), fcl.arg(poolType, types.String)]),
            fcl.proposer(authorization),
            fcl.authorizations([authorization]),
            fcl.payer(authorization),
            fcl.ref(block.id),
            fcl.limit(200)
          ])
        )
        .then((response: TransactionResponse) => {
          const summary = `${name} ${poolType === 'LIMITED' ? 'standard' : 'unlimited'} pool selected`
          addTransaction(response, {
            summary,
            ido: {
              name,
              action: 'SELECT_POOL'
            }
          })
          return response.transactionId
        })
    },
    [fcl, chainId, authorization, addTransaction]
  )

  const checkInitialized = useCallback(
    async (): Promise<boolean> =>
      account
        ? fcl
            .send([
              fcl.script(replaceContractAddresses(checkIDOInitialized, chainId)),
              fcl.args([fcl.arg(account, types.Address)])
            ])
            .then(fcl.decode)
            .catch((error: Error) => {
              console.error(error)
            })
        : false,
    [fcl, chainId, account]
  )

  const getProjectConfigs = useCallback(
    async (): Promise<{ [key: string]: IDOProjectConfig }> =>
      fcl
        .send([fcl.script(replaceContractAddresses(getIDOProjectConfigs, chainId))])
        .then(fcl.decode)
        .catch((error: Error) => {
          console.error(error)
        }),
    [fcl, chainId]
  )

  const getProjectConfig = useCallback(
    async (name): Promise<IDOProjectConfig> =>
      name
        ? fcl
            .send([
              fcl.script(replaceContractAddresses(getIDOProjectConfig, chainId)),
              fcl.args([fcl.arg(name, types.String)])
            ])
            .then(fcl.decode)
            .catch((error: Error) => {
              console.error(error)
            })
        : null,
    [fcl, chainId]
  )

  const getValidStake = useCallback(
    async (name): Promise<any> =>
      name
        ? fcl
            .send([
              fcl.script(replaceContractAddresses(getIDOValidStake, chainId)),
              fcl.args([fcl.arg(name, types.String)])
            ])
            .then(fcl.decode)
            .catch((error: Error) => {
              console.error(error)
            })
        : null,
    [fcl, chainId]
  )

  const getUserInfo = useCallback(
    async (name): Promise<IDOUserInfo> =>
      name && account
        ? fcl
            .send([
              fcl.script(replaceContractAddresses(getIDOUserInfo, chainId)),
              fcl.args([fcl.arg(name, types.String), fcl.arg(account, types.Address)])
            ])
            .then(fcl.decode)
            .catch((error: Error) => {
              console.error(error)
            })
        : null,
    [fcl, chainId, account]
  )

  return useMemo(
    () => ({
      getProjectConfigs,
      getProjectConfig,
      getUserInfo,
      initializeIDOUser,
      checkInitialized,
      selectPool,
      getValidStake
    }),
    [getProjectConfigs, getProjectConfig, getUserInfo, initializeIDOUser, checkInitialized, selectPool, getValidStake]
  )
}
