import { useCallback } from 'react'
import depositScript from './transactions/depositIDO'
import { useFclReact } from './useFclReact'
import { replaceContractAddresses } from './env'
import { TransactionResponse } from '../types'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'

export function useIDOTransactions(projectName: string) {
  const { fcl, chainId, types, authorization } = useFclReact()
  const addTransaction = useTransactionAdder()
  const isSealed = false
  const deposit = useCallback(
    async (amount: number) =>
      fcl
        .send([fcl.getBlock(isSealed)])
        .then(fcl.decode)
        .then((block: any) =>
          fcl
            .send([
              fcl.transaction(replaceContractAddresses(depositScript, chainId)),
              fcl.args([fcl.arg(projectName, types.String), fcl.arg(amount.toFixed(8), types.UFix64)]),
              fcl.limit(100),
              fcl.proposer(authorization),
              fcl.authorizations([authorization]),
              fcl.payer(authorization),
              fcl.ref(block.id)
            ])
            .then((response: TransactionResponse) => {
              const summary = `Deposit ${amount} tUSDT`
              addTransaction(response, {
                summary,
                ido: {
                  name: projectName,
                  action: 'DEPOSIT'
                }
              })
              return response.transactionId
            })
        ),
    [authorization, chainId, fcl, isSealed, projectName, types, addTransaction]
  )
  return { deposit }
}
