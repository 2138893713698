import React from 'react'
import { Trade } from '../../fcl-react'
import QuestionHelper from '../QuestionHelper'
import { Flex, Box, Center } from '@chakra-ui/react'

const DetailRow = ({ children }: { children: React.ReactNode }) => (
  <Flex
    alignItems={{ base: 'flex-start', sm: 'center' }}
    flexDirection={{ base: 'column', sm: 'row' }}
    justifyContent="space-between"
    flexWrap="wrap"
    mb="12px"
    _last={{ mb: '0' }}
  >
    {children}
  </Flex>
)

function TradeSummary({ trade }: { trade: Trade }) {
  return (
    <>
      <Box p="16px" width="100%" bgColor="background.tertiary" borderRadius="12px">
        <Center justifyContent="space-between" fontWeight="500" fontSize="16px">
          Price Info
        </Center>

        <Flex
          flexDirection="column"
          pt="12px"
          mt="12px"
          borderTop="1px solid"
          borderColor="border.secondary"
          fontSize="14px"
        >
          <DetailRow>
            <Center fontWeight="500">
              Teleport Fee
              <QuestionHelper content="A fixed amount is charged in each teleport to cover the cost of teleport service and network transaction fee." />
            </Center>
            <Box>{trade.fee ? `${trade.fee.toFixed(3)} ${trade.inputCurrency.symbol.replace('t', '')}` : '-'}</Box>
          </DetailRow>

          <DetailRow>
            <Center fontWeight="500">
              Process Time
              <QuestionHelper content="Teleporting more than 1000 USDT requires manual auditing and can take up to 24 hours." />
            </Center>
            <Box color={trade.inputAmount >= 100000 ? 'font.error' : 'font.error'}>
              {trade.inputAmount >= 100000 ? '<24 hours' : '~15 minutes'}
            </Box>
          </DetailRow>
        </Flex>
      </Box>
    </>
  )
}

export interface AdvancedTeleportDetailsProps {
  trade?: Trade
}

export function AdvancedTeleportDetails({ trade }: AdvancedTeleportDetailsProps) {
  return <Flex>{trade && <TradeSummary trade={trade} />}</Flex>
}
