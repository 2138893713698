import React from 'react'
import { Button } from '@chakra-ui/react'

interface ButtonPrimaryProps {
  onClick?: () => void
  children: React.ReactNode
  background?: string
  color?: string
  innerRef?: React.RefObject<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
  variant?: string
  p?: string
}

const ButtonSecondary = ({
  children,
  onClick,
  background = 'interaction.secondary',
  color = 'font.primary',
  innerRef,
  disabled = false,
  isLoading = false,
  variant = 'solid',
  p = '6px 12px'
}: ButtonPrimaryProps) => {
  return (
    <>
      <Button
        onClick={onClick}
        as="button"
        p={p}
        height="auto"
        borderRadius="100px"
        fontWeight="500"
        color={color}
        background={background}
        justifyContent="center"
        ref={innerRef}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        disabled={disabled}
        isLoading={isLoading}
        variant={variant}
        transition=".2s all"
        _hover={{
          transform: disabled ? '' : 'scale(0.98)',
          bg: 'interactive.secondary.hovered'
        }}
        _active={{ transform: disabled ? '' : 'scale(0.96)' }}
        _focus={{
          boxShadow: 'none'
        }}
        _disabled={{
          bg: 'interaction.secondary.disabled'
        }}
      >
        {children}
      </Button>
    </>
  )
}
export default ButtonSecondary
