const script = `\
import BloctoIdo from 0xIDOADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction(name: String, amount: UFix64) {
	prepare(user: AuthAccount) {
		let bloctoIdoUserRef = user
			.borrow<&BloctoIdo.User>(from: BloctoIdo.BloctoIdoUserStoragePath)
			?? panic("faield to borrow user")

	        let vaultRef = user.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

		let vault <- vaultRef.withdraw(amount: amount)

		bloctoIdoUserRef.deposit(name: name, vault: <- (vault as! @TeleportedTetherToken.Vault))
	}
}
`
export default script
