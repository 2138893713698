import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import styled from 'styled-components'
import Box from '../../components/IDO/layout/Box'
import * as InfoBlocks from '../../components/IDO/InfoBlocks'
import IDOJourney from '../../components/IDO/IDOJourney'
import IDOJourneyVert from '../../components/IDO/IDOJourneyVert'
import { useAppDispatch, useIDOState } from '../../state/IDO/hook'
import { useIDOAPI } from '../../fcl-react/useIDOAPI'
import { loadProject, loadUserInfo } from '../../state/IDO/action'
import useGeoLocation from '../../hooks/useGeoLocation'
import banned from '../../constants/banned'
import { useUserStakedBLT } from '../../fcl-react/useUserStakedBLT'
import { useFclReact } from '../../fcl-react'

const Wrapper = styled.div`
  max-width: 1120px;
  padding: 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100vw;
    padding: 0;
    margin-top: -2rem;
  `}
`

const Flex = styled.div`
  display: flex;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `}
  & > div:first-child {
    width: 66.6%;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 100%;
    `}
  }
  & > div:last-child {
    width: 33.3%;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 100%;
    `}
  }
`

const Iframe = styled.iframe`
  width: 100%;
  border: none;
  min-height: 100vh;
`

export function IDODetail(props: RouteComponentProps<{ name: string }>) {
  const dispatch = useAppDispatch()
  const { getProjectConfig, getUserInfo, checkInitialized } = useIDOAPI()
  const { projects } = useIDOState()
  const [isInitialized, setIsInitialized] = useState(false)
  const location = useGeoLocation()
  const [userInfoLoaded, setUserInfoLoaded] = useState(false)
  const { account } = useFclReact()

  const { name } = props.match.params
  const project = projects[name]
  const isBanned = banned.includes(location)
  const { stakedLocked, stakedUnlocked } = useUserStakedBLT()
  const staked = stakedLocked + stakedUnlocked
  const minStakingAmount = project?.minStakingAmount ?? 0
  const isStakedSufficient = staked >= minStakingAmount

  useEffect(() => {
    if (!project) {
      getProjectConfig(name)
        .then(config => dispatch(loadProject({ name, config })))
        .then(() => {
          getUserInfo(name).then(info => {
            setUserInfoLoaded(true)
            dispatch(loadUserInfo({ projectName: name, userInfo: info }))
          })
        })
      return
    } else if (!userInfoLoaded) {
      getUserInfo(name).then(info => {
        setUserInfoLoaded(true)
        dispatch(loadUserInfo({ projectName: name, userInfo: info }))
      })
      return
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, name, getProjectConfig, dispatch, getUserInfo])

  useEffect(() => void checkInitialized().then(setIsInitialized), [checkInitialized])

  useEffect(() => {
    // reload userInfo when account changed
    if (userInfoLoaded) {
      getUserInfo(name).then(info => {
        setUserInfoLoaded(true)
        dispatch(loadUserInfo({ projectName: name, userInfo: info }))
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useEffect(() => {
    // always scroll to top when visit
    window.scrollTo({ top: 0 })
  }, [])

  return project ? (
    <Wrapper>
      <Flex>
        <InfoBlocks.Main {...project} />
        <InfoBlocks.SaleInfo {...project} />
      </Flex>
      <Flex>
        <InfoBlocks.RulesBlock rules={project?.rules || []} />
        <InfoBlocks.Verifications
          name={project?.name}
          timeline={project?.timeline}
          minStakingAmount={project?.minStakingAmount}
          isBanned={isBanned}
          isInitialized={isInitialized}
          setIsInitialized={setIsInitialized}
          isStakedSufficient={isStakedSufficient}
        />
      </Flex>
      <Box>
        <IDOJourney metadata={project} />
      </Box>
      <Box>
        <IDOJourneyVert metadata={project} isInitialized={isInitialized} />
      </Box>
      {project?.projectUrl && (
        <Box>
          <h2>About Project</h2>
          <Iframe src={project.projectUrl} />
        </Box>
      )}
    </Wrapper>
  ) : null
}
