import { Currency } from '@uniswap/sdk'
import React from 'react'
import Modal from '../Modal'
import { TeleportSearch } from './TeleportSearch'

interface CurrencySearchModalProps {
  isOpen: boolean
  onDismiss: () => void
  onCurrencySelect: (select: any) => void
  otherSelectedCurrency?: Currency | null
  isMainSelector?: boolean
}

export default function TeleportSearchModal({
  isOpen,
  onDismiss,
  onCurrencySelect,
  otherSelectedCurrency,
  isMainSelector
}: CurrencySearchModalProps) {
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={80} minHeight={80}>
      <TeleportSearch
        isOpen={isOpen}
        onDismiss={onDismiss}
        onSelect={onCurrencySelect}
        otherSelectedCurrency={otherSelectedCurrency}
        isMainSelector={isMainSelector}
      />
    </Modal>
  )
}
