const script = `
import BloctoPass from 0xBLTADDRESS
import NonFungibleToken from 0xNONFUNGIBLETOKENADDRESS

access(all) fun main(address: Address): [UFix64] {
  let collectionRef = getAccount(address).getCapability(/public/bloctoPassCollection)
    .borrow<&{NonFungibleToken.CollectionPublic, BloctoPass.CollectionPublic}>()
    ?? panic("Could not borrow collection public reference")
  let ids = collectionRef.getIDs()
  var lockedAmount = 0.0
  var unlockedAmount = 0.0
  var tokensRequestedToUnstake = 0.0
  var tokensCommitted = 0.0

  for id in ids {
    let bloctoPassRef = collectionRef.borrowBloctoPassPublic(id: id)
    let locked = bloctoPassRef.getLockupAmount()
    let stakerInfo = bloctoPassRef.getStakingInfo()
    let totalStaked = stakerInfo.tokensStaked
    let requestUnstake = stakerInfo.tokensRequestedToUnstake
    let requestCommitted = stakerInfo.tokensCommitted
    var unlockedStaked = totalStaked > locked ? totalStaked - locked : 0.0
    var lockedStaked = locked > totalStaked ? totalStaked : locked
    unlockedAmount = unlockedAmount + unlockedStaked
    lockedAmount = lockedAmount + lockedStaked
    tokensRequestedToUnstake = tokensRequestedToUnstake + requestUnstake
    tokensCommitted = tokensCommitted + requestCommitted
  }

  return [lockedAmount, unlockedAmount, tokensRequestedToUnstake, tokensCommitted]
}
`

export default script
