import React from 'react'
import { Box, Flex, Text, Link, Image } from '@chakra-ui/react'
import IconLink from './IconLink'
import Discord from '../../assets/svg/community/Discord.svg'
import Twitter from '../../assets/svg/community/Twitter.svg'
import Github from '../../assets/svg/community/Github.svg'
import Web from '../../assets/svg/community/Web.svg'
import Medium from '../../assets/svg/community/medium.svg'
import { useTranslation } from 'react-i18next'

const FooterLink = (props: any) => (
  <Link
    d="block"
    target="_blank"
    rel="noopener noreferrer"
    mb={2}
    _hover={{
      textDecoration: 'none',
      opacity: 0.8,
      transform: 'scale(0.98)'
    }}
    _visited={{ opacity: 0.8, transform: 'scale(0.96)' }}
    transition="all .2s"
    {...props}
  />
)

const Footer = () => {
  const { t } = useTranslation()
  return (
    <Box bg="background.tertiary" py={5} px={7} pb={{ base: '86px', lg: '28px' }}>
      <Flex
        justify={{ base: 'center', lg: 'space-between' }}
        alignItems={{ base: 'center', lg: 'flex-start' }}
        direction={{ base: 'column', lg: 'row' }}
        mx={{ base: '0px', lg: '100px' }}
        my={{ base: '30px', lg: '60px' }}
      >
        <Flex justify="space-between" width="300px" color="font.primary">
          <Box>
            <Text fontSize="xl" fontWeight="500" mb={4}>
              {t('footer.company.title')}
            </Text>
            <FooterLink href="https://portto.com/">{t('footer.company.blocto')}</FooterLink>
            <FooterLink href="https://token.blocto.app/">{t('footer.company.token')}</FooterLink>
            <FooterLink href="https://bay.blocto.app/">{t('footer.company.bay')}</FooterLink>
          </Box>
          <Box>
            <Text fontSize="xl" fontWeight="500" mb={4}>
              {t('footer.support.title')}
            </Text>
            <FooterLink href="https://portto.com/terms/">{t('footer.support.terms')}</FooterLink>
            <FooterLink href="https://portto.com/privacy/">{t('footer.support.privacy')}</FooterLink>
            <FooterLink href="https://portto.zendesk.com/hc/">{t('footer.support.help_center')}</FooterLink>
          </Box>
        </Flex>
        <Flex justify={{ base: 'center', lg: 'flex-end' }} mt={{ base: 12, lg: 0 }}>
          <IconLink href="https://twitter.com/BloctoApp" mx="10px">
            <Image src={Twitter} alt="twitter" boxSize="20px" />
          </IconLink>
          <IconLink href="http://discord.gg/QRZTr6yHmY" mx="10px">
            <Image src={Discord} alt="discord" boxSize="20px" />
          </IconLink>
          <IconLink href="https://github.com/portto" mx="10px">
            <Image src={Github} alt="github" boxSize="20px" />
          </IconLink>
          <IconLink href="https://medium.com/portto" mx="10px">
            <Image src={Medium} alt="medium" boxSize="20px" />
          </IconLink>
          <IconLink href="https://portto.com" mx="10px">
            <Image src={Web} alt="website" boxSize="20px" />
          </IconLink>
        </Flex>
      </Flex>

      <Box as="hr" my={6} />

      <Text fontSize={14} align="center" color="font.secondary" opacity=".65">
        {t('footer.disclaimer')}
      </Text>
    </Box>
  )
}

export default Footer
