import { Currency } from '@uniswap/sdk'
import { TokenInfoName } from '../../types'
import React, { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FixedSizeList } from 'react-window'
import { CloseIcon } from '../../theme'
import Column from '../Column'
import { RowBetween } from '../Row'
import CurrencyList from './TeleportCurrencyList'
import NetworkList from './NetworkList'
import SortButton from './SortButton'
import { PaddedColumn, SearchInput, Separator } from './styleds'
import AutoSizer from 'react-virtualized-auto-sizer'
import { TELEPORT_TOKENS, TELEPORT_SELECTIONS } from '../../constants/lists'
import { useFclReact } from '../../fcl-react'
import mapCoreSymbol from '../../utils/mapCoreSymbol'
import { Center, Box, Flex, Image } from '@chakra-ui/react'
import { ChevronRight } from 'react-feather'
import BackIcon from '../../assets/icon/navigation/arror_back.svg'

const Dots = () => (
  <Center
    flex={1}
    h="1px"
    mx="12px"
    backgroundImage="linear-gradient(to right, transparent 40%, #BFC2C6 40%)"
    backgroundSize="4px 100%"
    position="relative"
  >
    <Box position="absolute" right="-6px">
      <ChevronRight color="#BFC2C6" size="15px" />
    </Box>
  </Center>
)

interface TeleportSearchProps {
  isOpen: boolean
  onDismiss: () => void
  onSelect: (select: object) => void
  otherSelectedCurrency?: Currency | null
  isMainSelector?: boolean
}

export function TeleportSearch({
  onSelect,
  otherSelectedCurrency,
  onDismiss,
  isOpen,
  isMainSelector
}: TeleportSearchProps) {
  const { t } = useTranslation()
  const fixedList = useRef<FixedSizeList>()
  const [searchQuery, setSearchQuery] = useState<string>('')
  const [invertSearchOrder, setInvertSearchOrder] = useState<boolean>(false)
  const [selectNetwork, setSelectNetwork] = useState<string[]>([])
  const selection = useRef<object>()
  const { chainId } = useFclReact()

  const handleSelect = useCallback(
    select => {
      onSelect(TELEPORT_TOKENS[select.name][select.network][chainId])
      onDismiss()
    },
    [onDismiss, onSelect, chainId]
  )

  const filteredSortedTokens: TokenInfoName[] = useMemo(() => {
    const filterSorted = TELEPORT_SELECTIONS.filter(token =>
      token.symbol.toLowerCase().includes(searchQuery.toLowerCase())
    )
    if (invertSearchOrder) {
      filterSorted.reverse()
    }
    return filterSorted
  }, [invertSearchOrder, searchQuery])

  const filterSortedNetworks = useMemo(() => {
    const filterSorted = selectNetwork.filter(network => network.toLowerCase().includes(searchQuery.toLowerCase()))
    if (invertSearchOrder) {
      filterSorted.reverse()
    }
    return filterSorted
  }, [selectNetwork, invertSearchOrder, searchQuery])

  const handleCurrencySelect = useCallback(
    currency => {
      selection.current = { ...selection.current, name: currency.symbol }
      if (currency.networks.length > 1) {
        setSelectNetwork(currency.networks)
      } else {
        selection.current = { ...selection.current, network: currency.networks[0] }
        handleSelect(selection.current)
      }
    },
    [handleSelect]
  )

  const handleNetworkSelect = useCallback(
    (network: string) => {
      selection.current = { ...selection.current, network: network }
      handleSelect(selection.current)
    },
    [handleSelect]
  )

  useEffect(() => {
    // clear the input on open
    if (isOpen) setSearchQuery('')

    // auto select currency for non main selector
    if (!isMainSelector) {
      const list = TELEPORT_SELECTIONS.filter(token =>
        token.symbol.toLowerCase().includes(mapCoreSymbol(otherSelectedCurrency?.symbol).toLowerCase())
      )
      if (!list.length) return

      selection.current = { ...list[0] }
      setSelectNetwork(list[0].networks ?? [])
    }
  }, [isOpen, isMainSelector, otherSelectedCurrency])

  // manage focus on modal show
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(event => {
    const input = event.target.value
    setSearchQuery(input)
    fixedList.current?.scrollTo(0)
  }, [])

  return (
    <Column style={{ width: '100%', flex: '1 1' }}>
      <Flex justifyContent="space-between" alignItems="center" p="16px 24px">
        <Center fontWeight={500} fontSize="16px">
          {!!selectNetwork.length && (
            <Box
              onClick={() => {
                setSelectNetwork([])
              }}
              mr="8px"
              cursor="pointer"
            >
              <Image boxSize="20px" src={BackIcon} alt="Back" />
            </Box>
          )}
          {selectNetwork.length ? t('currency.title.select_chain') : t('currency.title.select_token')}
        </Center>
        <CloseIcon onClick={onDismiss} />
      </Flex>

      <Separator />

      <Center
        fontWeight={500}
        m="24px 24px 0"
        p="8px 16px"
        height="36px"
        bgColor="background.tertiary"
        borderRadius="full"
      >
        <Box color="font.highlight">{t('currency.indicator.step1')}</Box>
        <Dots />
        <Box color={selectNetwork.length ? 'font.highlight' : 'font.secondary'}>{t('currency.indicator.step2')}</Box>
      </Center>

      <PaddedColumn gap="14px">
        <SearchInput
          type="text"
          id="token-search-input"
          placeholder={t('currency.tokenSearchPlaceholder')}
          value={searchQuery}
          ref={inputRef as RefObject<HTMLInputElement>}
          onChange={handleInput}
        />
        <RowBetween>
          <Box fontSize={14} fontWeight={500} color="font.secondary">
            {selectNetwork.length ? t('currency.list_title.blockchain') : t('currency.list_title.token')}
          </Box>
          <SortButton ascending={invertSearchOrder} toggleSortOrder={() => setInvertSearchOrder(iso => !iso)} />
        </RowBetween>
      </PaddedColumn>

      <div style={{ flex: '1' }}>
        <AutoSizer disableWidth>
          {({ height }) =>
            !selectNetwork.length ? (
              <CurrencyList
                height={height}
                tokens={filteredSortedTokens}
                onCurrencySelect={handleCurrencySelect}
                otherCurrency={otherSelectedCurrency}
                fixedListRef={fixedList}
                isMainSelector={isMainSelector}
              />
            ) : (
              <NetworkList
                height={height}
                networks={filterSortedNetworks}
                currentSelection={selection}
                otherCurrency={otherSelectedCurrency}
                onNetworkSelect={handleNetworkSelect}
                fixedListRef={fixedList}
              />
            )
          }
        </AutoSizer>
      </div>
    </Column>
  )
}
