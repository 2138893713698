import React from 'react'
import styled from 'styled-components'
import { CardProps, Text } from 'rebass'
import { Box } from 'rebass/styled-components'
import { Box as NewBox } from '@chakra-ui/react'

const Card = styled(Box)<{ padding?: string; border?: string; borderRadius?: string }>`
  width: 100%;
  border-radius: 20px;
  padding: 1.25rem;
  padding: ${({ padding }) => padding};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  box-shadow: 0 10px 20px 15px rgba(0, 0, 0, 0.02);
`
export default Card

export const PlainCard = styled(Card)`
  box-shadow: none;
`

export const LightCard = styled(Card)`
  border: 1px solid ${({ theme }) => theme.bg2};
  background-color: ${({ theme }) => theme.bg1};
`

export const GreyCard = styled(Card)`
  background-color: ${({ theme }) => theme.bg3};
`

// export const OutlineCard = styled(Card)`
//   border: 1px solid ${({ theme }) => theme.bg3};
//   box-shadow: none;
//   border-radius: 10px;
// `
interface OutLineCardProps {
  children: React.ReactNode
}

export const OutlineCard = ({ children }: OutLineCardProps) => {
  return (
    <NewBox bg="background.primary" p="16px" border="1px solid" borderColor="border.secondary" borderRadius="12px">
      {children}
    </NewBox>
  )
}

export const YellowCard = styled(Card)`
  background-color: rgba(243, 132, 30, 0.05);
  color: ${({ theme }) => theme.yellow2};
  font-weight: 500;
`

export const PinkCard = styled(Card)`
  background-color: rgba(255, 0, 122, 0.03);
  color: ${({ theme }) => theme.primary1};
  font-weight: 500;
`

const BlueCardStyled = styled(Card)`
  background-color: ${({ theme }) => theme.primary5};
  color: ${({ theme }) => theme.primary1};
  border-radius: 12px;
  width: fit-content;
`

export const BlueCard = ({ children, ...rest }: CardProps) => {
  return (
    <BlueCardStyled {...rest}>
      <Text fontWeight={500} color="#2172E5">
        {children}
      </Text>
    </BlueCardStyled>
  )
}
