import { useEffect, useMemo, useState } from 'react'
import * as types from '@onflow/types'
import { replaceContractAddresses } from './env'
import { useFclReact } from './useFclReact'
import getUserStakedBLT from './scripts/getUserStakedBLT'

export function useUserStakedBLT() {
  const { fcl, chainId, account } = useFclReact()
  const [data, setData] = useState<{
    stakedLocked: number
    stakedUnlocked: number
    stakedRequestedToUnstake: number
    stakedRequestedToCommit: number
  }>({
    stakedLocked: 0,
    stakedUnlocked: 0,
    stakedRequestedToUnstake: 0,
    stakedRequestedToCommit: 0
  })

  useEffect(() => {
    if (!account) {
      return
    }
    fcl
      .send([
        fcl.script(replaceContractAddresses(getUserStakedBLT, chainId)),
        fcl.args([fcl.arg(account, types.Address)])
      ])
      .then(fcl.decode)
      .then((result: string[]) =>
        setData({
          stakedLocked: parseFloat(result[0]),
          stakedUnlocked: parseFloat(result[1]),
          stakedRequestedToUnstake: parseFloat(result[2]),
          stakedRequestedToCommit: parseFloat(result[3])
        })
      )
      .catch((error: Error) => {
        console.log(error)
      })
  }, [fcl, chainId, account])

  return useMemo(() => data, [data])
}
