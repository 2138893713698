import React from 'react'
import { Flex } from '@chakra-ui/react'

export const ListItem = ({ children, onClick, active, clickable, ...props }: any) => (
  <Flex
    backgroundColor={active ? 'background.secondary' : 'background.tertiary'}
    position="relative"
    p="16px"
    borderRadius="12px"
    alignItems="center"
    cursor={clickable ? 'pointer' : 'auto'}
    onClick={onClick}
    {...props}
  >
    {children}
  </Flex>
)

export const TitleBox = ({ children }: { children: React.ReactNode }) => (
  <Flex
    height="54px"
    width="100%"
    p="16px 24px"
    borderBottom="1px solid"
    borderColor="border.secondary"
    alignItems="center"
    fontWeight={500}
  >
    {children}
  </Flex>
)
