import { createContext } from 'react'

interface AptosContextInterface {
  aptos: any
  setAptos?: (s: any) => void
}

export const AptosContext = createContext<AptosContextInterface | null>({
  aptos: null,
  setAptos: () => null
})
