import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import ReactGA from 'react-ga'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import ConfirmSwapModal from '../../components/swap/ConfirmSwapModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import AdvancedSwapDetailsDropdown from '../../components/swap/AdvancedSwapDetailsDropdown'
import confirmPriceImpactWithoutFee from '../../components/swap/confirmPriceImpactWithoutFee'
import { Wrapper } from '../../components/swap/styleds'
import TradePrice from '../../components/swap/TradePrice'

import { Price } from '../../types'
import { useFclReact, useSwapCallback, useStorageFee, Trade } from '../../fcl-react'
import { Field } from '../../state/swap/actions'
import {
  useDefaultsFromURLSearch,
  useDerivedSwapInfo,
  useSwapActionHandlers,
  useSwapState
} from '../../state/swap/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import { computeTradePriceBreakdown, warningSeverity } from '../../utils/prices'
import AppBody from '../AppBody'
import Settings from '../../components/Settings'
import { Box, Center, Image, Grid, Flex, Button } from '@chakra-ui/react'
import ChangeIcon from '../../assets/icon/view/exchange.svg'
import MainButton from '../../components/Common/MainButton'
import { useTranslation } from 'react-i18next'
import { FUSD, currentEnv } from '../../constants'
import { ChainId } from '../../utils'
import { useDelistWarningToggle } from '../../state/application/hooks'

const chainId = currentEnv === 'mainnet' ? ChainId.MAINNET : ChainId.SEPOLIA

export default function Swap() {
  useDefaultsFromURLSearch()

  const { account } = useFclReact()
  const theme = useContext(ThemeContext)
  const { t } = useTranslation()

  const storageFee = useStorageFee(account)

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  // swap state
  const {
    independentField,
    typedValue,
    [Field.INPUT]: { currencyId: inputCurrencyAddr },
    [Field.OUTPUT]: { currencyId: outputCurrencyAddr }
  } = useSwapState()
  const { trade, currencyBalances, parsedAmount, currencies, inputError: swapInputError } = useDerivedSwapInfo()

  const noEnoughLiquidity = trade?.inputAmount === Number.MAX_VALUE

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
  }

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useSwapActionHandlers()
  const isValid = !swapInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT
  const toggleDelistWarning = useDelistWarningToggle()

  useEffect(() => {
    const noFUSDPresent = inputCurrencyAddr !== FUSD[chainId].address && outputCurrencyAddr !== FUSD[chainId].address
    toggleDelistWarning(!noFUSDPresent)
  }, [inputCurrencyAddr, outputCurrencyAddr, toggleDelistWarning])

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, swapErrorMessage, attemptingTxn, txHash }, setSwapState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    swapErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    swapErrorMessage: undefined,
    txHash: undefined
  })

  const dependentAmount =
    parsedAmounts[dependentField] === Number.MAX_VALUE || parsedAmounts[dependentField] === 0
      ? ''
      : parsedAmounts[dependentField]?.toFixed(8) ?? ''

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: dependentAmount
  }

  const maxAmountInput: number | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
    currencies[Field.INPUT]?.symbol,
    storageFee
  )
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT] === maxAmountInput)

  // the callback to execute the swap
  const { callback: swapCallback, error: swapCallbackError } = useSwapCallback(trade, allowedSlippage)

  const { priceImpactWithoutFee } = computeTradePriceBreakdown(trade)

  const handleSwap = useCallback(() => {
    if (priceImpactWithoutFee && !confirmPriceImpactWithoutFee(priceImpactWithoutFee)) {
      return
    }
    if (!swapCallback) {
      return
    }
    setSwapState({ attemptingTxn: true, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: undefined })
    swapCallback()
      .then(hash => {
        setSwapState({ attemptingTxn: false, tradeToConfirm, showConfirm, swapErrorMessage: undefined, txHash: hash })

        ReactGA.event({
          category: 'Swap',
          action: 'Swap w/o Send',
          label: [trade?.inputCurrency?.symbol, trade?.outputCurrency?.symbol].join('/')
        })
      })
      .catch(error => {
        setSwapState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          swapErrorMessage: error.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, priceImpactWithoutFee, showConfirm, swapCallback, trade])

  // errors
  const [showInverted, setShowInverted] = useState<boolean>(false)

  // warnings on slippage
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)

  const handleConfirmDismiss = useCallback(() => {
    setSwapState({ showConfirm: false, tradeToConfirm, attemptingTxn, swapErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, swapErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setSwapState({ tradeToConfirm: trade, swapErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, swapErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(inputCurrency => onCurrencySelection(Field.INPUT, inputCurrency), [
    onCurrencySelection
  ])

  const handleMaxInput = useCallback(() => {
    maxAmountInput !== undefined && onUserInput(Field.INPUT, maxAmountInput.toString())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const price = useMemo(() => {
    const processedPrice: Price = {
      baseCurrency: trade?.inputCurrency,
      quoteCurrency: trade?.outputCurrency,
      price: trade?.executionPrice
    }

    return processedPrice
  }, [trade])

  return (
    <>
      <AppBody>
        <Center justifyContent="space-between" mb="21px">
          <Box fontWeight="500">{t('swap.title')}</Box>
          <Center>
            <Box fontSize="14px" color="font.secondary" mr="8px">
              {t('swap.slippage', { PERCENT: allowedSlippage / 100 })}
            </Box>
            <Settings />
          </Center>
        </Center>

        <Wrapper id="swap-page">
          <ConfirmSwapModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            allowedSlippage={allowedSlippage}
            onConfirm={handleSwap}
            swapErrorMessage={swapErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <Grid>
            <CurrencyInputPanel
              label={t('swap.from')}
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              id="swap-currency-input"
              isEstimated={independentField === Field.OUTPUT && !!trade}
            />

            <Button>
              <Center>
                <Center
                  m="12px"
                  boxSize="40px"
                  borderRadius="full"
                  bgColor="interaction.secondary"
                  cursor="pointer"
                  onClick={() => {
                    onSwitchTokens()
                  }}
                >
                  <Image src={ChangeIcon} boxSize="20px" />
                </Center>
              </Center>
            </Button>

            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label={t('swap.to')}
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              id="swap-currency-output"
              isEstimated={independentField === Field.INPUT && !!trade}
            />
            {Boolean(trade) && !noEnoughLiquidity && (
              <Flex justifyContent="space-between" mt="16px">
                <Text fontWeight={500} fontSize={14} color={theme.text2}>
                  {t('swap.price')}
                </Text>
                <TradePrice price={price} showInverted={showInverted} setShowInverted={setShowInverted} />
              </Flex>
            )}
          </Grid>

          <Box mt="20px">
            <AdvancedSwapDetailsDropdown trade={trade} />
          </Box>

          <Box mt="20px">
            <MainButton
              onClick={() => {
                setSwapState({
                  tradeToConfirm: trade,
                  attemptingTxn: false,
                  swapErrorMessage: undefined,
                  showConfirm: true,
                  txHash: undefined
                })
              }}
              disabled={!account || noEnoughLiquidity || !isValid || priceImpactSeverity > 3 || !!swapCallbackError}
            >
              {!account
                ? t('swap.button.connect')
                : noEnoughLiquidity
                ? t('swap.button.insufficientLiquidity')
                : swapInputError
                ? swapInputError
                : priceImpactSeverity > 3
                ? t('swap.button.impactTooHigh')
                : priceImpactSeverity > 2
                ? t('swap.button.swapAnyway')
                : t('swap.button.swap')}
            </MainButton>
          </Box>
        </Wrapper>
      </AppBody>
    </>
  )
}
