import { Contract } from '@ethersproject/contracts'
import { WETH } from '@uniswap/sdk'
import { ChainId } from '../utils'
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json'
import { useMemo } from 'react'
import {
  ARGENT_WALLET_DETECTOR_ABI,
  ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS
} from '../constants/abis/argent-wallet-detector'
import ENS_PUBLIC_RESOLVER_ABI from '../constants/abis/ens-public-resolver.json'
import ENS_ABI from '../constants/abis/ens-registrar.json'
import { ERC20_BYTES32_ABI } from '../constants/abis/erc20'
import ERC20_ABI from '../constants/abis/erc20.json'
import { MIGRATOR_ABI, MIGRATOR_ADDRESS } from '../constants/abis/migrator'
import UNISOCKS_ABI from '../constants/abis/unisocks.json'
import WETH_ABI from '../constants/abis/weth.json'
import TELEPORT_ETH_ABI from '../constants/abis/teleportETH.json'
import TELEPORT_ABI_NEW from '../constants/abis/teleportNew.json'
import { MULTICALL_ABI, MULTICALL_NETWORKS } from '../constants/multicall'
import { getContract, isEvmChain } from '../utils'
import { useActiveWeb3React } from './index'
import { TELEPORT_ADDRESS_MAP } from '../constants'
import { Trade } from '../fcl-react'

// returns null on errors
function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React()

  return useMemo(() => {
    if (!address || !ABI || !library) return null

    return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined) || null
  }, [address, ABI, library, withSignerIfPossible, account])
}

export function useV2MigratorContract(): Contract | null {
  return useContract(MIGRATOR_ADDRESS, MIGRATOR_ABI, true)
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible)
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    // Because we didn't update the SDK so WETH doesn't include the address for sepolia
    // So we add the address manually here: 0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    chainId ? (chainId in WETH ? WETH[chainId].address : '0xfFf9976782d46CC05630D1f6eBAb18b2324d6B14') : undefined,
    WETH_ABI,
    withSignerIfPossible
  )
}

export function useArgentWalletDetectorContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? ARGENT_WALLET_DETECTOR_MAINNET_ADDRESS : undefined,
    ARGENT_WALLET_DETECTOR_ABI,
    false
  )
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React()
  let address: string | undefined
  if (chainId) {
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.RINKEBY:
      case ChainId.SEPOLIA:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'
        break
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible)
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible)
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible)
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible)
}

export function useMulticallContract(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(chainId && MULTICALL_NETWORKS[chainId], MULTICALL_ABI, false)
}

export function useSocksController(): Contract | null {
  const { chainId } = useActiveWeb3React()
  return useContract(
    chainId === ChainId.MAINNET ? '0x65770b5283117639760beA3F867b69b3697a91dd' : undefined,
    UNISOCKS_ABI,
    false
  )
}

export function useTeleportContract(trade?: Trade): Contract | null {
  const { chainId } = useActiveWeb3React()
  const isBSC = (chainId as number) === 56 || (chainId as number) === 97

  const symbol: string | undefined = trade?.inputCurrency?.symbol
  let teleportABI: any = TELEPORT_ETH_ABI

  // NOTE - BLT ethereum is use new abi
  if (isBSC || (symbol === 'BLT' && isEvmChain(chainId as number))) {
    teleportABI = TELEPORT_ABI_NEW
  }

  return useContract(
    TELEPORT_ADDRESS_MAP[trade?.inputCurrency.address || '0xdAC17F958D2ee523a2206206994597C13D831ec7'],
    teleportABI
  )
}
