import { useMemo } from 'react'
import { useFclReact } from './useFclReact'
import removeLiquidity from './transactions/removeLiquidity'
import { Pair } from '../types'
import { replaceContractAddresses } from './env'

// returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useRemoveLiquidity(): Function | undefined {
  const { fcl, authorization, types, chainId } = useFclReact()

  return useMemo(() => {
    const isSealed = false
    return (pair: Pair, amount: number) =>
      fcl
        .send([fcl.getBlock(isSealed)])
        .then(fcl.decode)
        .then((block: any) =>
          fcl.send([
            fcl.transaction(
              replaceContractAddresses(removeLiquidity[pair.token0.symbol ?? ''][pair.token1.symbol ?? ''], chainId)
            ),
            fcl.args([fcl.arg(amount.toFixed(8), types.UFix64)]),
            fcl.limit(200),
            fcl.proposer(authorization),
            fcl.authorizations([authorization]),
            fcl.payer(authorization),
            fcl.ref(block.id)
          ])
        )
        .catch((error: Error) => {
          // if the user rejected the tx, pass this along
          if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
            throw new Error('Transaction rejected.')
          } else {
            // otherwise, the error was unexpected and we need to convey that
            console.error(`Remove failed`, error, removeLiquidity[pair.token0.symbol ?? ''][pair.token1.symbol ?? ''])
            throw new Error(`Remove failed: ${error.message}`)
          }
        })
  }, [authorization, fcl, types, chainId])
}
