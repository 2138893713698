import { createAction, createAsyncThunk } from '@reduxjs/toolkit'
import { IDOProjectConfig, IDOUserInfo } from '../../fcl-react'
import { StepState, StepID } from '../../types'

export interface StepObject {
  state: StepState
  ongoing: boolean
  showOptions: boolean
  open: boolean
  startTime: number
  endTime: number
}
export interface Steps {
  kyc: StepObject
  pool: StepObject
  deposit: StepObject
  complete: StepObject
}
export interface Project {
  name: string
  contractName: string
  symbol: string
  iconUrl: string
  price: number
  minStakingAmount: number
  start?: number
  end?: number
  descriptions: string[]
  rules: string[]
  status: 'Active' | 'Upcoming' | 'Ended'
  kycPassed?: boolean
  website: string
  flowscanUrl: string
  discordUrl?: string
  telegramUrl?: string
  twitterUrl?: string
  kycUrl?: string
  projectUrl?: string
  timeline: Steps
  userInfo: IDOUserInfo
  snapshotTimes: number
  poolConfig: {
    [key in 'UNLIMITED' | 'LIMITED']: {
      amount: string
      upperBound: string
      selectFee: string
      exchangeRate: string
      minimumStake: string
    }
  }
}

function transformProject(
  name: string,
  config: IDOProjectConfig,
  extra: any,
  userInfo?: IDOUserInfo,
  timeline?: Steps
): Project {
  const { KYC, SELECT_POOL, DEPOSIT, DISTRIBUTE } = config.schedule

  const start = KYC?.[0] ? parseInt(KYC[0], 10) : undefined
  // in case no exact token distribution time, use deposit end time
  const depositEnd = DEPOSIT?.[1] ? parseInt(DEPOSIT[1], 10) : undefined
  const end = DISTRIBUTE?.[1] ? parseInt(DISTRIBUTE[1], 10) : depositEnd
  const now = Date.now() / 1000
  const status = (() => {
    switch (true) {
      case start && now < Number(start):
        return 'Upcoming'
      case (!start || start <= now) && (!end || now < end):
        return 'Active'
      case !end || Number(end) <= now:
        return 'Ended'
      default:
        return 'Ended'
    }
  })()

  const computeStatus = (rawStart?: string, rawEnd?: string, rawNextStart?: string) => {
    const start = rawStart ? parseInt(rawStart, 10) : undefined
    const end = rawEnd ? parseInt(rawEnd, 10) : undefined
    const nextStart = rawNextStart ? parseInt(rawNextStart, 10) : undefined
    const effectiveEnd = nextStart || end
    return {
      state: StepState.unactive,
      ongoing: (!start || start <= now) && (!end || now < end),
      open: (!start || start <= now) && (!effectiveEnd || now < effectiveEnd),
      showOptions: false,
      startTime: start,
      endTime: end
    }
  }

  return {
    name,
    contractName: config.tokenInfo.contractName,
    price: parseFloat(config.poolConfig.UNLIMITED.exchangeRate),
    minStakingAmount: parseFloat(config.poolConfig.UNLIMITED.minimumStake),
    start,
    end,
    status,
    timeline: timeline || {
      [StepID.kyc]: computeStatus(KYC?.[0], KYC?.[1], SELECT_POOL?.[0]),
      [StepID.pool]: computeStatus(SELECT_POOL?.[0], SELECT_POOL?.[1], DEPOSIT?.[0]),
      [StepID.deposit]: computeStatus(DEPOSIT?.[0], DEPOSIT?.[1], DISTRIBUTE?.[0]),
      [StepID.complete]: computeStatus(DISTRIBUTE?.[0], DISTRIBUTE?.[1])
    },
    userInfo: userInfo || {},
    poolConfig: config.poolConfig,
    snapshotTimes: config.snapshotTimes,
    ...extra
  }
}

export const updateIDOState = createAction<{ projectName: string; timeline: Steps }>('IDO/UPDATE_STATE')
export const updateOpenSection = createAction<{ id: StepID; open: boolean; projectName: string }>('IDO/UPDATE_OPEN')

export const loadProjects = createAsyncThunk<{ [key: string]: Project }, { [key: string]: IDOProjectConfig }>(
  'IDO/LOAD_PROJECTS',
  async projectConfigs => {
    const extraDataRequests = Object.keys(projectConfigs).map(async name => [
      name,
      await fetch(`${process.env.REACT_APP_IDO_METADATA_URL}/${name}.json`).then(response => response.json())
    ])
    const extraData = Object.fromEntries(await Promise.all(extraDataRequests))

    const projects = Object.fromEntries(
      Object.entries(projectConfigs).map(([name, config]) => [name, transformProject(name, config, extraData[name])])
    )

    return projects
  }
)

export const loadProject = createAsyncThunk<
  { name: string; project: Project },
  { name: string; config: IDOProjectConfig }
>('IDO/LOAD_PROJECT', async ({ name, config }) => {
  const extraData = await fetch(`${process.env.REACT_APP_IDO_METADATA_URL}/${name}.json`).then(response =>
    response.json()
  )

  return { name, project: transformProject(name, config, extraData) }
})

export const loadUserInfo = createAction<{ projectName: string; userInfo: IDOUserInfo }>('IDO/LOAD_USER_INFO')
