import React from 'react'
import { useURLWarningToggle } from '../../state/user/hooks'
import Warning from './Warning'

export default function URLWarning({ displayOrder }: { displayOrder: number }) {
  const toggleURLWarning = useURLWarningToggle()

  return (
    <Warning
      closeWarning={toggleURLWarning}
      content={
        <span>
          Make sure the URL is
          <code style={{ padding: '0 4px', display: 'inline', fontWeight: 'bold' }}>swap.blocto.app</code>
        </span>
      }
      displayOrder={displayOrder}
    />
  )
}
