import React from 'react'
import { Trade } from '../../fcl-react'
import { TeleportCallbackError } from './styleds'
import { AdvancedTeleportDetails } from './AdvancedTeleportDetails'
import { useLastTruthy } from '../../hooks/useLast'
import { Box } from '@chakra-ui/react'
import MainButton from '../../components/Common/MainButton'
import { useTranslation } from 'react-i18next'

export default function TeleportModalFooter({
  trade,
  onConfirm,
  teleportErrorMessage
}: {
  trade: Trade
  onConfirm: () => void
  teleportErrorMessage: string | undefined
}) {
  const lastTrade = useLastTruthy(trade)
  const { t } = useTranslation()

  return (
    <>
      <AdvancedTeleportDetails trade={trade ?? lastTrade ?? undefined} />
      <Box mt="20px">
        <MainButton onClick={onConfirm}>{t('teleport.button.confirm_teleport')}</MainButton>
        {teleportErrorMessage ? <TeleportCallbackError error={teleportErrorMessage} /> : null}
      </Box>
    </>
  )
}
