import React, { useState } from 'react'
import styled from 'styled-components'
import { isMobile } from 'react-device-detect'
import Tooltip from '../Tooltip'
import { ReactComponent as AlertIcon } from '../../assets/icon/status/alert.svg'

const TooltipContainer = styled.div`
  display: inline-block;
  margin-left: 8px;
  vertical-align: middle;
`

const Tag = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 20px;
  color: #ffb200;
  background: #fff8e0;
  padding: 4px 8px;
  border-radius: 32px;
`

const ColoredAlertIcon = styled(AlertIcon)`
  margin-right: 4px;
  width: 16px;
  height: 16px;

  path {
    fill: #ffb200;
  }
`

export default function DelistTag({ tooltippContent }: { tooltippContent: string }) {
  const [isShown, setIsShown] = useState(false)
  return (
    <TooltipContainer>
      <Tooltip content={tooltippContent} show={isShown}>
        <Tag
          onMouseEnter={() => {
            if (!isMobile) setIsShown(true)
          }}
          onClick={e => {
            if (isMobile) {
              e.stopPropagation()
              setIsShown(prev => !prev)
            }
          }}
          onMouseLeave={() => setIsShown(false)}
        >
          <ColoredAlertIcon />
          Delist soon
        </Tag>
      </Tooltip>
    </TooltipContainer>
  )
}
