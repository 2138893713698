import { StepID } from '../../../types'

export default function SwapStepName(name: StepID) {
  switch (name) {
    case StepID.kyc:
      return 'Complete your KYC'
    case StepID.pool:
      return 'Select a pool'
    case StepID.deposit:
      return 'Deposit'
    case StepID.complete:
      return 'Token distribution'
    default:
      return null
  }
}
