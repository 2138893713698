import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
interface FlexProps {
  width?: string
}
const Flex = styled.div<FlexProps>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
`
const HelpButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`
const BigIcon = styled.div`
  width: 55px;
  height: 55px;
  margin-right: 20px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: none;
  `}
`
const SmallIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 11px;
  display: none;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: inline-block;
  `}
  & img {
    width: 24px;
  }
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`
interface PriceFieldProps {
  fullwidth?: boolean
}
const PriceField = styled.div<PriceFieldProps>`
  width: 100%;
  margin-top: 12px;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${props => props.fullwidth && `flex-wrap: wrap;`}
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
  `}
  & input {
    padding: 0 5px;
    height: 40px;
    border: none;
    font-weight: 600;
    width: 100%;
    border-bottom: 1px solid #bcbcbc;
    font-size: 20px;
    color: #43a0ff;
    margin: 0 8px;
    &:focus {
      outline: none;
    }
    &::placeholder {
      color: #bcbcbc;
    }
  }
`
interface InputWrapperProps {
  width?: string
}
const InputWrapper = styled.div<InputWrapperProps>`
  display: flex;
  align-items: center;
  width: ${props => props.width || '200px'};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`
const FirstLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
`
const MaxButton = styled.div`
  display: inline;
  margin-right: 10px;
  width: 54px;
  height: 26px;
  background: #f9f9f9;
  border-radius: 20px;
  padding: 4px 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #43a0ff;
  cursor: pointer;
`

export default function PriceBox({
  children,
  icon,
  title,
  content,
  showInput,
  symbol,
  onChange,
  maxPrice,
  helpButton,
  fullwidth,
  color
}: {
  children?: any
  icon: any
  title?: any
  content?: any
  showInput?: boolean
  symbol?: any
  onChange?: (input: string) => void
  maxPrice?: string
  helpButton?: any
  fullwidth?: boolean
  color?: string
}) {
  const [price, setPrice] = useState('')
  const processValue = useCallback((value: string) => {
    const normalizeDecimal = value.replace(',', '.')
    if (/^\d+[.]$|0$|^$/.test(normalizeDecimal)) return normalizeDecimal
    const parsedFloat = parseFloat(normalizeDecimal)
    if (isNaN(parsedFloat)) return ''
    if (parsedFloat < 0) return '0'
    if (parsedFloat > 184467440737) return '184467440737'
    return parsedFloat.toString()
  }, [])
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value
      const processedValue = processValue(value)
      setPrice(processedValue)
      if (onChange) onChange(processedValue)
    },
    [processValue, onChange]
  )
  useEffect(() => {
    setPrice('')
  }, [maxPrice])

  return (
    <Flex>
      {!!helpButton && <HelpButton>{helpButton}</HelpButton>}
      <BigIcon>
        <img src={icon} alt="icon" width="55px" />
      </BigIcon>
      <ContentWrapper>
        <TitleWrapper>
          <SmallIcon>
            <img src={icon} alt="icon" />
          </SmallIcon>
          {title}
        </TitleWrapper>
        <PriceField fullwidth={fullwidth}>
          <FirstLine style={{ color: color }}>
            {content}
            {showInput && (
              <InputWrapper width={!!maxPrice ? '300px' : '200px'}>
                <input type="text" placeholder="0.0" value={price} onChange={handleChange} />
                {!!maxPrice && maxPrice !== price && (
                  <MaxButton
                    onClick={() => {
                      setPrice(maxPrice)
                      if (onChange) onChange(maxPrice)
                    }}
                  >
                    MAX
                  </MaxButton>
                )}
                {symbol}
              </InputWrapper>
            )}
          </FirstLine>
          {children}
        </PriceField>
      </ContentWrapper>
    </Flex>
  )
}
