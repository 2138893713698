const scriptAddFlowUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: auth(FungibleToken.Withdraw) &FlowToken.Vault
  let tetherVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &FlowSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.flowTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath) == nil {
      let vault <- FlowSwapPair.createEmptyVault(vaultType: Type<@FlowSwapPair.Vault>())

      // Create a new FlowSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: FlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&FlowSwapPair.Vault>(
          FlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: FlowSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&FlowSwapPair.Vault>(
          FlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: FlowSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = FlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.flowTokenVaultRef.withdraw(amount: token1Amount) as! @FlowToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- FlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- FlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtFlow = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: auth(FungibleToken.Withdraw) &FlowToken.Vault
  let tetherVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &FlowSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.flowTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault>(from: /storage/flowTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath) == nil {
      let vault <- FlowSwapPair.createEmptyVault(vaultType: Type<@FlowSwapPair.Vault>())

      // Create a new FlowSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: FlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&FlowSwapPair.Vault>(
          FlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: FlowSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&FlowSwapPair.Vault>(
          FlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: FlowSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<&FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = FlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.flowTokenVaultRef.withdraw(amount: token1Amount) as! @FlowToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- FlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- FlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddBltUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenRef: auth(FungibleToken.Withdraw) &BloctoToken.Vault
  let tetherVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &BltUsdtSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.bloctoTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath) == nil {
      let vault <- BltUsdtSwapPair.createEmptyVault(vaultType: Type<@BltUsdtSwapPair.Vault>())

      // Create a new BltUsdtSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: BltUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&BltUsdtSwapPair.Vault>(
          BltUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: BltUsdtSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&BltUsdtSwapPair.Vault>(
          BltUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: BltUsdtSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.bloctoTokenRef.withdraw(amount: token1Amount) as! @BloctoToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- BltUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- BltUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtBlt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenRef: auth(FungibleToken.Withdraw) &BloctoToken.Vault
  let tetherVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &BltUsdtSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.bloctoTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &BloctoToken.Vault>(from: /storage/bloctoTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath) == nil {
      let vault <- BltUsdtSwapPair.createEmptyVault(vaultType: Type<@BltUsdtSwapPair.Vault>())

      // Create a new BltUsdtSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: BltUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&BltUsdtSwapPair.Vault>(
          BltUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: BltUsdtSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&BltUsdtSwapPair.Vault>(
          BltUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: BltUsdtSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = BltUsdtSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    
    let token1Vault <- self.bloctoTokenRef.withdraw(amount: token1Amount) as! @BloctoToken.Vault
    let token2Vault <- self.tetherVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault

    let tokenBundle <- BltUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- BltUsdtSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddRevvFlow = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let revvVaultRef: auth(FungibleToken.Withdraw) &REVV.Vault
  let flowTokenVaultRef: auth(FungibleToken.Withdraw) &FlowToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &RevvFlowSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.revvVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &REVV.Vault>(from: REVV.RevvVaultStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath) == nil {
      let vault <- RevvFlowSwapPair.createEmptyVault(vaultType: Type<@RevvFlowSwapPair.Vault>())

      // Create a new RevvFlowSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: RevvFlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&RevvFlowSwapPair.Vault>(
          RevvFlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: RevvFlowSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&RevvFlowSwapPair.Vault>(
          RevvFlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: RevvFlowSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = RevvFlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.revvVaultRef.withdraw(amount: token1Amount) as! @REVV.Vault
    let token2Vault <- self.flowTokenVaultRef.withdraw(amount: token2Amount) as! @FlowToken.Vault

    let tokenBundle <- RevvFlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- RevvFlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddFlowRevv = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let revvVaultRef: auth(FungibleToken.Withdraw) &REVV.Vault
  let flowTokenVaultRef: auth(FungibleToken.Withdraw) &FlowToken.Vault

  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &RevvFlowSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.revvVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &REVV.Vault>(from: REVV.RevvVaultStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath) == nil {
      let vault <- RevvFlowSwapPair.createEmptyVault(vaultType: Type<@RevvFlowSwapPair.Vault>())

      // Create a new RevvFlowSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: RevvFlowSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&RevvFlowSwapPair.Vault>(
          RevvFlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: RevvFlowSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&RevvFlowSwapPair.Vault>(
          RevvFlowSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: RevvFlowSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    let poolAmounts = RevvFlowSwapPair.getPoolAmounts()

    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount

    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage

    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount

    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")

    let token1Vault <- self.revvVaultRef.withdraw(amount: token1Amount) as! @REVV.Vault
    let token2Vault <- self.flowTokenVaultRef.withdraw(amount: token2Amount) as! @FlowToken.Vault

    let tokenBundle <- RevvFlowSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- RevvFlowSwapPair.addLiquidity(from: <- tokenBundle)

    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddStarlyUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS

transaction(token1In: UFix64, token2In: UFix64, token1Min: UFix64, token2Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let starlyVaultRef: auth(FungibleToken.Withdraw) &StarlyToken.Vault
  let usdtTokenVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &StarlyUsdtSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.starlyVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
    self.usdtTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath) == nil {
      let vault <- StarlyUsdtSwapPair.createEmptyVault(vaultType: Type<@StarlyUsdtSwapPair.Vault>())

      // Create a new StarlyUsdtSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: StarlyUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&StarlyUsdtSwapPair.Vault>(
          StarlyUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: StarlyUsdtSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&StarlyUsdtSwapPair.Vault>(
          StarlyUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: StarlyUsdtSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }
  execute {
    let poolAmounts = StarlyUsdtSwapPair.getPoolAmounts()
    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount
    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount
    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    let token1Vault <- self.starlyVaultRef.withdraw(amount: token1Amount) as! @StarlyToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault
    let tokenBundle <- StarlyUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- StarlyUsdtSwapPair.addLiquidity(from: <- tokenBundle)
    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scriptAddUsdtStarly = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS

transaction(token2In: UFix64, token1In: UFix64, token2Min: UFix64, token1Min: UFix64) {
  // The Vault references that holds the tokens that are being transferred
  let starlyVaultRef: auth(FungibleToken.Withdraw) &StarlyToken.Vault
  let usdtTokenVaultRef: auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: &StarlyUsdtSwapPair.Vault

  prepare(signer: auth(BorrowValue, IssueStorageCapabilityController, PublishCapability, SaveValue) &Account) {
    self.starlyVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
    self.usdtTokenVaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    if signer.storage.borrow<&StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath) == nil {
      let vault <- StarlyUsdtSwapPair.createEmptyVault(vaultType: Type<@StarlyUsdtSwapPair.Vault>())

      // Create a new StarlyUsdtSwapPair Vault and put it in storage
      signer.storage.save(<-vault, to: StarlyUsdtSwapPair.TokenStoragePath)

      // Create a public capability to the Vault that exposes the Vault interfaces
      let vaultCap = signer.capabilities.storage.issue<&StarlyUsdtSwapPair.Vault>(
          StarlyUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(vaultCap, at: StarlyUsdtSwapPair.TokenPublicBalancePath)

      // Create a public Capability to the Vault's Receiver functionality
      let receiverCap = signer.capabilities.storage.issue<&StarlyUsdtSwapPair.Vault>(
          StarlyUsdtSwapPair.TokenStoragePath
      )
      signer.capabilities.publish(receiverCap, at: StarlyUsdtSwapPair.TokenPublicReceiverPath)
    }

    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }
  execute {
    let poolAmounts = StarlyUsdtSwapPair.getPoolAmounts()
    let token1Percentage = token1In / poolAmounts.token1Amount
    let token2Percentage = token2In / poolAmounts.token2Amount
    let finalPercentage = token1Percentage < token2Percentage ? token1Percentage : token2Percentage
    let token1Amount = finalPercentage * poolAmounts.token1Amount
    let token2Amount = finalPercentage * poolAmounts.token2Amount
    assert(token1Amount > token1Min, message: "Token 1 amount too small")
    assert(token2Amount > token2Min, message: "Token 2 amount too small")
    let token1Vault <- self.starlyVaultRef.withdraw(amount: token1Amount) as! @StarlyToken.Vault
    let token2Vault <- self.usdtTokenVaultRef.withdraw(amount: token2Amount) as! @TeleportedTetherToken.Vault
    let tokenBundle <- StarlyUsdtSwapPair.createTokenBundle(fromToken1: <- token1Vault, fromToken2: <- token2Vault);
    let liquidityTokenVault <- StarlyUsdtSwapPair.addLiquidity(from: <- tokenBundle)
    self.liquidityTokenRef.deposit(from: <- liquidityTokenVault)
  }
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptAddFlowUsdt,
    REVV: scriptAddFlowRevv
  },
  tUSDT: {
    FLOW: scriptAddUsdtFlow,
    BLT: scriptAddUsdtBlt,
    STARLY: scriptAddUsdtStarly
  },
  BLT: {
    tUSDT: scriptAddBltUsdt
  },
  REVV: {
    FLOW: scriptAddRevvFlow
  },
  STARLY: {
    tUSDT: scriptAddStarlyUsdt
  }
}

export default scripts
