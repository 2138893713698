import { Currency } from '@uniswap/sdk'
import React, { useCallback, useState } from 'react'
import { ReactComponent as Plus } from '../../assets/images/plus-black.svg'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router-dom'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'

import { useFclReact, useAddLiquidity, useStorageFee } from '../../fcl-react'
import { TransactionResponse } from '../../types'
import { PairState } from '../../data/Reserves'
import { useCurrency } from '../../hooks/Tokens'
// import useTransactionDeadline from '../../hooks/useTransactionDeadline'
import { useWalletModalFlowToggle } from '../../state/application/hooks'
import { Field } from '../../state/mint/actions'
import { useDerivedMintInfo, useMintActionHandlers, useMintState } from '../../state/mint/hooks'

import { useTransactionAdder } from '../../state/transactionsFlow/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { calculateSlippageAmount } from '../../utils'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { ConfirmAddModalBottom } from './ConfirmAddModalBottom'
import { currencyId } from '../../utils/currencyId'
import { PoolPriceBar } from './PoolPriceBar'
import { Box, Flex, Text, Grid, useDisclosure, Collapse, Center, Image } from '@chakra-ui/react'
import Button from '../../components/ButtonPrimary'
import ArrowIcon from '../../assets/icon/navigation/arror_collapse.svg'
import { useTranslation } from 'react-i18next'

export default function AddLiquidity({
  match: {
    params: { currencyIdA, currencyIdB }
  },
  history
}: RouteComponentProps<{ currencyIdA?: string; currencyIdB?: string }>) {
  const { account, fcl } = useFclReact()
  const { t } = useTranslation()

  const currencyA = useCurrency(currencyIdA)
  const currencyB = useCurrency(currencyIdB)

  const toggleWalletModal = useWalletModalFlowToggle() // toggle wallet when disconnected

  // mint state
  const { independentField, typedValue, otherTypedValue } = useMintState()
  const {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  } = useDerivedMintInfo(currencyA ?? undefined, currencyB ?? undefined)
  const { onFieldAInput, onFieldBInput } = useMintActionHandlers(noLiquidity)

  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false) // clicked confirm

  // txn values
  // const deadline = useTransactionDeadline() // custom from users settings
  const [allowedSlippage] = useUserSlippageTolerance() // custom from users
  const [txHash, setTxHash] = useState<string>('')

  const storageFee = useStorageFee(account)

  const { isOpen, onToggle } = useDisclosure()

  // get formatted amounts
  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: noLiquidity ? otherTypedValue : parsedAmounts[dependentField]?.toFixed(8) ?? ''
  }

  // get the max amounts user can add
  const maxAmounts: { [field in Field]?: number } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmountSpend(currencyBalances[field], currencies[field]?.symbol, storageFee)
      }
    },
    {}
  )

  const atMaxAmounts: { [field in Field]?: number } = [Field.CURRENCY_A, Field.CURRENCY_B].reduce(
    (accumulator, field) => {
      return {
        ...accumulator,
        [field]: maxAmounts[field] === (parsedAmounts[field] ?? 0)
      }
    },
    {}
  )

  const addTransaction = useTransactionAdder()

  const addLiquidity = useAddLiquidity()

  async function onAdd() {
    if (!fcl || !account || !addLiquidity) {
      return
    }

    const { [Field.CURRENCY_A]: parsedAmountA, [Field.CURRENCY_B]: parsedAmountB } = parsedAmounts
    if (!parsedAmountA || !parsedAmountB || !currencyA || !currencyB) {
      return
    }

    const amountsMin = {
      [Field.CURRENCY_A]: calculateSlippageAmount(parsedAmountA, noLiquidity ? 0 : allowedSlippage)[0],
      [Field.CURRENCY_B]: calculateSlippageAmount(parsedAmountB, noLiquidity ? 0 : allowedSlippage)[0]
    }

    setAttemptingTxn(true)

    addLiquidity(pair, parsedAmountA, parsedAmountB, amountsMin[Field.CURRENCY_A], amountsMin[Field.CURRENCY_B]).then(
      (response: TransactionResponse) => {
        setAttemptingTxn(false)

        addTransaction(response, {
          summary:
            'Add ' +
            parsedAmounts[Field.CURRENCY_A]?.toFixed(4) +
            ' ' +
            currencies[Field.CURRENCY_A]?.symbol +
            ' and ' +
            parsedAmounts[Field.CURRENCY_B]?.toFixed(4) +
            ' ' +
            currencies[Field.CURRENCY_B]?.symbol
        })

        setTxHash(response.transactionId || '')

        ReactGA.event({
          category: 'Liquidity',
          action: 'Add',
          label: [currencies[Field.CURRENCY_A]?.symbol, currencies[Field.CURRENCY_B]?.symbol].join('/')
        })
      }
    )
  }

  const modalHeader = () => {
    return noLiquidity ? (
      <Grid gap="12px">
        <Box bg="background.primary" p="16px" border="1px solid" borderColor="border.secondary" borderRadius="12px">
          <Flex align="center" justify="space-between">
            <DoubleCurrencyLogo
              currency0={currencies[Field.CURRENCY_A]}
              currency1={currencies[Field.CURRENCY_B]}
              size={30}
            />
            <Text fontSize="24px" fontWeight={600}>
              {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            </Text>
          </Flex>
        </Box>
      </Grid>
    ) : (
      <Grid gap="12px">
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          bg="background.tertiary"
          borderRadius="12px"
          p="17px 16px"
        >
          <Text fontSize="24px" fontWeight={600} color="font.primary">
            {liquidityMinted?.toFixed(8)}
          </Text>
          <DoubleCurrencyLogo
            currency0={currencies[Field.CURRENCY_A]}
            currency1={currencies[Field.CURRENCY_B]}
            size={28}
          />
        </Flex>
        <Flex w="100%">
          <Text fontSize="16px" fontWeight={500} color="font.primary">
            {currencies[Field.CURRENCY_A]?.symbol + '/' + currencies[Field.CURRENCY_B]?.symbol}
            <Box as="span" ml="5px">
              {t('liquidity.Pool_Tokens')}
            </Box>
          </Text>
        </Flex>
        <Text fontSize="12px" fontWeight={400} color="font.secondary">
          {t('liquidity.output_estimated_hint', { PERCENT: allowedSlippage / 100 })}
        </Text>
      </Grid>
    )
  }

  const modalBottom = () => {
    return (
      <ConfirmAddModalBottom
        price={price}
        currencies={currencies}
        parsedAmounts={parsedAmounts}
        noLiquidity={noLiquidity}
        onAdd={onAdd}
        poolTokenPercentage={poolTokenPercentage}
      />
    )
  }

  const pendingText = t('liquidity.pendingText', {
    CURRENCY_A_AMOUNT: parsedAmounts[Field.CURRENCY_A]?.toFixed(8),
    CURRENCY_A_SYMBOL: currencies[Field.CURRENCY_A]?.symbol,
    CURRENCY_B_AMOUNT: parsedAmounts[Field.CURRENCY_B]?.toFixed(8),
    CURRENCY_B_SYMBOL: currencies[Field.CURRENCY_B]?.symbol
  })

  const handleCurrencyASelect = useCallback(
    (currencyA: Currency) => {
      const newCurrencyIdA = currencyId(currencyA)
      if (newCurrencyIdA === currencyIdB) {
        history.push(`/add/${currencyIdB}/${currencyIdA}`)
      } else {
        history.push(`/add/${newCurrencyIdA}/${currencyIdB}`)
      }
    },
    [currencyIdB, history, currencyIdA]
  )
  const handleCurrencyBSelect = useCallback(
    (currencyB: Currency) => {
      const newCurrencyIdB = currencyId(currencyB)
      if (currencyIdA === newCurrencyIdB) {
        if (currencyIdB) {
          history.push(`/add/${currencyIdB}/${newCurrencyIdB}`)
        } else {
          history.push(`/add/${newCurrencyIdB}`)
        }
      } else {
        history.push(`/add/${currencyIdA ? currencyIdA : 'ETH'}/${newCurrencyIdB}`)
      }
    },
    [currencyIdA, history, currencyIdB]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onFieldAInput('')
    }
    setTxHash('')
  }, [onFieldAInput, txHash])

  const isCreate = history.location.pathname.includes('/create')

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={isCreate} adding={true} />
        <Box position="relative">
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash}
            content={() => (
              <ConfirmationModalContent
                title={noLiquidity ? t('liquidity.modal.creating') : t('liquidity.modal.receiving')}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <Grid gap="20px">
            {noLiquidity ||
              (isCreate && (
                <Flex bg="#e7eaf8" flexWrap="wrap" p="1.25rem" borderRadius="12px">
                  <Text fontWeight="600" fontSize="16px" color="font.highlight">
                    {t('liquidity.first_provider_hint')}
                  </Text>
                </Flex>
              ))}
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_A] ?? ''}
              onUserInput={onFieldAInput}
              onMax={() => {
                onFieldAInput(maxAmounts[Field.CURRENCY_A]?.toFixed(8) ?? '0')
              }}
              onCurrencySelect={handleCurrencyASelect}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_A]}
              currency={currencies[Field.CURRENCY_A]}
              otherCurrency={currencies[Field.CURRENCY_B]}
              id="add-liquidity-input-tokena"
              showCommonBases
              showLiquidityTokens
            />
            <Flex justify="center">
              <Plus />
            </Flex>
            <CurrencyInputPanel
              value={formattedAmounts[Field.CURRENCY_B]}
              onUserInput={onFieldBInput}
              onCurrencySelect={handleCurrencyBSelect}
              onMax={() => {
                onFieldBInput(maxAmounts[Field.CURRENCY_B]?.toFixed(8) ?? '0')
              }}
              showMaxButton={!atMaxAmounts[Field.CURRENCY_B]}
              currency={currencies[Field.CURRENCY_B]}
              otherCurrency={currencies[Field.CURRENCY_A]}
              id="add-liquidity-input-tokenb"
              showCommonBases
              showLiquidityTokens
            />
            {currencies[Field.CURRENCY_A] && currencies[Field.CURRENCY_B] && pairState !== PairState.INVALID && (
              <>
                <Flex
                  justify="space-between"
                  align="center"
                  flexWrap="wrap"
                  borderRadius="12px"
                  cursor="pointer"
                  bg="background.tertiary"
                  p="16px"
                  onClick={onToggle}
                >
                  <Flex width="70%" flexWrap="wrap" align="center">
                    <Text color="font.primary" fontWeight={500} fontSize={16}>
                      {noLiquidity ? t('liquidity.initial_share') : t('liquidity.price_share')}
                    </Text>
                  </Flex>
                  <Box pt="0">
                    <Center transition="transform .2s" transform={isOpen ? 'rotate(180deg)' : 'none'}>
                      <Image src={ArrowIcon} alt="↓" boxSize="14px" />
                    </Center>
                  </Box>
                  <Collapse in={isOpen}>
                    <PoolPriceBar
                      currencies={currencies}
                      poolTokenPercentage={poolTokenPercentage}
                      noLiquidity={noLiquidity}
                      price={price}
                    />
                  </Collapse>
                </Flex>
              </>
            )}

            {!account ? (
              <Button br="12px" onClick={toggleWalletModal}>
                {t('liquidity.button.connect')}
              </Button>
            ) : (
              <Button br="12px" onClick={() => setShowConfirm(true)} disabled={!isValid}>
                <Text fontSize="16px" fontWeight="600">
                  {error ?? 'Supply'}
                </Text>
              </Button>
            )}
          </Grid>
        </Box>
      </AppBody>

      {pair && !noLiquidity && pairState !== PairState.INVALID ? (
        <Box minWidth="20rem" maxWidth="400px" mt="1rem" width="100%">
          <FullPositionCard pair={pair} needButton={false} />
        </Box>
      ) : null}
    </>
  )
}
