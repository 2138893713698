import React from 'react'
import { Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
interface ButtonPrimaryProps {
  onClick?: () => void
  children: React.ReactNode
  background?: string
  color?: string
  innerRef?: React.RefObject<HTMLButtonElement>
  disabled?: boolean
  isLoading?: boolean
  variant?: string
  type?: string
  to?: string
  width?: string
  height?: string
  br?: string
  p?: string
}

const BaseButton = ({
  children,
  onClick,
  background = 'interaction.primary',
  color = 'font.inverse',
  innerRef,
  disabled = false,
  isLoading = false,
  variant = 'solid',
  width = '100%',
  height,
  br = '100px',
  p = '16px'
}: ButtonPrimaryProps) => {
  return (
    <>
      <Button
        onClick={onClick}
        as="button"
        minHeight={height}
        width={width}
        borderRadius={br}
        fontWeight={500}
        color={color}
        background={background}
        justifyContent="center"
        ref={innerRef}
        cursor={disabled ? 'not-allowed' : 'pointer'}
        disabled={disabled}
        isLoading={isLoading}
        variant={variant}
        p={p}
        transition=".2s all"
        _hover={{
          transform: disabled ? '' : 'scale(0.98)',
          bg: 'interaction.primary.hovered',
          color: background === 'interaction.primary' ? color : 'font.inverse'
        }}
        _active={{ transform: disabled ? '' : 'scale(0.96)' }}
        _focus={{
          boxShadow: 'none'
        }}
        _disabled={{
          bg: 'interaction.primary.disabled'
        }}
      >
        {children}
      </Button>
    </>
  )
}

const ButtonPrimary = ({
  children,
  onClick,
  background = 'interaction.primary',
  color = 'font.inverse',
  innerRef,
  disabled = false,
  isLoading = false,
  variant = 'solid',
  type = 'button',
  to = '',
  width = '100%',
  height,
  br = '100px',
  p
}: ButtonPrimaryProps) => {
  return (
    <>
      {type === 'button' ? (
        <BaseButton
          onClick={onClick}
          background={background}
          color={color}
          innerRef={innerRef}
          disabled={disabled}
          isLoading={isLoading}
          variant={variant}
          height={height}
          br={br}
          width={width}
          p={p}
        >
          {children}
        </BaseButton>
      ) : (
        <Link to={to} style={{ width }}>
          <BaseButton
            onClick={onClick}
            background={background}
            color={color}
            innerRef={innerRef}
            disabled={disabled}
            isLoading={isLoading}
            variant={variant}
            height={height}
            br={br}
            p={p}
          >
            {children}
          </BaseButton>
        </Link>
      )}
    </>
  )
}
export default ButtonPrimary
