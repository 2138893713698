import React from 'react'
import useCopyClipboard from '../../hooks/useCopyClipboard'
import { Center, Button } from '@chakra-ui/react'
import { ReactComponent as CopyIcon } from '../../assets/icon/edit/copy.svg'

export default function CopyHelper({ text, m }: { text: string; m?: string }) {
  const [isCopied, setCopied] = useCopyClipboard()
  return (
    <Center m={m} onClick={() => setCopied(text)}>
      <Button>{isCopied ? <CopyIcon /> : <CopyIcon />}</Button>
    </Center>
  )
}
