import React, { useContext } from 'react'
import { Text } from 'rebass'
import { ThemeContext } from 'styled-components'
import { TYPE } from '../../../theme'
import { ButtonError } from '../../Button'
import { AutoColumn } from '../../Column'
import QuestionHelper from '../../QuestionHelper'
import { AutoRow, RowBetween, RowFixed } from '../../Row'
import { TeleportCallbackError } from '../../teleport/styleds'

export default function TeleportModalFooter({
  onConfirm,
  teleportErrorMessage,
  hideEstimate
}: {
  onConfirm: () => void
  teleportErrorMessage: string | undefined
  hideEstimate: boolean
}) {
  const theme = useContext(ThemeContext)

  return (
    <>
      {hideEstimate || (
        <AutoColumn gap="0px">
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                Transaction Fee
              </TYPE.black>
              <QuestionHelper content="A fixed amount is charged in each teleport to cover the cost of teleport service and network transaction fee." />
            </RowFixed>
            <TYPE.black fontSize={14}>-</TYPE.black>
          </RowBetween>
          <RowBetween>
            <RowFixed>
              <TYPE.black fontSize={14} fontWeight={400} color={theme.text2}>
                Process Time
              </TYPE.black>
              <QuestionHelper content="Teleporting more than 1000 USDT requires manual auditing and can take up to 24 hours." />
            </RowFixed>
            ~15 minutes
          </RowBetween>
        </AutoColumn>
      )}

      <AutoRow>
        <ButtonError onClick={onConfirm} style={{ margin: '10px 0 0 0' }} id="confirm-teleport-or-send">
          <Text fontSize={20} fontWeight={500}>
            Confirm Transaction
          </Text>
        </ButtonError>

        {teleportErrorMessage ? <TeleportCallbackError error={teleportErrorMessage} /> : null}
      </AutoRow>
    </>
  )
}
