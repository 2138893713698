import React from 'react'
import ErrorPage from '../components/ErrorPage'
import { ReactComponent } from '../assets/images/maintain.svg'
import { useTranslation } from 'react-i18next'

const Maintain = () => {
  const { t } = useTranslation()
  return <ErrorPage Img={ReactComponent} title={t('maintain.title')} description={t('maintain.description')} />
}

export default Maintain
