import React, { useCallback } from 'react'
import { Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react'

interface InputSliderProps {
  value: number
  onChange: (value: number) => void
  step?: number
  min?: number
  max?: number
}

export default function RangeInput({ value, onChange, min = 0, step = 1, max = 100 }: InputSliderProps) {
  const changeCallback = useCallback(
    e => {
      onChange(parseInt(e))
    },
    [onChange]
  )

  return (
    <>
      <Slider onChange={changeCallback} defaultValue={value} value={value} step={step} min={min} max={max}>
        <SliderTrack bg="border.secondary">
          <SliderFilledTrack bg="interaction.primary.pressed" />
        </SliderTrack>
        <SliderThumb
          bg="interaction.primary"
          w="16px"
          h="16px"
          _focus={{
            boxShadow: 'none'
          }}
        />
      </Slider>
    </>
  )
}
