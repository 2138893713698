import styled from 'styled-components'

const STATUS_COLORS = {
  Active: 'rgba(21, 145, 234, 0.6)',
  Upcoming: 'rgba(49, 193, 255, 0.6)',
  Ended: '#7F7F7F'
}

const StatusLabel = styled.div<{ status: 'Active' | 'Upcoming' | 'Ended' }>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 20px;

  justify-self: end;

  text-align: center;
  color: ${({ status }) => STATUS_COLORS[status]};

  background: #ffffff;
  border: 1px solid ${({ status }) => STATUS_COLORS[status]};
  box-sizing: border-box;
  border-radius: 10px;
`

export default StatusLabel
