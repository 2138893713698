import { useMemo, useState, useEffect, useCallback } from 'react'
import { PairDetail, TokenInfo } from '../types'
import { useFclReact } from './useFclReact'
import useSwapRouter, { RouterPlan } from './useSwapRouter'

const scriptBuilder = (route: Array<{ from: string; to: string; pair?: PairDetail }>) => {
  const importSnippet = route.map(({ pair }) => `import ${pair?.name} from ${pair?.address}`).join('\n')
  const pairsFrozenSnippet = route.map(({ pair }) => `${pair?.name}.isFrozen`).join(' || ')

  return `\
  ${importSnippet}

  access(all) fun main(): Bool {
    return ${pairsFrozenSnippet}
  }
  `
}

export function useSwapFrozen(currencyIn?: TokenInfo, currencyOut?: TokenInfo): boolean {
  const { fcl, chainId } = useFclReact()
  const [isFrozen, setIsFrozen] = useState(true)

  const router = useSwapRouter()

  const createScript = useCallback((routerPlan: RouterPlan | undefined) => {
    if (!routerPlan) return null
    const { pathDetails } = routerPlan
    if (!pathDetails || !pathDetails.length) return null
    return scriptBuilder(pathDetails)
  }, [])

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!currencyIn?.symbol || !currencyOut?.symbol) {
      setIsFrozen(false)
      return callback
    }

    const symbolIn = currencyIn?.symbol
    const symbolOut = currencyOut?.symbol
    const routerPlan = router.plan(symbolIn, symbolOut)
    const script = createScript(routerPlan)
    if (!script) {
      setIsFrozen(false)
      return callback
    }

    fcl
      .send([fcl.script(script)])
      .then(fcl.decode)
      .then((result: boolean) => isSubscribed && setIsFrozen(result))
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [fcl, chainId, currencyIn, currencyOut, router, createScript])

  return useMemo(() => isFrozen, [isFrozen])
}
