import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PairState, usePair } from '../../data/Reserves'

import { useFclReact, usePoolAmounts, useTotalSupply } from '../../fcl-react'
import { AppDispatch, AppState } from '../index'
import { Pair, TokenInfo } from '../../types'
import { useTokenBalances } from '../wallet/flowHooks'
import { Field, typeInput } from './actions'

export function useMintState(): AppState['mint'] {
  return useSelector<AppState, AppState['mint']>(state => state.mint)
}

export function useDerivedMintInfo(
  currencyA: TokenInfo | undefined,
  currencyB: TokenInfo | undefined
): {
  dependentField: Field
  currencies: { [field in Field]?: TokenInfo }
  pair?: Pair | null
  pairState: PairState
  currencyBalances: { [field in Field]?: number }
  parsedAmounts: { [field in Field]?: number }
  price?: number
  noLiquidity?: boolean
  liquidityMinted?: number
  poolTokenPercentage?: number
  error?: string
} {
  const { account } = useFclReact()

  const { independentField, typedValue } = useMintState()

  const dependentField = independentField === Field.CURRENCY_A ? Field.CURRENCY_B : Field.CURRENCY_A

  // tokens
  const currencies: { [field in Field]?: TokenInfo } = useMemo(
    () => ({
      [Field.CURRENCY_A]: currencyA ?? undefined,
      [Field.CURRENCY_B]: currencyB ?? undefined
    }),
    [currencyA, currencyB]
  )

  // pair
  const [pairState, pair] = usePair(currencies[Field.CURRENCY_A], currencies[Field.CURRENCY_B])
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const poolAmounts = usePoolAmounts(pair?.liquidityToken)

  const noLiquidity: boolean = pairState === PairState.NOT_EXISTS || totalSupply === 0

  // balances
  const balances = useTokenBalances(account)
  const currencyBalances: { [field in Field]?: number } = {
    [Field.CURRENCY_A]: balances[pair?.token0.address ?? ''],
    [Field.CURRENCY_B]: balances[pair?.token1.address ?? '']
  }

  // amounts
  const independentAmount: number | undefined = parseFloat(typedValue)
  const dependentAmount: number | undefined = useMemo(() => {
    if (independentAmount) {
      // we wrap the currencies just to get the price in terms of the other token
      if (pair && pair?.price) {
        // const dependentCurrency = dependentField === Field.CURRENCY_B ? currencyB : currencyA
        const dependentTokenAmount =
          dependentField === Field.CURRENCY_B ? pair?.price * independentAmount : independentAmount / pair?.price
        return dependentTokenAmount
      }
      return undefined
    } else {
      return undefined
    }
  }, [dependentField, independentAmount, pair])
  const parsedAmounts: { [field in Field]: number | undefined } = {
    [Field.CURRENCY_A]: independentField === Field.CURRENCY_A ? independentAmount : dependentAmount,
    [Field.CURRENCY_B]: independentField === Field.CURRENCY_A ? dependentAmount : independentAmount
  }

  const price = useMemo(() => {
    return pair?.price ?? undefined
  }, [pair])

  // liquidity minted
  const liquidityMinted = useMemo(() => {
    const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts
    if (pair && totalSupply && currencyAAmount && currencyBAmount) {
      const percentA = currencyAAmount / poolAmounts[pair.token0.symbol ?? 'FLOW']
      const percentB = currencyBAmount / poolAmounts[pair.token1.symbol ?? 'tUSDT']
      return Math.min(percentA, percentB) * totalSupply
    } else {
      return undefined
    }
  }, [parsedAmounts, pair, totalSupply, poolAmounts])

  const poolTokenPercentage = useMemo(() => {
    if (liquidityMinted && totalSupply) {
      return (liquidityMinted / (liquidityMinted + totalSupply)) * 100
    } else {
      return undefined
    }
  }, [liquidityMinted, totalSupply])

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (pairState === PairState.INVALID || noLiquidity) {
    error = error ?? 'Invalid pair'
  }

  if (!parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? 'Enter an amount'
  }

  const { [Field.CURRENCY_A]: currencyAAmount, [Field.CURRENCY_B]: currencyBAmount } = parsedAmounts

  if (currencyAAmount && (currencyBalances?.[Field.CURRENCY_A] ?? 0) < currencyAAmount) {
    error = error ?? 'Insufficient ' + currencies[Field.CURRENCY_A]?.symbol + ' balance'
  }

  if (currencyBAmount && (currencyBalances?.[Field.CURRENCY_B] ?? 0) < currencyBAmount) {
    error = error ?? 'Insufficient ' + currencies[Field.CURRENCY_B]?.symbol + ' balance'
  }

  return {
    dependentField,
    currencies,
    pair,
    pairState,
    currencyBalances,
    parsedAmounts,
    price,
    noLiquidity,
    liquidityMinted,
    poolTokenPercentage,
    error
  }
}

export function useMintActionHandlers(
  noLiquidity: boolean | undefined
): {
  onFieldAInput: (typedValue: string) => void
  onFieldBInput: (typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onFieldAInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_A, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )
  const onFieldBInput = useCallback(
    (typedValue: string) => {
      dispatch(typeInput({ field: Field.CURRENCY_B, typedValue, noLiquidity: noLiquidity === true }))
    },
    [dispatch, noLiquidity]
  )

  return {
    onFieldAInput,
    onFieldBInput
  }
}
