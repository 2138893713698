import { useMemo } from 'react'
import Web3 from 'web3'

// we had to had this separate provider for
// returns a web3 instance with blocto provider
export function useBloctoWeb3() {
  return useMemo(() => {
    if (!window.ethereum?.isBlocto) return null
    const web3 = new Web3(window.ethereum)
    return web3
  }, [])
}
