// // Wrong vault type, destroy it
// destroy signer.load<@AnyResource>(from: TeleportedTetherToken.TokenStoragePath)
// signer.unlink(TeleportedTetherToken.TokenPublicReceiverPath)
// signer.unlink(TeleportedTetherToken.TokenPublicBalancePath)

const scriptEnableUSDT = `\
// Add a Vault resource to user's account
// so that they can use the teleportedTetherToken (USDT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {
    if signer.storage.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath) == nil {
      let vault <- TeleportedTetherToken.createEmptyVault(vaultType: Type<@TeleportedTetherToken.Vault>())
      signer.storage.save(<-vault, to: TeleportedTetherToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      let receiverCap = signer.capabilities.storage.issue<&TeleportedTetherToken.Vault>(TeleportedTetherToken.TokenStoragePath)
      signer.capabilities.publish(receiverCap, at: TeleportedTetherToken.TokenPublicReceiverPath)

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      let balanceCap = signer.capabilities.storage.issue<&TeleportedTetherToken.Vault>(TeleportedTetherToken.TokenStoragePath)
      signer.capabilities.publish(balanceCap, at: TeleportedTetherToken.TokenPublicBalancePath)
    }
  }
}
`

const scriptEnableFUSD = `\
// This transaction is a template for a transaction
// to add a Vault resource to their account
// so that they can use the FUSD

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import FUSD from 0xFUSDADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.storage.borrow<&FUSD.Vault>(from: /storage/fusdVault) != nil) {
        return
    }
    
    // Create a new FUSD Vault and put it in storage
    let vault <- FUSD.createEmptyVault()
    signer.storage.save(<-vault, to: /storage/fusdVault)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    let receiverCap = signer.capabilities.storage.issue<&FUSD.Vault>(/storage/fusdVault)
    signer.capabilities.publish(receiverCap, at: /public/fusdReceiver)

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    let balanceCap = signer.capabilities.storage.issue<&FUSD.Vault>(/storage/fusdVault)
    signer.capabilities.publish(balanceCap, at: /public/fusdBalance)
  }
}
`

const scriptEnableBLT = `\
// Add a Vault resource to user's account
// so that they can use the BloctoToken (BLT)

import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import BloctoToken from 0xBLTADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {
    if signer.storage.borrow<&BloctoToken.Vault>(from: BloctoToken.TokenStoragePath) == nil {
      let vault <- BloctoToken.createEmptyVault(vaultType: Type<@BloctoToken.Vault>())
      signer.storage.save(<-vault, to: BloctoToken.TokenStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      let receiverCap = signer.capabilities.storage.issue<&BloctoToken.Vault>(BloctoToken.TokenStoragePath)
      signer.capabilities.publish(receiverCap, at: BloctoToken.TokenPublicReceiverPath)

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      let balanceCap = signer.capabilities.storage.issue<&BloctoToken.Vault>(BloctoToken.TokenStoragePath)
      signer.capabilities.publish(balanceCap, at: BloctoToken.TokenPublicBalancePath)
    }
  }
}
`

const scriptEnableFLOW = `\
transaction {
  prepare(signer: &Account) {
  }
}
`

const scriptEnableSTARLY = `\
// Add a Vault resource to user's account
// so that they can use the StarlyToken (STARLY)
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
transaction {
  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {
    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.storage.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath) != nil) {
      return
    }
    // Create a new Starly Token Vault and put it in storage
    let vault <- StarlyToken.createEmptyVault()
    signer.storage.save(<-vault, to: StarlyToken.TokenStoragePath)
    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    let receiverCap = signer.capabilities.storage.issue<&StarlyToken.Vault>(StarlyToken.TokenStoragePath)
    signer.capabilities.publish(receiverCap, at: StarlyToken.TokenPublicReceiverPath)

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    let balanceCap = signer.capabilities.storage.issue<&StarlyToken.Vault>(StarlyToken.TokenStoragePath)
    signer.capabilities.publish(balanceCap, at: StarlyToken.TokenPublicBalancePath)
  }
}
`

const scriptEnableREVV = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {
    if signer.storage.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath) == nil {
      let vault <- REVV.createEmptyVault(vaultType: Type<@REVV.Vault>())
      signer.storage.save(<-vault, to: REVV.RevvVaultStoragePath)

      // Create a public capability to the Vault that only exposes
      // the deposit function through the Receiver interface
      let receiverCap = signer.capabilities.storage.issue<&REVV.Vault>(REVV.RevvVaultStoragePath)
      signer.capabilities.publish(receiverCap, at: REVV.RevvReceiverPublicPath)

      // Create a public capability to the Vault that only exposes
      // the balance field through the Balance interface
      let balanceCap = signer.capabilities.storage.issue<&REVV.Vault>(REVV.RevvVaultStoragePath)
      signer.capabilities.publish(balanceCap, at: REVV.RevvBalancePublicPath)
    }
  }
}
`

const scriptEnableUSDC = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FiatToken from 0xUSDC_TOKEN_ADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.storage.borrow<&FiatToken.Vault>(from: FiatToken.VaultStoragePath) != nil) {
      return
    }
    
    // Create a new Blocto Token Vault and put it in storage
    let vault <- FiatToken.createEmptyVault()
    signer.storage.save(<-vault, to: FiatToken.VaultStoragePath)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    let receiverCap = signer.capabilities.storage.issue<&FiatToken.Vault>(FiatToken.VaultStoragePath)
    signer.capabilities.publish(receiverCap, at: FiatToken.VaultReceiverPubPath)

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    let balanceCap = signer.capabilities.storage.issue<&FiatToken.Vault>(FiatToken.VaultStoragePath)
    signer.capabilities.publish(balanceCap, at: FiatToken.VaultBalancePubPath)
  }
}
`
const scriptEnableSPRT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

transaction {

  prepare(signer: auth(BorrowValue, SaveValue, IssueStorageCapabilityController, PublishCapability) &Account) {

    // If the account is already set up that's not a problem, but we don't want to replace it
    if(signer.storage.borrow<&TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath) != nil) {
      return
    }
    
    // Create a new Blocto Token Vault and put it in storage
    let vault <- TeleportedSportiumToken.createEmptyVault()
    signer.storage.save(<-vault, to: TeleportedSportiumToken.TokenStoragePath)

    // Create a public capability to the Vault that only exposes
    // the deposit function through the Receiver interface
    let receiverCap = signer.capabilities.storage.issue<&TeleportedSportiumToken.Vault>(TeleportedSportiumToken.TokenStoragePath)
    signer.capabilities.publish(receiverCap, at: TeleportedSportiumToken.TokenPublicReceiverPath)

    // Create a public capability to the Vault that only exposes
    // the balance field through the Balance interface
    let balanceCap = signer.capabilities.storage.issue<&TeleportedSportiumToken.Vault>(TeleportedSportiumToken.TokenStoragePath)
    signer.capabilities.publish(balanceCap, at: TeleportedSportiumToken.TokenPublicBalancePath)
  }
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptEnableUSDT,
  FUSD: scriptEnableFUSD,
  BLT: scriptEnableBLT,
  FLOW: scriptEnableFLOW,
  STARLY: scriptEnableSTARLY,
  REVV: scriptEnableREVV,
  USDC: scriptEnableUSDC,
  SPRT: scriptEnableSPRT
}

export default scripts
