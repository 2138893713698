import React, { useCallback, useEffect, useState } from 'react'
import Box from '../layout/Box'
import styled from 'styled-components'
import { useFclReact } from '../../../fcl-react'
import checkIcon from '../../../assets/svg/check.svg'
import crossIcon from '../../../assets/svg/cross.svg'
import { useIDOAPI } from '../../../fcl-react/useIDOAPI'
import { CustomLightSpinner } from '../../../theme'
import Circle from '../../../assets/images/loader.svg'
import { Steps, updateOpenSection } from '../../../state/IDO/action'
import { useAppDispatch } from '../../../state/IDO/hook'

const Root = styled(Box)`
  order: 1;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 1.5rem;
  margin-bottom: 30px;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
`

const Requirement = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.25em;
  margin: 10px 0;
`

const Icon = styled.img`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  margin-right: 1rem;
`

const Guide = styled.p`
  line-height: 1.25em;
  flex: 1;
`

const VerifyButton = styled.button`
  color: white;
  font-weight: bold;
  padding: 12px 40px;
  font-size: 16px;
  margin-top: 30px;
  background: #3398ff;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  align-self: end;
  transition: all 0.2s;

  &:hover {
    opacity: 0.7;
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    align-self: stretch;
  `}
`

const Verifications = ({
  name,
  minStakingAmount,
  isBanned,
  isInitialized,
  setIsInitialized,
  isStakedSufficient,
  timeline
}: {
  name: string
  minStakingAmount: number
  isBanned: boolean
  isInitialized: boolean
  setIsInitialized: (arg: boolean) => void
  isStakedSufficient: boolean
  timeline: Steps
}) => {
  const [initializing, setInitializing] = useState<boolean>(false)
  const [retryVerify, setRetryVerify] = useState(false)
  const { fcl, account } = useFclReact()
  const { initializeIDOUser } = useIDOAPI()
  const dispatch = useAppDispatch()

  const initializeUser = useCallback(() => {
    if (isInitialized) setIsInitialized(true)
    else {
      setInitializing(true)
      initializeIDOUser()
        .then(() => setIsInitialized(true))
        .catch(() => setIsInitialized(false))
        .finally(() => setInitializing(false))
    }
  }, [isInitialized, setIsInitialized, initializeIDOUser])

  const verify = useCallback(
    () =>
      account
        ? initializeUser()
        : (fcl.authenticate(), fcl.currentUser().subscribe((user: any) => user.addr && setRetryVerify(true))),
    [account, initializeUser, fcl]
  )

  useEffect(() => {
    if (retryVerify) {
      setRetryVerify(false)
      verify()
    }
  }, [retryVerify, verify])

  // tslint-disable-next-line
  const ongoingEventEntry = Object.entries(timeline).find(([key, value]) => value.ongoing)
  const ongoingEvent = ongoingEventEntry ? { event: ongoingEventEntry[0], ...ongoingEventEntry[1] } : null

  return (
    <Root>
      <Title>Join {name} Launchpool</Title>
      <Container>
        {account && isInitialized ? (
          <>
            <Requirement>
              <Icon src={checkIcon} />
              Connect BLOCTO wallet.
            </Requirement>
            <Requirement>
              <Icon src={isBanned ? crossIcon : checkIcon} />
              Residency check.
            </Requirement>
            <Requirement>
              <Icon src={isStakedSufficient ? checkIcon : crossIcon} />
              <div>
                Hold <strong>{minStakingAmount.toFixed(8).replace(/\.?0+$/g, '')}</strong> $BLT in-stake.
              </div>
            </Requirement>
            {ongoingEvent && (
              <VerifyButton
                type="button"
                onClick={() => {
                  dispatch(updateOpenSection({ id: ongoingEvent.event, open: true, projectName: name }))
                  document.getElementById(ongoingEvent.event)?.scrollIntoView({ behavior: 'smooth' })
                }}
              >
                {ongoingEvent.event === 'kyc' && 'Go to KYC'}
                {ongoingEvent.event === 'pool' && 'Select a pool'}
                {ongoingEvent.event === 'deposit' && 'Deposit tUSDT'}
                {ongoingEvent.event === 'complete' && 'Check token distribution'}
              </VerifyButton>
            )}
          </>
        ) : (
          <>
            <Guide>
              Before participating in {name} Launchpool, we need to verify your qualification. Connect your Blocto
              wallet and verify now !
            </Guide>
            <VerifyButton type="button" onClick={verify} disabled={initializing}>
              {initializing ? <CustomLightSpinner src={Circle} alt="loader" size="12px" /> : 'Verify'}
            </VerifyButton>
          </>
        )}
      </Container>
    </Root>
  )
}

export default Verifications
