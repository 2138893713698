import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { updateBalances } from './actions'
import { Network, TokenInfo } from '../../types'

export function useWalletState(): AppState['wallet'] {
  return useSelector<AppState, AppState['wallet']>(state => state.wallet)
}

export function useWalletActionHandlers(): {
  onUpdateBalances: (balances: { [tokenName: string]: number | undefined }) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onUpdateBalances = useCallback(
    (balances: { [tokenName: string]: number | undefined }) => {
      dispatch(updateBalances(balances))
    },
    [dispatch]
  )

  return {
    onUpdateBalances
  }
}

export function useTokenBalances(uncheckedAddress?: string | null): { [tokenAddress: string]: number | undefined } {
  const { balances } = useWalletState()

  return balances
}

export function useTokenBalance(uncheckedAddress?: string | null, token?: TokenInfo | null): number | undefined {
  const balances = useTokenBalances()

  return useMemo(() => {
    return token?.network === Network.FLOW ? balances[token?.address ?? ''] : undefined
  }, [balances, token])
}
