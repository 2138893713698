const script = `\
import BloctoIdo from 0xIDOADDRESS

access(all) fun main(name: String): BloctoIdo.TokenInfo {
	let config = BloctoIdo.getActivityConfig(name)!
  return config.tokenInfo
}
`

export default script
