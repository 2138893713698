import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePair } from '../../data/Reserves'

import { Pair, TokenInfo } from '../../types'
import { useFclReact, usePoolAmounts, useTotalSupply } from '../../fcl-react'
import { useTokenBalances } from '../wallet/flowHooks'
import { AppDispatch, AppState } from '../index'
import { Field, typeInput } from './actions'

export function useBurnState(): AppState['burn'] {
  return useSelector<AppState, AppState['burn']>(state => state.burn)
}

export function useDerivedBurnInfo(
  tokens?: {
    [Field.CURRENCY_A]?: TokenInfo
    [Field.CURRENCY_B]?: TokenInfo
  },
  currencyA?: TokenInfo,
  currencyB?: TokenInfo
): {
  pair?: Pair | null
  parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: number
    [Field.LIQUIDITY]?: number
    [Field.CURRENCY_A]?: number
    [Field.CURRENCY_B]?: number
  }
  error?: string
} {
  const { account } = useFclReact()

  const { independentField, typedValue } = useBurnState()

  // pair + totalsupply
  const [, pair] = usePair(currencyA, currencyB)

  // balances
  const balances = useTokenBalances(account)
  const userLiquidity = pair?.liquidityToken?.symbol ? balances[pair?.liquidityToken.address] : undefined

  // liquidity values
  const totalSupply = useTotalSupply(pair?.liquidityToken)
  const poolAmounts = usePoolAmounts(pair?.liquidityToken)
  const percentage = userLiquidity && totalSupply ? userLiquidity / totalSupply : 0
  const liquidityValueA = poolAmounts[pair?.token0.symbol ?? 'FLOW'] * percentage
  const liquidityValueB = poolAmounts[pair?.token1.symbol ?? 'tUSDT'] * percentage
  const liquidityValues = {
    [Field.CURRENCY_A]: liquidityValueA,
    [Field.CURRENCY_B]: liquidityValueB
  }

  let percentToRemove = 0
  // user specified a %
  if (independentField === Field.LIQUIDITY_PERCENT) {
    percentToRemove = parseFloat(typedValue)
  }
  // user specified a specific amount of liquidity tokens
  else if (independentField === Field.LIQUIDITY) {
    if (pair?.liquidityToken) {
      const independentAmount = parseFloat(typedValue)
      if (independentAmount && userLiquidity && !(independentAmount > userLiquidity)) {
        percentToRemove = (independentAmount / userLiquidity) * 100
      }
    }
  }
  // user specified a specific amount of token a or b
  else {
    if (tokens?.[independentField]) {
      const independentAmount = parseFloat(typedValue)
      const liquidityValue = liquidityValues[independentField]
      if (independentAmount && liquidityValue && !(independentAmount > liquidityValue)) {
        percentToRemove = (independentAmount / liquidityValue) * 100
      }
    }
  }

  const acutalPercent = percentToRemove / 100.0

  const parsedAmounts: {
    [Field.LIQUIDITY_PERCENT]: number
    [Field.LIQUIDITY]?: number
    [Field.CURRENCY_A]?: number
    [Field.CURRENCY_B]?: number
  } = {
    [Field.LIQUIDITY_PERCENT]: percentToRemove,
    [Field.LIQUIDITY]:
      userLiquidity && percentToRemove && percentToRemove > 0 ? acutalPercent * userLiquidity : undefined,
    [Field.CURRENCY_A]:
      pair?.token0 && percentToRemove && percentToRemove > 0 && liquidityValueA
        ? acutalPercent * liquidityValueA
        : undefined,
    [Field.CURRENCY_B]:
      pair?.token1 && percentToRemove && percentToRemove > 0 && liquidityValueB
        ? acutalPercent * liquidityValueB
        : undefined
  }

  let error: string | undefined
  if (!account) {
    error = 'Connect Wallet'
  }

  if (!parsedAmounts[Field.LIQUIDITY] || !parsedAmounts[Field.CURRENCY_A] || !parsedAmounts[Field.CURRENCY_B]) {
    error = error ?? 'Enter an amount'
  }

  return { pair, parsedAmounts, error }
}

export function useBurnActionHandlers(): {
  onUserInput: (field: Field, typedValue: string) => void
} {
  const dispatch = useDispatch<AppDispatch>()

  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      dispatch(typeInput({ field, typedValue }))
    },
    [dispatch]
  )

  return {
    onUserInput
  }
}
