import React, { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useCustomEthereum } from '../../hooks'

import { network } from '../../connectors'
import { NetworkContextName } from '../../constants'
import Loader from '../Loader'
import { LightCard } from '../Card'

const MessageWrapper = styled(LightCard)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 480px;
  margin: 0 20px;
`

const Message = styled.p`
  color: ${({ theme }) => theme.text2};
`

export default function Web3ReactManager({ children }: { children: JSX.Element }) {
  const { t } = useTranslation()

  const { active, chainId, account } = useWeb3React()
  const { active: ethActive, chainId: ethChainId, account: ethAccount } = useCustomEthereum()
  const { active: networkActive, error: networkError, activate: activateNetwork } = useWeb3React(NetworkContextName)

  // after eagerly trying blocto connector, if the network connect ever isn't active or in an error state, activate it
  useEffect(() => {
    const chain = ethChainId || chainId
    if (chain) network.switchProvider(chain)
    if (!networkActive && !networkError && !(ethActive || active)) {
      activateNetwork(network)
    }
  }, [networkActive, networkError, activateNetwork, active, chainId, account, ethAccount, ethChainId, ethActive])

  // handle delayed loader state
  const [showLoader, setShowLoader] = useState(false)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoader(true)
    }, 600)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // if the account context isn't active, and there's an error on the network context, it's an irrecoverable error
  if (!(ethActive || active) && networkError) {
    return (
      <MessageWrapper>
        <Message>{t('unknownError')}</Message>
      </MessageWrapper>
    )
  }

  // if neither context is active, spin
  if (!(ethActive || active) && !networkActive) {
    return showLoader ? (
      <MessageWrapper>
        <Loader />
      </MessageWrapper>
    ) : null
  }

  return children
}
