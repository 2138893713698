import React from 'react'
import { Center } from '@chakra-ui/react'

const ErrorPage = ({ Img, title, description }: any) => {
  return (
    <Center width="100%" height="80vh">
      <Center flexDirection="column" color="font.secondary" maxWidth="600px" textAlign="center">
        <Img />
        <Center mt="16px" fontSize="28px" fontWeight="600">
          {title}
        </Center>
        <Center mt="16px">{description}</Center>
      </Center>
    </Center>
  )
}

export default ErrorPage
