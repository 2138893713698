import { Currency, currencyEquals } from '@uniswap/sdk'
import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { FixedSizeList } from 'react-window'
import { Text } from 'rebass'
import styled from 'styled-components'
import { useTokenBalance } from '../../state/wallet/hooks'
import Column from '../Column'
import { RowFixed } from '../Row'
import CurrencyLogo from '../CurrencyLogo'
import { FadedSpan, MenuItem } from './styleds'
import Loader from '../Loader'
import { TokenInfo } from '../../types'
import { LIQUIDITY_PAIRS } from '../../constants/lists'
import DelistTag from './DelistTag'

function currencyKey(currency: TokenInfo): string {
  return `${currency.address}.${currency.name}`
}

const StyledBalanceText = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  max-width: 5rem;
  text-overflow: ellipsis;
`

function Balance({ balance }: { balance: number }) {
  return <StyledBalanceText title={balance.toString()}>{balance.toFixed(2)}</StyledBalanceText>
}

function CurrencyRow({
  token,
  onSelect,
  isDisabled,
  otherSelected,
  style
}: {
  token: TokenInfo
  onSelect: () => void
  isDisabled: boolean
  otherSelected: boolean
  style: CSSProperties
}) {
  const key = currencyKey(token)
  const balance = useTokenBalance(token)

  // only show add or remove buttons if not on selected list
  return (
    <MenuItem
      style={style}
      className={`token-item-${key}`}
      // eslint-disable-next-line
      onClick={isDisabled ? () => {} : onSelect}
      disabled={isDisabled}
      selected={otherSelected}
    >
      <CurrencyLogo currency={token} size={'24px'} />
      <Column>
        <div>
          <Text title={token.name} fontWeight={500} display="inline-block" verticalAlign="middle">
            {token.symbol}
          </Text>
          {token.symbol === 'FUSD' && (
            <DelistTag tooltippContent="FUSD will soon be unsupported. Please swap for other tokens." />
          )}
        </div>
      </Column>
      <FadedSpan />
      <RowFixed style={{ justifySelf: 'flex-end' }}>
        {balance ? <Balance balance={balance} /> : balance === 0 ? 0 : <Loader />}
      </RowFixed>
    </MenuItem>
  )
}

export default function CurrencyList({
  height,
  tokens,
  selectedCurrency,
  onCurrencySelect,
  otherCurrency,
  fixedListRef,
  showLiquidityTokens
}: {
  height: number
  tokens: TokenInfo[]
  selectedCurrency?: Currency | null
  onCurrencySelect: (currency: Currency) => void
  otherCurrency?: Currency | null
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
  showLiquidityTokens?: boolean
}) {
  const Row = useCallback(
    ({ data, index, style }) => {
      const token: TokenInfo = data[index]
      const validLiquidityPairs = LIQUIDITY_PAIRS
      const tokenSymbol = token?.symbol
      const otherTokenSymbol = otherCurrency?.symbol
      const isInvalidLiquidityPair =
        showLiquidityTokens &&
        tokenSymbol &&
        otherTokenSymbol &&
        !validLiquidityPairs.find(
          ([t0, t1]) =>
            (t0 === tokenSymbol && t1 === otherTokenSymbol) || (t0 === otherTokenSymbol && t1 === tokenSymbol)
        )
      const isDisabled =
        Boolean(selectedCurrency && currencyEquals(selectedCurrency, token)) || !!isInvalidLiquidityPair
      const otherSelected = Boolean(otherCurrency && currencyEquals(otherCurrency, token))
      const handleSelect = () => onCurrencySelect(token)
      return (
        <CurrencyRow
          style={style}
          token={token}
          isDisabled={isDisabled}
          onSelect={handleSelect}
          otherSelected={otherSelected}
        />
      )
    },
    [onCurrencySelect, otherCurrency, selectedCurrency, showLiquidityTokens]
  )

  const itemKey = useCallback((index: number, data: any) => currencyKey(data[index]), [])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={tokens}
      itemCount={tokens.length}
      itemSize={50}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
