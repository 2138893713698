const theme = {
  components: {
    Link: {
      baseStyle: {
        _hover: {
          textDecoration: '',
          opacity: '0.8',
          transform: 'scale(0.98)'
        },
        _active: {
          opacity: '0.8',
          transform: 'scale(0.96)'
        },
        _focus: {
          boxShadow: 'none'
        }
      }
    },
    Button: {
      baseStyle: {
        borderRadius: '20px',
        fontWeight: 'inherit',
        _hover: {
          borderColor: 'transparent',
          boxShadow: 'none',
          transform: 'scale(0.98)'
        },
        _active: {
          borderColor: 'transparent',
          boxShadow: 'none',
          transform: 'scale(0.96)'
        },
        _focus: {
          boxShadow: 'none'
        }
      },
      defaultProps: {
        variant: 'unstyled',
        size: 'unstyled'
      },
      sizes: {
        unstyled: {
          h: 'auto',
          minW: 'auto',
          fontSize: '14px'
        },
        default: {
          width: '100%',
          p: '16px 24px',
          borderRadius: '12px',
          fontWeight: '600'
        },
        rounded: {
          p: '12px 16px',
          borderRadius: 'full',
          fontWeight: '500'
        }
      },
      variants: {
        primary: {
          color: 'font.inverse',
          bgColor: 'interaction.primary',
          _hover: {
            transform: 'scale(0.98)',
            bgColor: 'interaction.primary.hovered'
          },
          _active: { transform: 'scale(0.96)' },
          _focus: {
            boxShadow: 'none'
          },
          _disabled: {
            bgColor: 'interaction.primary.disabled'
          }
        },
        secondary: {
          color: 'interaction.primary',
          bgColor: 'interaction.secondary',
          _hover: {
            transform: 'scale(0.98)',
            bgColor: 'interaction.secondary.hovered'
          },
          _active: { transform: 'scale(0.96)' },
          _focus: {
            boxShadow: 'none'
          },
          _disabled: {
            bgColor: 'interaction.secondary.disabled'
          }
        }
      }
    },
    Progress: {
      baseStyle: { filledTrack: { bgColor: '#0075FF' }, track: { bgColor: '#E1E4E8' } }
    },
    Popover: {
      baseStyle: { content: { _focus: { boxShadow: null } } }
    }
  }
}

export default theme
