import { ChainId } from '../utils'
import { TokenInfo, TokenInfoName, Network } from '../types'
import {
  FLOW_TOKEN,
  TUSDT,
  USDT,
  FUSD,
  BLT,
  FLOW_TUSDT_LP,
  BLT_TUSDT_LP,
  REVV_FLOW_LP,
  STARLY_USDT_LP,
  BLT_BSC,
  BLT_ETHEREUM,
  BLT_APTOS,
  STARLY_FLOW,
  REVV_FLOW,
  REVV_ETHEREUM,
  SPRT_ETHEREUM,
  SPRT_FLOW
} from '../constants'

export type TokenAddressMap = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenAddress: string]: TokenInfo }> }>
export type TokenPairList = Readonly<{ [chainId in ChainId]: Readonly<[TokenInfo, TokenInfo][]> }>
export type TradeRoutes = Readonly<{ [chainId in ChainId]: Readonly<{ [tokenPairAddress: string]: TokenInfo[] }> }>
export type LiquidityTokenMap = Readonly<{ [tokenAddress: string]: TokenInfo }>
export type TeleportTargetMap = Readonly<{ [tokenAddress: string]: TokenInfo }>
export type LiquidityPairs = Readonly<Array<Array<string>>>

/**
 * An empty result, useful as a default.
 */
export const TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.GÖRLI],
    '0x2d270db9ac8c7fef': TUSDT[ChainId.GÖRLI],
    '0xe223d8a629e49c68': FUSD[ChainId.GÖRLI],
    '0x653cfe36d146e7be': BLT[ChainId.GÖRLI],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.GÖRLI]
  },
  [ChainId.SEPOLIA]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.SEPOLIA],
    '0x2d270db9ac8c7fef': TUSDT[ChainId.SEPOLIA],
    '0xe223d8a629e49c68': FUSD[ChainId.SEPOLIA],
    '0x653cfe36d146e7be': BLT[ChainId.SEPOLIA],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.SEPOLIA]
  },
  [ChainId.MAINNET]: {
    '0x1654653399040a61': FLOW_TOKEN[ChainId.MAINNET],
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x3c5959b568896393': FUSD[ChainId.MAINNET],
    '0x0f9df91c9121c460': BLT[ChainId.MAINNET],
    '0xd01e482eb680ec9f': REVV_FLOW[ChainId.MAINNET]
  }
}

export const TELEPORT_TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {
    '0x2d270db9ac8c7fef': TUSDT[ChainId.GÖRLI],
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.GÖRLI],
    '0x653cfe36d146e7be': BLT[ChainId.GÖRLI],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.GÖRLI],
    '0xb097866d87d01550': SPRT_FLOW[ChainId.GÖRLI],
    '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3': BLT_APTOS[ChainId.GÖRLI],
    '0x164914A9270fcE48d6172Fac2C1e0eC9023a1f43': BLT_ETHEREUM[ChainId.GÖRLI],
    '0xfB0727386DB1A630344a08467b45541bEC9bCf17': BLT_BSC[ChainId.GÖRLI],
    '0x9F3547d3Bc3315f1F0Fffb80B57dcD9332fdf827': REVV_ETHEREUM[ChainId.GÖRLI],
    '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a': SPRT_ETHEREUM[ChainId.GÖRLI]
  },
  [ChainId.SEPOLIA]: {
    '0x2d270db9ac8c7fef': TUSDT[ChainId.SEPOLIA],
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.SEPOLIA],
    '0x653cfe36d146e7be': BLT[ChainId.SEPOLIA],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.SEPOLIA],
    '0xb097866d87d01550': SPRT_FLOW[ChainId.SEPOLIA],
    '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3': BLT_APTOS[ChainId.SEPOLIA],
    '0x164914A9270fcE48d6172Fac2C1e0eC9023a1f43': BLT_ETHEREUM[ChainId.SEPOLIA],
    '0xfB0727386DB1A630344a08467b45541bEC9bCf17': BLT_BSC[ChainId.SEPOLIA],
    '0x9F3547d3Bc3315f1F0Fffb80B57dcD9332fdf827': REVV_ETHEREUM[ChainId.SEPOLIA],
    '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a': SPRT_ETHEREUM[ChainId.SEPOLIA]
  },
  [ChainId.MAINNET]: {
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x0f9df91c9121c460': BLT[ChainId.MAINNET],
    '0xd01e482eb680ec9f': REVV_FLOW[ChainId.MAINNET],
    '0x475755d2c9dccc3a': SPRT_FLOW[ChainId.MAINNET],
    '0xfbab9fb68bd2103925317b6a540baa20087b1e7a7a4eb90badee04abb6b5a16f': BLT_APTOS[ChainId.MAINNET],
    '0x943ba7dafd835408743c716127eaa20e1bdf868a': BLT_ETHEREUM[ChainId.MAINNET],
    '0x02Bdf640fba368E7Ba5c6429cCaF251512273865': BLT_BSC[ChainId.MAINNET],
    '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca': REVV_ETHEREUM[ChainId.MAINNET],
    '0x5ab6A4F46Ce182356B6FA2661Ed8ebcAFce995aD': SPRT_ETHEREUM[ChainId.MAINNET]
  }
}

export const LOQUIDITY_TOKEN_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.GÖRLI],
    '0x2d270db9ac8c7fef': TUSDT[ChainId.GÖRLI],
    '0x653cfe36d146e7be': BLT[ChainId.GÖRLI],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.GÖRLI],
    '0xf63219072aaddd50': STARLY_FLOW[ChainId.GÖRLI],
    '0xb097866d87d01550': SPRT_FLOW[ChainId.GÖRLI]
  },
  [ChainId.SEPOLIA]: {
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.SEPOLIA],
    '0x2d270db9ac8c7fef': TUSDT[ChainId.SEPOLIA],
    '0x653cfe36d146e7be': BLT[ChainId.SEPOLIA],
    '0xcdb09c8fef7e3c15': REVV_FLOW[ChainId.SEPOLIA],
    '0xf63219072aaddd50': STARLY_FLOW[ChainId.SEPOLIA],
    '0xb097866d87d01550': SPRT_FLOW[ChainId.SEPOLIA]
  },
  [ChainId.MAINNET]: {
    '0x1654653399040a61': FLOW_TOKEN[ChainId.MAINNET],
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x0f9df91c9121c460': BLT[ChainId.MAINNET],
    '0xd01e482eb680ec9f': REVV_FLOW[ChainId.MAINNET],
    '0x142fa6570b62fd97': STARLY_FLOW[ChainId.MAINNET],
    '0x475755d2c9dccc3a': SPRT_FLOW[ChainId.MAINNET]
  }
}

export const LIQUIDITY_PAIRS: LiquidityPairs = [
  ['FLOW', 'tUSDT'],
  ['BLT', 'tUSDT'],
  ['REVV', 'FLOW'],
  ['STARLY', 'tUSDT'],
  ['SPRT', 'tUSDT']
]

export const LIQUIDITY_TOKENS: LiquidityTokenMap = {
  '0x1654653399040a61:0xcfdd90d4a00f7b5b': FLOW_TUSDT_LP[ChainId.MAINNET],
  '0xcfdd90d4a00f7b5b:0x1654653399040a61': FLOW_TUSDT_LP[ChainId.MAINNET],
  '0x0f9df91c9121c460:0xcfdd90d4a00f7b5b': BLT_TUSDT_LP[ChainId.MAINNET],
  '0xcfdd90d4a00f7b5b:0x0f9df91c9121c460': BLT_TUSDT_LP[ChainId.MAINNET],
  '0xd01e482eb680ec9f:0x1654653399040a61': REVV_FLOW_LP[ChainId.MAINNET],
  '0x1654653399040a61:0xd01e482eb680ec9f': REVV_FLOW_LP[ChainId.MAINNET],
  '0x142fa6570b62fd97:0xcfdd90d4a00f7b5b': STARLY_USDT_LP[ChainId.MAINNET],
  '0xcfdd90d4a00f7b5b:0x142fa6570b62fd97': STARLY_USDT_LP[ChainId.MAINNET],
  '0x7e60df042a9c0868:0x2d270db9ac8c7fef': FLOW_TUSDT_LP[ChainId.SEPOLIA],
  '0x2d270db9ac8c7fef:0x7e60df042a9c0868': FLOW_TUSDT_LP[ChainId.SEPOLIA],
  '0x653cfe36d146e7be:0x2d270db9ac8c7fef': BLT_TUSDT_LP[ChainId.SEPOLIA],
  '0x2d270db9ac8c7fef:0x653cfe36d146e7be': BLT_TUSDT_LP[ChainId.SEPOLIA],
  '0xcdb09c8fef7e3c15:0x7e60df042a9c0868': REVV_FLOW_LP[ChainId.SEPOLIA],
  '0x7e60df042a9c0868:0xcdb09c8fef7e3c15': REVV_FLOW_LP[ChainId.SEPOLIA],
  '0xf63219072aaddd50:0x2d270db9ac8c7fef': STARLY_USDT_LP[ChainId.SEPOLIA],
  '0x2d270db9ac8c7fef:0xf63219072aaddd50': STARLY_USDT_LP[ChainId.SEPOLIA]
}

export const TOKEN_PAIRS: TokenPairList = {
  [ChainId.KOVAN]: [],
  [ChainId.RINKEBY]: [],
  [ChainId.ROPSTEN]: [],
  [ChainId.GÖRLI]: [
    [FLOW_TOKEN[ChainId.GÖRLI], TUSDT[ChainId.GÖRLI]],
    [FUSD[ChainId.GÖRLI], TUSDT[ChainId.GÖRLI]],
    [BLT[ChainId.GÖRLI], TUSDT[ChainId.GÖRLI]],
    [REVV_FLOW[ChainId.GÖRLI], FLOW_TOKEN[ChainId.GÖRLI]],
    [STARLY_FLOW[ChainId.GÖRLI], TUSDT[ChainId.GÖRLI]],
    [SPRT_FLOW[ChainId.GÖRLI], TUSDT[ChainId.GÖRLI]]
  ],
  [ChainId.SEPOLIA]: [
    [FLOW_TOKEN[ChainId.SEPOLIA], TUSDT[ChainId.SEPOLIA]],
    [FUSD[ChainId.SEPOLIA], TUSDT[ChainId.SEPOLIA]],
    [BLT[ChainId.SEPOLIA], TUSDT[ChainId.SEPOLIA]],
    [REVV_FLOW[ChainId.SEPOLIA], FLOW_TOKEN[ChainId.SEPOLIA]],
    [STARLY_FLOW[ChainId.SEPOLIA], TUSDT[ChainId.SEPOLIA]],
    [SPRT_FLOW[ChainId.SEPOLIA], TUSDT[ChainId.SEPOLIA]]
  ],
  [ChainId.MAINNET]: [
    [FLOW_TOKEN[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [FUSD[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [BLT[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [REVV_FLOW[ChainId.MAINNET], FLOW_TOKEN[ChainId.MAINNET]],
    [STARLY_FLOW[ChainId.MAINNET], TUSDT[ChainId.MAINNET]],
    [SPRT_FLOW[ChainId.MAINNET], TUSDT[ChainId.MAINNET]]
  ]
}

export const TELEPORT_LIST: TokenAddressMap = {
  [ChainId.KOVAN]: {},
  [ChainId.RINKEBY]: {},
  [ChainId.ROPSTEN]: {},
  [ChainId.GÖRLI]: {
    '0x2d270db9ac8c7fef': TUSDT[ChainId.GÖRLI],
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.GÖRLI]
  },
  [ChainId.SEPOLIA]: {
    '0x2d270db9ac8c7fef': TUSDT[ChainId.SEPOLIA],
    '0x7e60df042a9c0868': FLOW_TOKEN[ChainId.SEPOLIA]
  },
  [ChainId.MAINNET]: {
    '0xcfdd90d4a00f7b5b': TUSDT[ChainId.MAINNET],
    '0x1654653399040a61': FLOW_TOKEN[ChainId.MAINNET]
  }
}

export const TELEPORT_TARGET: TeleportTargetMap = {
  '0x2d270db9ac8c7fef': USDT[ChainId.SEPOLIA],
  '0xcfdd90d4a00f7b5b': USDT[ChainId.MAINNET],
  '0x96b1e2b383feAd8f91386c19Cf88C4E214108193': FLOW_TOKEN[ChainId.SEPOLIA],
  '0x5c147e74D63B1D31AA3Fd78Eb229B65161983B2b': FLOW_TOKEN[ChainId.MAINNET],
  BLT1noyNr3GttckEVrtcfC6oyK6yV1DpPgSyXbncMwef: BLT[ChainId.MAINNET],
  '0xf5f8fa110109823bd52c4e9e807d3d1dddefddfb315fcd55ddb28e315c1615d3': BLT[ChainId.SEPOLIA],
  '0xfbab9fb68bd2103925317b6a540baa20087b1e7a7a4eb90badee04abb6b5a16f': BLT[ChainId.MAINNET],
  '0xfB0727386DB1A630344a08467b45541bEC9bCf17': BLT[ChainId.SEPOLIA],
  '0x02Bdf640fba368E7Ba5c6429cCaF251512273865': BLT[ChainId.MAINNET],
  '0xd01e482eb680ec9f': REVV_ETHEREUM[ChainId.MAINNET],
  '0xcdb09c8fef7e3c15': REVV_ETHEREUM[ChainId.SEPOLIA],
  '0x557B933a7C2c45672B610F8954A3deB39a51A8Ca': REVV_FLOW[ChainId.MAINNET],
  '0x9F3547d3Bc3315f1F0Fffb80B57dcD9332fdf827': REVV_FLOW[ChainId.SEPOLIA],
  '0xb097866d87d01550': SPRT_ETHEREUM[ChainId.SEPOLIA],
  '0x475755d2c9dccc3a': SPRT_ETHEREUM[ChainId.MAINNET],
  '0x7DF77DFec0CFB96B64Ca6a7d21Ceb01E98c5547a': SPRT_FLOW[ChainId.SEPOLIA],
  '0x5ab6A4F46Ce182356B6FA2661Ed8ebcAFce995aD': SPRT_FLOW[ChainId.MAINNET]
}

export const TELEPORT_TOKENS: any = {
  BLT: {
    [Network.FLOW]: BLT,
    [Network.BSC]: BLT_BSC,
    [Network.APTOS]: BLT_APTOS,
    [Network.ETHEREUM]: BLT_ETHEREUM
  },
  USDT: {
    [Network.ETHEREUM]: USDT,
    [Network.FLOW]: TUSDT
  },
  tUSDT: {
    [Network.ETHEREUM]: USDT,
    [Network.FLOW]: TUSDT
  },
  REVV: {
    [Network.ETHEREUM]: REVV_ETHEREUM,
    [Network.FLOW]: REVV_FLOW
  }
  // SPRT: {
  //  [Network.ETHEREUM]: SPRT_ETHEREUM,
  //  [Network.FLOW]: SPRT_FLOW
  // }
}

export const TELEPORT_SELECTIONS: TokenInfoName[] = [
  {
    name: 'BLT',
    symbol: 'BLT',
    networks: [Network.FLOW, Network.BSC, Network.APTOS, Network.ETHEREUM]
  },
  {
    name: 'REVV',
    symbol: 'REVV',
    networks: [Network.FLOW, Network.ETHEREUM]
  },
  // {
  //   name: 'SPRT',
  //  symbol: 'SPRT',
  //  networks: [Network.FLOW, Network.ETHEREUM]
  // },
  {
    name: 'USDT',
    symbol: 'USDT',
    networks: [Network.FLOW, Network.ETHEREUM]
  }
]
