import React from 'react'
import { useLastTruthy } from '../../hooks/useLast'
import { AdvancedTeleportDetails, AdvancedTeleportDetailsProps } from './AdvancedTeleportDetails'
import { Collapse } from '@chakra-ui/react'

export default function AdvancedTeleportDetailsDropdown({ trade, ...rest }: AdvancedTeleportDetailsProps) {
  const lastTrade = useLastTruthy(trade)

  return (
    <Collapse in={Boolean(trade) && trade?.inputAmount !== Number.MAX_VALUE}>
      <AdvancedTeleportDetails {...rest} trade={trade ?? lastTrade ?? undefined} />
    </Collapse>
  )
}
