import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { X } from 'react-feather'
import { ReactComponent as AlertIcon } from '../../assets/icon/status/alert.svg'

const PhishAlert = styled.div<{ displayOrder: number; colorScheme: 'blue' | 'yellow' }>`
  width: 100%;
  padding: 6px 6px;
  background-color: ${({ colorScheme, theme }) => (colorScheme === 'blue' ? theme.blue1 : '#FFF8E0')};
  color: ${({ colorScheme }) => (colorScheme === 'blue' ? 'white' : '#FFB200')}
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: fixed;
  z-index: 1300;
  top: ${({ displayOrder }) => displayOrder * 28.5 + 'px'};
  ${({ theme, displayOrder }) => theme.mediaWidth.upToSmall`
    top: ${displayOrder * 48}px;
  `}
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    align-items: flex-start;
  `}
`

const ColoredAlertIcon = styled(AlertIcon)<{ colorscheme: 'blue' | 'yellow' }>`
  flex: 0 0 auto;
  margin-right: 4px;
  width: 16px;
  height: 16px;

  path {
    fill: ${({ colorscheme }) => (colorscheme === 'blue' ? 'white' : ' #ffb200')};
  }
`

const Content = styled.span`
  flex: 1;
`

const StyledClose = styled(X)`
  flex: 0 0 auto;
  :hover {
    cursor: pointer;
  }
`

export default function Warning({
  closeWarning,
  content,
  displayOrder,
  colorScheme = 'blue'
}: {
  closeWarning: () => void
  content: ReactNode
  displayOrder: number
  colorScheme?: 'blue' | 'yellow'
}) {
  return (
    <PhishAlert displayOrder={displayOrder} colorScheme={colorScheme}>
      <ColoredAlertIcon colorscheme={colorScheme} /> <Content>{content}</Content>
      <StyledClose size={12} onClick={closeWarning} />
    </PhishAlert>
  )
}
