import { BigNumber } from '@ethersproject/bignumber'
import { MaxUint256 } from '@ethersproject/constants'
import { useMemo } from 'react'

import { useTokenContract } from '../hooks/useContract'
import { useSingleCallResult } from '../state/multicall/hooks'
import { Network, TokenInfo } from '../types'

export function useTokenAllowance(token?: TokenInfo, owner?: string, spender?: string): BigNumber | undefined {
  const contract = useTokenContract(token?.address, false)

  const inputs = useMemo(() => [owner, spender], [owner, spender])
  const allowance = useSingleCallResult(contract, 'allowance', inputs).result

  return token?.network === Network.FLOW
    ? BigNumber.from(MaxUint256)
    : allowance
    ? BigNumber.from(allowance.toString())
    : undefined
}
