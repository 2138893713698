import { useEffect, useState, useContext, useMemo } from 'react'
import { AptosContext } from './AptosContext'
import { useAptos } from './useAptos'
import { BLT_TOKEN_ADDR, BLT_CONTRACT_ADDR } from './env'
import { APTOS_NODE_URL } from './env'
import { division } from '../utils'

export function useTokenBalances(
  address: string | undefined,
  nonce?: number | undefined
): { [tokenAddress: string]: number | undefined } {
  const [balances, setBalances] = useState<{ [tokenAddress: string]: number | undefined }>({})
  const { aptos } = useContext<any>(AptosContext)
  const { account: aptosAccount } = useAptos()

  const getAptosBalance = async (address: string, type: string): Promise<any> => {
    try {
      const result = await fetch(`${APTOS_NODE_URL}/accounts/${address}/resources`).then(response => response.json())
      if (Array.isArray(result)) {
        const found = result.find((entry: any) => entry.type === type)
        return found?.data?.coin?.value
      }
      return NaN
    } catch (error) {
      console.error(error)
      return error
    }
  }

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }
    aptos &&
      getAptosBalance(aptosAccount, BLT_TOKEN_ADDR).then(res => {
        if (isSubscribed) {
          setBalances({
            [BLT_CONTRACT_ADDR]: Number.isNaN(res) ? NaN : division(res, 100000000)
          })
        }
      })

    return callback
  }, [aptosAccount, aptos, address, nonce])

  return useMemo(() => balances, [balances])
}
