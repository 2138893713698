import React, { useCallback, useState, useEffect, useMemo } from 'react'

import ReactGA from 'react-ga'
import { useTranslation } from 'react-i18next'
import { Text } from 'rebass'

import Column from '../../components/Column'
import ConfirmTeleportModal from '../../components/teleport/ConfirmTeleportModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import { SwapPoolTabs } from '../../components/NavigationTabs'
import { AutoRow, RowBetween } from '../../components/Row'
import AdvancedTeleportDetailsDropdown from '../../components/teleport/AdvancedTeleportDetailsDropdown'
import { BottomGrouping, Wrapper } from '../../components/teleport/styleds'
import ProgressSteps from '../../components/ProgressSteps'

import { useTeleportCallback as useTeleportInCallback } from '../../hooks/useTeleportCallback'
import { useApproveAndTeleportCallback } from '../../hooks/useApproveAndTeleportCallback'
import {
  useFclReact,
  useTeleportCallback as useTeleportOutCallback,
  Trade,
  EnableState,
  useEnableCallback
} from '../../fcl-react'
import { Field } from '../../state/teleport/actions'
import { ApprovalState, useApproveCallbackFromTrade } from '../../hooks/useApproveCallback'
import {
  useDefaultsFromURLSearch,
  useDerivedTeleportInfo,
  useTeleportActionHandlers,
  useTeleportState
} from '../../state/teleport/hooks'
import { maxAmountSpend } from '../../utils/maxAmountSpend'
import AppBody from '../AppBody'
import { Network } from '../../types'
import Loader from '../../components/Loader'
import { TELEPORT_TOKENS } from '../../constants/lists'
import { useTeleportCallback as useTeleportAPTCallback, useAptos, useAptosBalance } from '../../aptos-react/useAptos'
import { BLT_CONTRACT_ADDR } from '../../aptos-react/env'
import { useTransactionAdder } from '../../state/transactionsAptos/hooks'

import { useActiveWeb3React } from '../../hooks'
import Settings from '../../components/Settings'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { Box, Center, Image, Grid, Button } from '@chakra-ui/react'
import ChangeIcon from '../../assets/icon/view/exchange.svg'
import MainButton from '../../components/Common/MainButton'
import { isEvmChain } from '../../utils'
// const IS_FROM_BLOCTO = window.hasOwnProperty('ethereum') && window.ethereum?.isBlocto
const IS_FROM_BLOCTO = false

export default function Teleport() {
  useDefaultsFromURLSearch()

  const { account: flowAccount, chainId } = useFclReact()
  const { account: ethAccount, chainId: ethChainId } = useActiveWeb3React()
  const { account: aptosAccount, sendTransaction } = useAptos()

  // teleport state
  const { independentField, typedValue } = useTeleportState()
  const { trade, currencyBalances, parsedAmount, currencies, inputError: teleportInputError } = useDerivedTeleportInfo()

  const noEnoughLiquidity = trade?.inputAmount === Number.MAX_VALUE

  // get custom setting values for user
  const [allowedSlippage] = useUserSlippageTolerance()

  const parsedAmounts = {
    [Field.INPUT]: independentField === Field.INPUT ? parsedAmount : trade?.inputAmount,
    [Field.OUTPUT]: independentField === Field.OUTPUT ? parsedAmount : trade?.outputAmount
  }

  const { onSwitchTokens, onCurrencySelection, onUserInput } = useTeleportActionHandlers()
  const isValid = !teleportInputError
  const dependentField: Field = independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT

  const handleTypeInput = useCallback(
    (value: string) => {
      onUserInput(Field.INPUT, value)
    },
    [onUserInput]
  )
  const handleTypeOutput = useCallback(
    (value: string) => {
      onUserInput(Field.OUTPUT, value)
    },
    [onUserInput]
  )

  // modal and loading
  const [{ showConfirm, tradeToConfirm, teleportErrorMessage, attemptingTxn, txHash }, setTeleportState] = useState<{
    showConfirm: boolean
    tradeToConfirm: Trade | undefined
    attemptingTxn: boolean
    teleportErrorMessage: string | undefined
    txHash: string | undefined
  }>({
    showConfirm: false,
    tradeToConfirm: undefined,
    attemptingTxn: false,
    teleportErrorMessage: undefined,
    txHash: undefined
  })

  const dependentCurrency = currencies[dependentField]
  const dependentAmount =
    parsedAmounts[dependentField] === Number.MAX_VALUE
      ? ''
      : (parsedAmounts[dependentField] && parsedAmounts[dependentField]?.toFixed(dependentCurrency?.decimals)) || ''

  const formattedAmounts = {
    [independentField]: typedValue,
    [dependentField]: dependentAmount
  }

  // check whether the user has approved the router on the input token
  const [enableState, enableCallback] = useEnableCallback(trade?.outputCurrency)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [enableSubmitted, setEnableSubmitted] = useState<boolean>(false)

  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (enableState === EnableState.PENDING) {
      setEnableSubmitted(true)
    }
    if (enableState === EnableState.ENABLED) {
      setEnableSubmitted(false)
    }
  }, [enableState, enableSubmitted])

  // check whether the user has approved the router on the input token
  const [approval, approveCallback] = useApproveCallbackFromTrade(trade)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const [approvalSubmitted, setApprovalSubmitted] = useState<boolean>(false)
  // mark when a user has submitted an approval, reset onTokenSelection for input field
  useEffect(() => {
    if (approval === ApprovalState.PENDING) {
      setApprovalSubmitted(true)
    }
  }, [approval, approvalSubmitted])

  const maxAmountInput: number | undefined = maxAmountSpend(
    currencyBalances[Field.INPUT],
    currencies[Field.INPUT]?.symbol
  )
  const atMaxAmountInput = Boolean(maxAmountInput && parsedAmounts[Field.INPUT] === maxAmountInput)

  // the callback to execute the teleport
  const { callback: teleportInCallback, error: teleportInCallbackError } = useTeleportInCallback(trade)
  const { callback: teleportOutCallback, error: teleportOutCallbackError } = useTeleportOutCallback(trade)
  const {
    callback: approveAndTeleportCallback,
    error: approveAndTeleportCallbackError
  } = useApproveAndTeleportCallback(trade)
  const { callback: teleportAPTCallback, error: teleportAPTCallbackError } = useTeleportAPTCallback(trade)

  let teleportCallback: (() => Promise<string>) | null = null
  let teleportCallbackError: string | null = null

  const isTeleportingToFlow = trade?.outputCurrency.network === Network.FLOW
  const isBatchable = isTeleportingToFlow && IS_FROM_BLOCTO && approval !== ApprovalState.APPROVED
  const addTransaction = useTransactionAdder()

  // FIXME: symbol can be another token on APTOS
  const isTeleportingToAptos =
    (trade?.outputCurrency.network === Network.APTOS && trade?.outputCurrency.symbol === 'BLT') ||
    (trade?.inputCurrency.network === Network.APTOS && trade?.inputCurrency.symbol === 'BLT')
  let token
  if (trade?.outputCurrency.network === Network.APTOS && trade?.outputCurrency.symbol === 'BLT') {
    token = trade?.outputCurrency
  }
  if (trade?.inputCurrency.network === Network.APTOS && trade?.inputCurrency.symbol === 'BLT') {
    token = trade?.inputCurrency
  }

  const aptosTokenBalance = useAptosBalance(null, token)
  const [showApproveAptosBLT, setShowApproveAptosBLT] = useState(false)

  useEffect(() => {
    if (isTeleportingToAptos && aptosAccount && Number.isNaN(aptosTokenBalance)) {
      setShowApproveAptosBLT(true)
    } else {
      setShowApproveAptosBLT(false)
    }
  }, [isTeleportingToAptos, aptosAccount, aptosTokenBalance])

  switch (trade?.inputCurrency.network) {
    case Network.FLOW:
      teleportCallbackError = teleportOutCallbackError
      teleportCallback = teleportOutCallback
      break
    case Network.ETHEREUM:
    case Network.BSC:
      teleportCallbackError = teleportInCallbackError
      teleportCallback = teleportInCallback
      break
    case Network.APTOS:
      teleportCallbackError = teleportAPTCallbackError
      teleportCallback = teleportAPTCallback
      break
    default:
      break
  }

  if (isBatchable) {
    teleportCallbackError = approveAndTeleportCallbackError
    teleportCallback = approveAndTeleportCallback
  }

  const handleTeleport = useCallback(() => {
    if (!teleportCallback) {
      return
    }

    setTeleportState({
      attemptingTxn: true,
      tradeToConfirm,
      showConfirm,
      teleportErrorMessage: undefined,
      txHash: undefined
    })

    teleportCallback()
      .then(hash => {
        setTeleportState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          teleportErrorMessage: undefined,
          txHash: hash
        })

        ReactGA.event({
          category: 'Teleport',
          action: 'Teleport w/o Send',
          label: [trade?.inputCurrency?.symbol, trade?.outputCurrency?.symbol].join('/')
        })
      })
      .catch(error => {
        setTeleportState({
          attemptingTxn: false,
          tradeToConfirm,
          showConfirm,
          teleportErrorMessage: error?.message,
          txHash: undefined
        })
      })
  }, [tradeToConfirm, showConfirm, teleportCallback, trade])

  // show approve flow when: no error on inputs, not approved or pending, or approved in current session
  // if the user uses blocto-injected web3, combine approve & swap steps
  // never show if price impact is above threshold in non expert mode
  const showApproveFlow =
    !isBatchable &&
    !teleportInputError &&
    (approval === ApprovalState.NOT_APPROVED ||
      approval === ApprovalState.PENDING ||
      (approvalSubmitted && approval === ApprovalState.APPROVED))

  const showEnableFlow =
    !teleportInputError &&
    (enableState === EnableState.NOT_ENABLED ||
      enableState === EnableState.PENDING ||
      enableState === EnableState.UNKNOWN ||
      (enableSubmitted && enableState === EnableState.ENABLED))

  const canSkipApprove = enableSubmitted && enableState === EnableState.ENABLED && isBatchable

  let steps: boolean[] = []

  if (showEnableFlow) {
    steps = [...steps, enableState === EnableState.ENABLED]
  }

  if (showApproveFlow) {
    steps = [...steps, approval === ApprovalState.APPROVED]
  }

  const handleConfirmDismiss = useCallback(() => {
    setTeleportState({ showConfirm: false, tradeToConfirm, attemptingTxn, teleportErrorMessage, txHash })
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.INPUT, '')
    }
  }, [attemptingTxn, onUserInput, teleportErrorMessage, tradeToConfirm, txHash])

  const handleAcceptChanges = useCallback(() => {
    setTeleportState({ tradeToConfirm: trade, teleportErrorMessage, txHash, attemptingTxn, showConfirm })
  }, [attemptingTxn, showConfirm, teleportErrorMessage, trade, txHash])

  const handleInputSelect = useCallback(
    inputCurrency => {
      onCurrencySelection(Field.INPUT, inputCurrency)
      /* help select outputCurrency */
      switch (inputCurrency.symbol) {
        case 'BLT':
          if (inputCurrency.network === 'FLOW') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['BLT']['ETHEREUM'][chainId])
          }
          if (
            inputCurrency.network === 'BSC' ||
            inputCurrency.network === 'APTOS' ||
            inputCurrency.network === 'ETHEREUM'
          ) {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['BLT']['FLOW'][chainId])
          }
          break
        case 'USDT':
          onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['tUSDT']['FLOW'][chainId])
          break
        case 'tUSDT':
          onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['USDT']['ETHEREUM'][chainId])
          break
        case 'REVV':
          if (inputCurrency.network === 'FLOW') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['REVV']['ETHEREUM'][chainId])
          }
          if (inputCurrency.network === 'ETHEREUM') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['REVV']['FLOW'][chainId])
          }
          break
        case 'SPRT':
          if (inputCurrency.network === 'FLOW') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['SPRT']['ETHEREUM'][chainId])
          }
          if (inputCurrency.network === 'ETHEREUM') {
            onCurrencySelection(Field.OUTPUT, TELEPORT_TOKENS['SPRT']['FLOW'][chainId])
          }
          break
      }
    },
    [onCurrencySelection, chainId]
  )

  const handleMaxInput = useCallback(() => {
    maxAmountInput !== undefined && onUserInput(Field.INPUT, maxAmountInput.toString())
  }, [maxAmountInput, onUserInput])

  const handleOutputSelect = useCallback(outputCurrency => onCurrencySelection(Field.OUTPUT, outputCurrency), [
    onCurrencySelection
  ])

  const { t } = useTranslation()

  const connectorIsETH = useMemo(() => ethChainId && isEvmChain(ethChainId), [ethChainId])
  const connectorIsBSC = useMemo(() => (ethChainId as number) === 56 || (ethChainId as number) === 97, [ethChainId])

  const connectWallets = useMemo(() => {
    const networkConnected = (INPUT: Field.INPUT | Field.OUTPUT) => {
      if (currencies[INPUT]?.network === Network.FLOW) {
        return flowAccount
      }
      if (currencies[INPUT]?.network === Network.ETHEREUM && connectorIsETH) {
        return ethAccount
      }
      if (currencies[INPUT]?.network === Network.BSC && connectorIsBSC) {
        return ethAccount
      } else if (currencies[INPUT]?.network === Network.APTOS) {
        return aptosAccount
      } else {
        return undefined
      }
    }
    return networkConnected(Field.INPUT) && networkConnected(Field.OUTPUT)
  }, [flowAccount, ethAccount, aptosAccount, currencies, connectorIsBSC, connectorIsETH])

  const connectWalletsText = useCallback(() => {
    const networks = [currencies[Field.INPUT]?.network, currencies[Field.OUTPUT]?.network]
    if (!networks) {
      return null
    }
    if (networks.includes(Network.FLOW) && !flowAccount) {
      return t('teleport.button.connectFlow')
    }
    if (networks.includes(Network.ETHEREUM) && (!ethAccount || connectorIsBSC)) {
      return t('teleport.button.connectEthereum')
    }
    if (networks.includes(Network.BSC) && (!ethAccount || connectorIsETH)) {
      return t('teleport.button.connectBsc')
    }
    if (networks.includes(Network.APTOS) && !aptosAccount) {
      return t('teleport.button.connectAptos')
    }
    return t('teleport.button.connectWallets')
  }, [flowAccount, ethAccount, aptosAccount, currencies, t, connectorIsBSC, connectorIsETH])

  const hintText = useMemo(() => {
    const networks = [currencies[Field.INPUT]?.network, currencies[Field.OUTPUT]?.network]
    if (!networks || !ethAccount) {
      return null
    }
    if (networks.includes(Network.ETHEREUM) && (!ethAccount || connectorIsBSC)) {
      return t('teleport.hint.change_to_eth')
    }
    if (networks.includes(Network.BSC) && (!ethAccount || connectorIsETH)) {
      return t('teleport.hint.change_to_bsc')
    }
  }, [currencies, connectorIsBSC, connectorIsETH, t, ethAccount])

  const approveAptosBLT = async () => {
    if (aptosAccount && Number.isNaN(aptosTokenBalance)) {
      try {
        // register blt token
        const transaction = {
          arguments: [],
          function: '0x1::managed_coin::register',
          type: 'entry_function_payload',
          // eslint-disable-next-line @typescript-eslint/camelcase
          type_arguments: [`${BLT_CONTRACT_ADDR}::blt::Blt`]
        }
        const hash = await sendTransaction(transaction)
        setShowApproveAptosBLT(false)
        const summary = `Create Aptos BLT Resources`

        addTransaction(
          { transactionId: hash },
          {
            summary
          }
        )
      } catch (error) {
        console.error('error: ', error)
      }
    }
  }

  const renderNextStep = () => {
    switch (true) {
      case !connectWallets:
        return <MainButton disabled={true}>{connectWalletsText()}</MainButton>
      case noEnoughLiquidity:
        return <MainButton disabled={true}>{t('teleport.button.insufficientLiquidity')}</MainButton>
      case showApproveFlow || showEnableFlow:
        return (
          <RowBetween>
            {enableState !== EnableState.ENABLED && (
              <MainButton
                onClick={enableCallback}
                disabled={enableState !== EnableState.NOT_ENABLED || enableSubmitted}
              >
                {enableState === EnableState.PENDING ? (
                  <AutoRow gap="6px" justify="center">
                    {t('teleport.button.enabling')} <Loader stroke="white" />
                  </AutoRow>
                ) : (
                  t('teleport.button.enable_currency', { CURRENCY: currencies[Field.OUTPUT]?.symbol })
                )}
              </MainButton>
            )}
            {enableState === EnableState.ENABLED && approval !== ApprovalState.APPROVED && !canSkipApprove && (
              <MainButton
                onClick={approveCallback}
                disabled={
                  approval !== ApprovalState.NOT_APPROVED || approvalSubmitted || enableState !== EnableState.ENABLED
                }
              >
                {approval === ApprovalState.PENDING ? (
                  <AutoRow gap="6px" justify="center">
                    {t('teleport.button.approving')} <Loader stroke="white" />
                  </AutoRow>
                ) : (
                  t('teleport.button.approve_currency', { CURRENCY: currencies[Field.INPUT]?.symbol })
                )}
              </MainButton>
            )}
            {enableState === EnableState.ENABLED && (approval === ApprovalState.APPROVED || canSkipApprove) && (
              <MainButton
                onClick={() => {
                  setTeleportState({
                    tradeToConfirm: trade,
                    attemptingTxn: false,
                    teleportErrorMessage: undefined,
                    showConfirm: true,
                    txHash: undefined
                  })
                }}
                disabled={!isValid}
              >
                <Text fontSize={16} fontWeight={500}>
                  {teleportInputError ? teleportInputError : t('teleport.button.teleport')}
                </Text>
              </MainButton>
            )}
          </RowBetween>
        )
      case showApproveAptosBLT:
        return (
          <RowBetween>
            <MainButton onClick={approveAptosBLT}>
              {t('teleport.button.enable_currency', { CURRENCY: 'Aptos BLT' })}
            </MainButton>
          </RowBetween>
        )
      default:
        return (
          <MainButton
            onClick={() => {
              setTeleportState({
                tradeToConfirm: trade,
                attemptingTxn: false,
                teleportErrorMessage: undefined,
                showConfirm: true,
                txHash: undefined
              })
            }}
            disabled={!isValid || !!teleportCallbackError}
          >
            {teleportInputError ? teleportInputError : t('teleport.button.teleport')}
          </MainButton>
        )
    }
  }

  return (
    <>
      <AppBody>
        <SwapPoolTabs active={'teleport'} />

        <Center justifyContent="space-between" mb="21px">
          <Box fontWeight="500">{t('teleport.title')}</Box>
          <Center>
            <Box fontSize="14px" color="font.secondary" mr="8px">
              {t('teleport.slippage', { PERCENT: allowedSlippage / 100 })}
            </Box>
            <Settings />
          </Center>
        </Center>

        <Wrapper id="teleport-page">
          <ConfirmTeleportModal
            isOpen={showConfirm}
            trade={trade}
            originalTrade={tradeToConfirm}
            onAcceptChanges={handleAcceptChanges}
            attemptingTxn={attemptingTxn}
            txHash={txHash}
            onConfirm={handleTeleport}
            teleportErrorMessage={teleportErrorMessage}
            onDismiss={handleConfirmDismiss}
          />

          <Grid>
            <CurrencyInputPanel
              label="From"
              value={formattedAmounts[Field.INPUT]}
              showMaxButton={!atMaxAmountInput}
              currency={currencies[Field.INPUT]}
              onUserInput={handleTypeInput}
              onMax={handleMaxInput}
              onCurrencySelect={handleInputSelect}
              otherCurrency={currencies[Field.OUTPUT]}
              showTeleportTokens
              id="teleport-currency-input"
              isTeleport={true}
              isMainSelector={true}
            />

            <Center>
              <Button>
                <Center
                  m="12px"
                  boxSize="40px"
                  borderRadius="full"
                  bgColor="interaction.secondary"
                  onClick={() => {
                    onSwitchTokens()
                  }}
                >
                  <Image src={ChangeIcon} boxSize="20px" />
                </Center>
              </Button>
            </Center>

            <CurrencyInputPanel
              value={formattedAmounts[Field.OUTPUT]}
              onUserInput={handleTypeOutput}
              label="To"
              showMaxButton={false}
              currency={currencies[Field.OUTPUT]}
              onCurrencySelect={handleOutputSelect}
              otherCurrency={currencies[Field.INPUT]}
              showTeleportTokens
              id="teleport-currency-output"
              isTeleport={true}
            />
          </Grid>

          <Box mt="20px">
            <AdvancedTeleportDetailsDropdown trade={trade} />
          </Box>

          <BottomGrouping>
            {renderNextStep()}
            {(showApproveFlow || showEnableFlow) && (
              <Column style={{ marginTop: '1rem' }}>
                <ProgressSteps steps={steps} />
              </Column>
            )}
          </BottomGrouping>
        </Wrapper>
      </AppBody>
      {!!hintText && (
        <Box
          whiteSpace="break-spaces"
          fontSize="12px"
          maxWidth="420px"
          width="100%"
          mt="28px"
          p="10px 16px"
          bgColor="background.tertiary"
          borderRadius="12px"
        >
          {hintText}
        </Box>
      )}
    </>
  )
}
