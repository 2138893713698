import React, { FunctionComponent, useState } from 'react'
import { useURLWarningVisible } from '../../state/user/hooks'
import { useDelistWarningVisible } from '../../state/application/hooks'
import DelistWarning from './DelistWarning'
import URLWarning from './URLWarning'

const WARNINGS: Record<
  string,
  {
    component: FunctionComponent<{ displayOrder: number; hasUserClosed: boolean; setClose: () => void }>
    hook: () => boolean
  }
> = {
  delist: { component: DelistWarning, hook: useDelistWarningVisible },
  url: { component: URLWarning, hook: useURLWarningVisible }
}

const USER_CLOSED_WARNINGS = Object.keys(WARNINGS).reduce<Record<string, boolean>>((initial, current) => {
  initial[current] = false
  return initial
}, {})

export default function HeaderWarning() {
  const keys = Object.keys(WARNINGS).map(key => (WARNINGS[key].hook() ? key : undefined))
  const [hasUserClosed, setHasUserClosed] = useState(USER_CLOSED_WARNINGS)
  const displayKeys = keys.filter((key): key is string => !!key && !hasUserClosed[key])

  const handleClose = (key: string) => () => {
    setHasUserClosed(prev => ({ ...prev, [key]: true }))
  }

  return (
    <>
      {displayKeys.map((key, index) => {
        const Component = WARNINGS[key].component
        return (
          <Component key={index} displayOrder={index} hasUserClosed={hasUserClosed[key]} setClose={handleClose(key)} />
        )
      })}
    </>
  )
}
