import { TradeType } from '@uniswap/sdk'
import React, { useCallback, useMemo, useState } from 'react'
import { Trade } from '../../fcl-react'
import { Field } from '../../state/swap/actions'
import {
  formatExecutionPrice,
  computeSlippageAdjustedAmounts,
  computeTradePriceBreakdown,
  warningSeverity
} from '../../utils/prices'
import CurrencyLogo from '../CurrencyLogo'
import { Flex, Box, Center, Image } from '@chakra-ui/react'
import ArrowDownIcon from '../../assets/icon/view/arrow_down.svg'
import ChangeIcon from '../../assets/icon/view/change.svg'
import { ReactComponent as AlertIcon } from '../../assets/icon/status/alert_outline.svg'
import { useTranslation } from 'react-i18next'
import { useUSDCTokenBalance } from '../../state/wallet/ethereumHooks'

export default function SwapModalHeader({ trade, allowedSlippage }: { trade: Trade; allowedSlippage: number }) {
  const slippageAdjustedAmounts = useMemo(() => computeSlippageAdjustedAmounts(trade, allowedSlippage), [
    trade,
    allowedSlippage
  ])
  const { priceImpactWithoutFee } = useMemo(() => computeTradePriceBreakdown(trade), [trade])
  const priceImpactSeverity = warningSeverity(priceImpactWithoutFee)
  const [showInverted, setShowInverted] = useState<boolean>(false)
  const { t } = useTranslation()
  const usdcBalance = useUSDCTokenBalance(trade.outputCurrency)

  const showUsdcLpMessage = useCallback(() => {
    if (!usdcBalance) return false
    if (trade.inputCurrency.symbol === 'USDC' && usdcBalance < trade.inputAmount) {
      return true
    }
    if (trade.outputCurrency.symbol === 'USDC' && usdcBalance < trade.outputAmount) {
      return true
    }
    return false
  }, [usdcBalance, trade])

  return (
    <Box>
      <Flex flexDirection="column" alignItems="center">
        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          bgColor="background.tertiary"
          p="16px"
          borderRadius="12px"
        >
          <Box fontWeight={600} fontSize="24px">
            {trade.inputAmount.toString()}
          </Box>
          <Center fontWeight={500}>
            <CurrencyLogo currency={trade.inputCurrency} size={'28px'} />
            <Box ml="8px">{trade.inputCurrency.symbol}</Box>
          </Center>
        </Flex>

        <Center
          mt="-12px"
          mb="-24px"
          boxSize="36px"
          borderRadius="full"
          bgColor="white"
          boxShadow="0px 0px 20px rgba(0, 0, 0, 0.05)"
          zIndex="100"
        >
          <Image src={ArrowDownIcon} boxSize="20px" />
        </Center>

        <Flex
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          bgColor="background.tertiary"
          p="16px"
          borderRadius="12px"
          mt="12px"
        >
          <Box fontWeight={600} fontSize="24px" color={priceImpactSeverity > 2 ? 'font.alert' : ''}>
            {trade.outputAmount.toString()}
          </Box>
          <Center fontWeight={500}>
            <CurrencyLogo currency={trade.outputCurrency} size={'28px'} />
            <Box ml="8px">{trade.outputCurrency.symbol}</Box>
          </Center>
        </Flex>
      </Flex>

      <Flex justifyContent="space-between" mt="16px" fontSize={14}>
        <Center fontWeight={500}>Price</Center>
        <Center>
          {formatExecutionPrice(trade, showInverted)}
          <Center
            ml="8px"
            onClick={() => setShowInverted(!showInverted)}
            boxSize="28px"
            borderRadius="full"
            bgColor="interaction.secondary"
            cursor="pointer"
          >
            <Image src={ChangeIcon} boxSize="14px" />
          </Center>
        </Center>
      </Flex>

      <Box fontSize="12px" color="font.secondary" mt="12px">
        {trade.tradeType === TradeType.EXACT_INPUT ? (
          <Box textAlign="left" style={{ width: '100%' }}>
            {`Output is estimated. You will receive at least `}
            <Box as="span" color="font.primary">
              {slippageAdjustedAmounts[Field.OUTPUT]?.toFixed(6)} {trade.outputCurrency.symbol}
            </Box>
            {' or the transaction will revert.'}
          </Box>
        ) : (
          <Box textAlign="left" style={{ width: '100%' }}>
            {`Input is estimated. You will sell at most `}
            <Box as="span" color="font.primary">
              {slippageAdjustedAmounts[Field.INPUT]?.toFixed(6)} {trade.inputCurrency.symbol}
            </Box>
            {' or the transaction will revert.'}
          </Box>
        )}
      </Box>
      <Box>
        {showUsdcLpMessage() && (
          <Flex alignItems="center" mt="12px">
            <AlertIcon fontSize="12px" style={{ fill: '#0075FF', width: '16px' }} />
            <Box fontSize="14px" lineHeight="20px" fontWeight={400} color="font.highlight" ml="8px" w="90%">
              {t('usdcLpMessage')}
            </Box>
          </Flex>
        )}
      </Box>
    </Box>
  )
}
