import React, { ReactElement, useCallback, useState } from 'react'
import styled from 'styled-components'
import Tooltip from '../Tooltip'
import { Flex, Image } from '@chakra-ui/react'
import HelpGrayIcon from '../../assets/icon/status/help-gray.svg'

const LightQuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: default;
  border-radius: 36px;
  width: 24px;
  height: 24px;
  color: ${({ theme }) => theme.white};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionMark = styled.span`
  font-size: 1rem;
`

export default function QuestionHelper({ content, icon }: { content: ReactElement | string; icon?: any }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <Flex align="center" mx="8px">
      <Tooltip content={content} show={show}>
        <Flex
          align="center"
          justify="center"
          p="0.2rem"
          border="none"
          bg="none"
          color="icon.tertiary"
          outline="none"
          cursor="default"
          borderRadius="36px"
          _hover={{ opacity: 0.7 }}
          _focus={{ opacity: 0.7 }}
          onClick={open}
          onMouseEnter={open}
          onMouseLeave={close}
        >
          {icon || <Image src={HelpGrayIcon} boxSize="16px" />}
        </Flex>
      </Tooltip>
    </Flex>
  )
}

// TODO - ask about this component use for ?
export function LightQuestionHelper({ content }: { content: ReactElement | string }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <span style={{ marginLeft: 4 }}>
      <Tooltip content={content} show={show}>
        <LightQuestionWrapper onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <QuestionMark>?</QuestionMark>
        </LightQuestionWrapper>
      </Tooltip>
    </span>
  )
}
