import { Currency } from '@uniswap/sdk'
import React from 'react'
import { RowFixed } from '../../components/Row'
import CurrencyLogo from '../../components/CurrencyLogo'
import { Field } from '../../state/mint/actions'
import { Box, Flex, Text } from '@chakra-ui/react'
import Button from '../../components/ButtonPrimary'
import { useTranslation } from 'react-i18next'

export function ConfirmAddModalBottom({
  noLiquidity,
  price,
  currencies,
  parsedAmounts,
  poolTokenPercentage,
  onAdd
}: {
  noLiquidity?: boolean
  price?: number
  currencies: { [field in Field]?: Currency }
  parsedAmounts: { [field in Field]?: number }
  poolTokenPercentage?: number
  onAdd: () => void
}) {
  const { t } = useTranslation()
  return (
    <>
      <Flex flexWrap="wrap" bg="background.tertiary" p="16px" borderRadius={12} mb="20px">
        <Text fontWeight="500" fontSize="16px" color="font.primary">
          {t('liquidity.price_share')}
        </Text>
        <Box bg="border.secondary" width="100%" height="1px" mt="12px" />
        <Flex justify="space-between" w="100%" pt="16px" pb="20px">
          <Text fontWeight="500" fontSize="14px" color="font.primary">
            {currencies[Field.CURRENCY_A]?.symbol} {t('liquidity.Deposited')}
          </Text>
          <Flex>
            <CurrencyLogo currency={currencies[Field.CURRENCY_A]} style={{ marginRight: '8px' }} />
            <Text fontWeight="400" fontSize="14px" color="font.primary">
              {parsedAmounts[Field.CURRENCY_A]?.toFixed(8)}
            </Text>
          </Flex>
        </Flex>
        <Flex justify="space-between" w="100%" pb="16px">
          <Text fontWeight="500" fontSize="14px" color="font.primary">
            {currencies[Field.CURRENCY_B]?.symbol} {t('liquidity.Deposited')}
          </Text>
          <RowFixed>
            <CurrencyLogo currency={currencies[Field.CURRENCY_B]} style={{ marginRight: '8px' }} />
            <Text fontWeight="400" fontSize="14px" color="font.primary">
              {parsedAmounts[Field.CURRENCY_B]?.toFixed(8)}
            </Text>
          </RowFixed>
        </Flex>
        <Flex justify="space-between" w="100%">
          <Text fontWeight="500" fontSize="14px" color="font.primary">
            {t('liquidity.Rates')}
          </Text>
          <Text fontWeight="400" fontSize="14px" color="font.primary">
            {`1 ${currencies[Field.CURRENCY_A]?.symbol} = ${price?.toFixed(8)} ${currencies[Field.CURRENCY_B]?.symbol}`}
          </Text>
        </Flex>
        <Flex justify="flex-end" w="100%" pb="12px">
          <Text fontWeight="400" fontSize="14px" color="font.primary">
            {`1 ${currencies[Field.CURRENCY_B]?.symbol} = ${(1 / (price ?? 1)).toFixed(8)} ${
              currencies[Field.CURRENCY_A]?.symbol
            }`}
          </Text>
        </Flex>
        <Flex justify="space-between" w="100%">
          <Text fontWeight="500" fontSize="14px" color="font.primary">
            {t('liquidity.share_of_pool')}
          </Text>
          <Text fontWeight="400" fontSize="14px" color="font.primary">
            {noLiquidity ? '100' : poolTokenPercentage?.toFixed(4)}%
          </Text>
        </Flex>
      </Flex>
      <Button onClick={onAdd} height="54px" br="12px">
        <Text fontWeight={600} fontSize="16px">
          {noLiquidity ? t('liquidity.button.create_and_supply') : t('liquidity.button.confirm_supply')}
        </Text>
      </Button>
    </>
  )
}
