import React from 'react'
import { Step, StepsContainer } from './layout/StepsVert'
import CollapseWrapper from './layout/CollapseWrapper'
import { StepID, StepState } from '../../types'
import { StepContent } from './layout/StepContent'

export default function IDOJourney({ metadata, isInitialized }: { metadata: any; isInitialized: boolean }) {
  return (
    <>
      <StepsContainer>
        <Step id={StepID.kyc} projectName={metadata.name}>
          <CollapseWrapper
            id={StepID.kyc}
            projectName={metadata.name}
            hint={metadata.timeline.kyc.state === StepState.passed ? 'Congrat! You already passed the KYC.' : undefined}
            error={
              metadata.timeline.kyc.state === StepState.failed
                ? `You did not complete your KYC, please contact the ${metadata.name} team for all KYC related issues.`
                : undefined
            }
          >
            {StepContent[StepID.kyc](metadata, isInitialized)}
          </CollapseWrapper>
        </Step>
        <Step id={StepID.pool} projectName={metadata.name}>
          <CollapseWrapper
            id={StepID.pool}
            projectName={metadata.name}
            hint={
              metadata.userInfo?.poolType === 'LIMITED' ? (
                <div style={{ color: '#3398FF', fontWeight: '600' }}>Standard pool - Staking cap</div>
              ) : metadata.userInfo?.poolType === 'UNLIMITED' ? (
                <div style={{ color: '#3398FF', fontWeight: '600' }}>Unlimited pool - Unlimited cap</div>
              ) : (
                undefined
              )
            }
            error={
              metadata.timeline.pool.state === StepState.failed
                ? 'You did not select a pool to participate in.'
                : undefined
            }
          >
            {StepContent[StepID.pool](metadata)}
          </CollapseWrapper>
        </Step>
        <Step id={StepID.deposit} projectName={metadata.name}>
          <CollapseWrapper
            id={StepID.deposit}
            projectName={metadata.name}
            error={
              (metadata.timeline.pool.state === StepState.failed
                ? `You did not select a pool to participate in.`
                : metadata.timeline.deposit.ongoing && Number(metadata.userInfo?.quota)) === 0
                ? 'You are disqualified from participating in the launchpool due to insufficient $BLT in-stake.'
                : undefined
            }
          >
            {StepContent[StepID.deposit](metadata)}
          </CollapseWrapper>
        </Step>
        <Step id={StepID.complete} projectName={metadata.name}>
          <CollapseWrapper id={StepID.complete} projectName={metadata.name}>
            {StepContent[StepID.complete](metadata)}
          </CollapseWrapper>
        </Step>
      </StepsContainer>
    </>
  )
}
