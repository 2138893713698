import React from 'react'
import styled from 'styled-components'
import stepCheck from '../../../assets/images/stepCheck.svg'
import stepFail from '../../../assets/images/stepFail.svg'
import stepCheckLight from '../../../assets/images/stepCheckLight.svg'
import stepFailLight from '../../../assets/images/stepFailLight.svg'
import { useProjectState } from '../../../state/IDO/hook'
import { StepState, StepID } from '../../../types'

interface StateProps {
  state?: StepState
  ongoing?: boolean
}
const Nav = styled.nav`
  position: relative;
`
const Ol = styled.ol`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
  list-style-type: none;
  z-index: 1;
  padding: 0;
`
const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
`
const Li = styled.li<StateProps>`
  display: inline-flex;
  position: relative;
  height: 100%;
  align-items: flex-start;
  justify-content: flex-start;
  &:not(:last-child) > ${Button.selector}:after {
    content: '';
    display: block;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
    height: 100%;
    width: 6px;
    background: ${props =>
      props.state === StepState.unactive
        ? '#f9f9f9'
        : props.ongoing
        ? 'linear-gradient(90deg, #99C0FA 0%, #99C8FB 50%, #9AD5FC 100%)'
        : '#99C0FA'};
  }
`

const StepCircle = styled.div<StateProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  flex-grow: 0;
  width: 34px;
  height: 34px;
  border-radius: 9999px;
  z-index: 1;
  position: relative;
  border-width: ${props => (props.state === StepState.ongoing || props.state === StepState.unactive ? '7px' : '0px')};
  border-style: solid;
  border-color: ${props => (props.state === StepState.ongoing ? '#9AD5FC' : props.ongoing ? '#BCBCBC' : '#f9f9f9')};
  background: #fff;
  background-image: url(${props =>
    props.state === StepState.passed
      ? props.ongoing
        ? stepCheckLight
        : stepCheck
      : props.state === StepState.failed
      ? props.ongoing
        ? stepFailLight
        : stepFail
      : null});
`

export const Step = ({ children, id, projectName }: { children: any; id: StepID; projectName: string }) => {
  const stepOrder = Object.keys(useProjectState(projectName).state) as StepID[]
  const thisStep = useProjectState(projectName).state[id]
  const nextStep = useProjectState(projectName).state[stepOrder[stepOrder.indexOf(id) + 1]]

  return (
    <Li state={nextStep ? nextStep.state : StepState.unactive} ongoing={nextStep?.ongoing}>
      <Button>
        <StepCircle state={thisStep.state} ongoing={thisStep.ongoing} />
      </Button>
      <div style={{ width: '100%' }}>{children}</div>
    </Li>
  )
}

export const StepsContainer = ({ children }: any) => {
  return (
    <div>
      <Nav>
        <Ol>{children}</Ol>
      </Nav>
    </div>
  )
}
