import React from 'react'
import { useDelistWarningToggle } from '../../state/application/hooks'
import Warning from './Warning'

export default function DelistWarning({ displayOrder, setClose }: { displayOrder: number; setClose: () => void }) {
  const toggleDelistWarning = useDelistWarningToggle()

  return (
    <Warning
      closeWarning={() => {
        toggleDelistWarning(false)
        setClose()
      }}
      content="FUSD will soon be unsupported. Please swap for other tokens."
      displayOrder={displayOrder}
      colorScheme="yellow"
    />
  )
}
