import { useMemo, useState, useEffect } from 'react'
import { useFclReact } from './useFclReact'
import { TokenInfo } from '../types'
import getPoolAmounts from './scripts/getPoolAmounts'
import { replaceContractAddresses } from './env'

export function usePoolAmounts(token?: TokenInfo): { [tokenName: string]: number } {
  const { fcl, chainId } = useFclReact()
  const [poolAmounts, setPoolAmounts] = useState<{ [tokenName: string]: number }>({})

  useEffect(() => {
    let isSubscribed = true
    const callback = () => {
      isSubscribed = false
    }

    if (!token?.address) {
      return callback
    }

    const symbol = token?.symbol ?? ''
    const [symbol1, symbol2] = symbol.split(':')

    fcl
      .send([fcl.script(replaceContractAddresses(getPoolAmounts[symbol] ?? '', chainId))])
      .then(fcl.decode)
      .then((results: [string, string]) => {
        isSubscribed &&
          setPoolAmounts({
            [symbol1]: parseFloat(results[0]),
            [symbol2]: parseFloat(results[1])
          })
      })
      .catch((error: Error) => {
        console.log(error)
      })

    return callback
  }, [fcl, token, chainId])

  return useMemo(() => poolAmounts, [poolAmounts])
}
