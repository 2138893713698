const scriptFlowUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import FlowSwapPair from 0xFLOWSWAPPAIRADDRESS

transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: auth(FungibleToken.Withdraw) &FlowSwapPair.Vault

  // The Vault references that holds the tokens that are being transferred
  let flowTokenVaultRef: &FlowToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  prepare(signer: auth(BorrowValue) &Account) {
    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &FlowSwapPair.Vault>(from: FlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.storage.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @FlowSwapPair.Vault

    // Take back liquidity
    let tokenBundle <- FlowSwapPair.removeLiquidity(from: <-liquidityTokenVault)

    // Deposit liquidity tokens
    self.flowTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.tetherVaultRef.deposit(from: <- tokenBundle.withdrawToken2())

    destroy tokenBundle
  }
}
`

const scriptBltUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import BltUsdtSwapPair from 0xBLTUSDTSWAPPAIRADDRESS

transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: auth(FungibleToken.Withdraw) &BltUsdtSwapPair.Vault

  // The Vault references that holds the tokens that are being transferred
  let bloctoTokenVaultRef: &BloctoToken.Vault
  let tetherVaultRef: &TeleportedTetherToken.Vault

  prepare(signer: auth(BorrowValue) &Account) {
    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &BltUsdtSwapPair.Vault>(from: BltUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.bloctoTokenVaultRef = signer.storage.borrow<&BloctoToken.Vault>(from: /storage/bloctoTokenVault)
      ?? panic("Could not borrow a reference to Vault")

    self.tetherVaultRef = signer.storage.borrow<&TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @BltUsdtSwapPair.Vault

    // Take back liquidity
    let tokenBundle <- BltUsdtSwapPair.removeLiquidity(from: <-liquidityTokenVault)

    // Deposit liquidity tokens
    self.bloctoTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.tetherVaultRef.deposit(from: <- tokenBundle.withdrawToken2())

    destroy tokenBundle
  }
}
`

const scriptRevvFlow = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import FlowToken from 0xFLOWTOKENADDRESS
import RevvFlowSwapPair from 0xREVV_FLOW_SWAP_PAIR_ADDRESS

transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: auth(FungibleToken.Withdraw) &RevvFlowSwapPair.Vault

  // The Vault references that holds the tokens that are being transferred
  let revvVaultRef: &REVV.Vault
  let flowTokenVaultRef: &FlowToken.Vault

  prepare(signer: auth(BorrowValue) &Account) {
    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &RevvFlowSwapPair.Vault>(from: RevvFlowSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.revvVaultRef = signer.storage.borrow<&REVV.Vault>(from: REVV.RevvVaultStoragePath)
      ?? panic("Could not borrow a reference to Vault")

    self.flowTokenVaultRef = signer.storage.borrow<&FlowToken.Vault>(from: /storage/flowTokenVault)
      ?? panic("Could not borrow a reference to Vault")
  }

  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @RevvFlowSwapPair.Vault

    // Take back liquidity
    let tokenBundle <- RevvFlowSwapPair.removeLiquidity(from: <-liquidityTokenVault)

    // Deposit liquidity tokens
    self.revvVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.flowTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken2())

    destroy tokenBundle
  }
}
`

const scriptStarlyUsdt = `
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import StarlyToken from 0xSTARLYTOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS
import StarlyUsdtSwapPair from 0xSTARLY_USDT_SWAP_PAIR_ADDRESS
transaction(amount: UFix64) {
  // The Vault reference for liquidity tokens
  let liquidityTokenRef: auth(FungibleToken.Withdraw) &StarlyUsdtSwapPair.Vault
  // The Vault references that holds the tokens that are being transferred
  let starlyVaultRef: &StarlyToken.Vault
  let usdtTokenVaultRef: &TeleportedTetherToken.Vault
  prepare(signer: auth(BorrowValue) &Account) {
    self.liquidityTokenRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &StarlyUsdtSwapPair.Vault>(from: StarlyUsdtSwapPair.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
    self.starlyVaultRef = signer.storage.borrow<&StarlyToken.Vault>(from: StarlyToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
    self.usdtTokenVaultRef = signer.storage.borrow<&TeleportedTetherToken.Vault>(from: TeleportedTetherToken.TokenStoragePath)
      ?? panic("Could not borrow a reference to Vault")
  }
  execute {
    // Withdraw liquidity provider tokens
    let liquidityTokenVault <- self.liquidityTokenRef.withdraw(amount: amount) as! @StarlyUsdtSwapPair.Vault
    // Take back liquidity
    let tokenBundle <- StarlyUsdtSwapPair.removeLiquidity(from: <-liquidityTokenVault)
    // Deposit liquidity tokens
    self.starlyVaultRef.deposit(from: <- tokenBundle.withdrawToken1())
    self.usdtTokenVaultRef.deposit(from: <- tokenBundle.withdrawToken2())
    destroy tokenBundle
  }
}
`

const scripts: { [token1Name: string]: { [token2Name: string]: string } } = {
  FLOW: {
    tUSDT: scriptFlowUsdt,
    REVV: scriptRevvFlow
  },
  tUSDT: {
    FLOW: scriptFlowUsdt,
    BLT: scriptBltUsdt,
    STARLY: scriptStarlyUsdt
  },
  BLT: {
    tUSDT: scriptBltUsdt
  },
  REVV: {
    FLOW: scriptRevvFlow
  },
  STARLY: {
    tUSDT: scriptStarlyUsdt
  }
}

export default scripts
