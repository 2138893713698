import { useMemo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from '../state/index'
import { Types } from 'aptos'
import BloctoSDK from '@blocto/sdk'
import { AptosContext } from './AptosContext'
import { TokenInfo } from '../types'
import { useFclReact } from '../fcl-react/useFclReact'
import { currentEnv } from '../constants'
import { Trade } from '../fcl-react'
import { TELEPORT_TO_FLOW } from './env'
import { useTransactionAdder } from '../state/transactionsAptos/hooks'
import { removeSessionAddressByChain } from '../utils'

const bloctoSDK = new BloctoSDK({
  aptos: {
    chainId: currentEnv === 'mainnet' ? 1 : 2
  },
  appId: process.env.REACT_APP_DAPP_ID
})

export function useAptos() {
  const { aptos, setAptos } = useContext<any>(AptosContext)
  const { chainId } = useFclReact()

  const connect = useMemo(() => {
    return async () => {
      if (bloctoSDK.aptos) {
        const result = await bloctoSDK.aptos.connect()
        setAptos({ ...bloctoSDK.aptos, ...result })
      }
    }
  }, [setAptos])

  const disconnect = useMemo(() => {
    return () => {
      if (aptos) {
        bloctoSDK?.aptos?.disconnect()
        removeSessionAddressByChain('aptos')
        setAptos(null)
      }
    }
  }, [aptos, setAptos])

  async function sendTransaction(transaction: Types.TransactionPayload, options = {}) {
    try {
      const result = await bloctoSDK.aptos?.signAndSubmitTransaction(transaction, options)
      return result?.hash
    } catch (error) {
      console.error(`Teleport failed`, error)
    }
  }

  return useMemo(
    () => ({
      chainId,
      connect,
      disconnect,
      account: aptos?.address,
      aptos,
      sendTransaction
    }),
    [connect, disconnect, aptos, chainId]
  )
}

export function useAptosBalance(uncheckedAddress: string | null, token?: TokenInfo | null): number | undefined {
  const wallet = useSelector<AppState, AppState['wallet']>(state => state.wallet)
  const result = token ? wallet.balances?.[token.address] : undefined
  return result
}

export enum TeleportCallbackState {
  INVALID,
  LOADING,
  VALID
}

export function useTeleportCallback(
  trade?: Trade // trade to execute, required
): {
  state: TeleportCallbackState
  callback: any
  error: string | null
} {
  const { account: flowAccount } = useFclReact()
  const formattedInput = trade ? parseInt(trade.inputAmount.toFixed(8).replace('.', '')) : 0
  const addTransaction = useTransactionAdder()
  const { sendTransaction } = useAptos()
  function hexToBytes(hexString: string) {
    const bytes = []
    for (let c = 0; c < hexString.length; c += 2) bytes.push(parseInt(hexString.substr(c, 2), 16))
    return bytes
  }

  const outputCallback = () => {
    return !flowAccount || !trade
      ? null
      : async function onTeleport(): Promise<string> {
          const transaction = {
            function: TELEPORT_TO_FLOW,
            // eslint-disable-next-line @typescript-eslint/camelcase
            type_arguments: [],
            arguments: [formattedInput, hexToBytes(flowAccount?.replaceAll('0x', ''))],
            type: 'entry_function_payload'
          }
          const result = await sendTransaction(transaction)
          const inputAmount = trade.inputAmount.toFixed(4)
          const outputSymbol = trade.outputCurrency.symbol
          const summary = `Teleport ${inputAmount} ${outputSymbol} from Aptos to Flow`

          addTransaction(
            { transactionId: result },
            {
              summary
            }
          )
          return result
        }
  }
  return {
    state: TeleportCallbackState.VALID,
    callback: outputCallback(),
    error: null
  }
}
