import { useMemo } from 'react'
import { Trade } from './types'
import { useFclReact } from './useFclReact'
import { useTransactionAdder } from '../state/transactionsFlow/hooks'
import teleportToEthereum from './transactions/teleportToEthereum'
import teleportToBSC from './transactions/teleportToBSC'
import teleportToAptos from './transactions/teleportToAptos'
import { Network, TransactionResponse } from '../types'
import { useActiveWeb3React } from '../hooks'
import { replaceContractAddresses } from './env'
import { useAptos } from '../aptos-react/useAptos'

export enum TeleportCallbackState {
  INVALID,
  LOADING,
  VALID
}

// returns a function that will execute a teleport, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useTeleportCallback(
  trade: Trade | undefined // trade to execute, required
): { state: TeleportCallbackState; callback: null | (() => Promise<string>); error: string | null } {
  const { account: flowAccount, fcl, authorization, types, chainId } = useFclReact()
  const { account: ethAccount, chainId: ethChainId } = useActiveWeb3React()

  const trimmedEthAccount = ethAccount?.replace('0x', '')
  const { account: aptosAccount } = useAptos()

  const addTransaction = useTransactionAdder()

  return useMemo(() => {
    if (!trade || !flowAccount || !trade?.inputCurrency?.symbol || !trade?.outputCurrency?.symbol) {
      return { state: TeleportCallbackState.INVALID, callback: null, error: 'Missing dependencies' }
    }

    const isWalletBSC = (ethChainId as number) === 56 || (ethChainId as number) === 97
    const outputNetwork = trade.outputCurrency.network

    // add this check to avoid network not matched and teleport from eth address
    const outputNetworkMatched =
      (isWalletBSC && outputNetwork === Network.BSC) || (!isWalletBSC && outputNetwork !== Network.BSC)

    if (!outputNetworkMatched) {
      return { state: TeleportCallbackState.INVALID, callback: null, error: 'BSC Network Error' }
    }

    const formattedInput = trade.inputAmount.toFixed(8)

    const outputCallback = (): Promise<string> => {
      const isSealed = false
      return fcl
        .send([fcl.getBlock(isSealed)])
        .then(fcl.decode)
        .then((block: any) => {
          switch (outputNetwork) {
            case 'BSC': {
              const teleportScript = teleportToBSC[trade.inputCurrency.symbol]
              return fcl.send([
                fcl.transaction(replaceContractAddresses(teleportScript, chainId)),
                fcl.args([fcl.arg(formattedInput, types.UFix64), fcl.arg(trimmedEthAccount, types.String)]),
                fcl.proposer(fcl.currentUser().authorization),
                fcl.authorizations([fcl.currentUser().authorization]),
                fcl.payer(fcl.currentUser().authorization),
                fcl.ref(block.id),
                fcl.limit(1000)
              ])
            }
            case 'ETHEREUM': {
              const teleportScript = teleportToEthereum[trade.inputCurrency.symbol]
              return fcl.send([
                fcl.transaction(replaceContractAddresses(teleportScript, chainId)),
                fcl.args([fcl.arg(formattedInput, types.UFix64), fcl.arg(trimmedEthAccount, types.String)]),
                fcl.limit(1000),
                fcl.proposer(authorization),
                fcl.authorizations([authorization]),
                fcl.payer(authorization),
                fcl.ref(block.id)
              ])
            }
            case 'APTOS': {
              const teleportScript = teleportToAptos[trade.inputCurrency.symbol]
              return fcl.send([
                fcl.transaction(replaceContractAddresses(teleportScript, chainId)),
                fcl.args([
                  fcl.arg(formattedInput, types.UFix64),
                  fcl.arg(aptosAccount.replace('0x', ''), types.String)
                ]),
                fcl.proposer(fcl.currentUser().authorization),
                fcl.authorizations([fcl.currentUser().authorization]),
                fcl.payer(fcl.currentUser().authorization),
                fcl.ref(block.id),
                fcl.limit(1000)
              ])
            }
            default:
          }
        })
        .then((response: TransactionResponse) => {
          const outputSymbol = trade.outputCurrency.symbol
          const inputAmount = trade.inputAmount.toFixed(4)
          const CHAIN_NAME = {
            FLOW: 'Flow',
            BSC: 'Binance Smart Chain',
            ETHEREUM: 'Ethereum',
            APTOS: 'Aptos'
          }

          const inputChain = CHAIN_NAME[trade.inputCurrency.network]
          const outputChain = CHAIN_NAME[trade.outputCurrency.network]

          const summary = `Teleport ${inputAmount} ${outputSymbol} from ${inputChain} to ${outputChain}`

          addTransaction(response, {
            summary
          })

          return response.transactionId
        })
        .catch((error: Error) => {
          // if the user rejected the tx, pass this along
          if (error?.message.indexOf("Cannot read property 'sig' of null") !== -1) {
            throw new Error('Transaction rejected.')
          } else {
            // otherwise, the error was unexpected and we need to convey that
            console.error(`Teleport failed`, error, teleportToEthereum)
            throw new Error(`Teleport failed: ${error.message}`)
          }
        })
    }

    return {
      state: TeleportCallbackState.VALID,
      callback: outputCallback,
      error: null
    }
  }, [
    trade,
    flowAccount,
    trimmedEthAccount,
    addTransaction,
    authorization,
    fcl,
    types,
    chainId,
    ethChainId,
    aptosAccount
  ])
}
