import React from 'react'
import { useFclReact, useTotalSupply, usePoolAmounts } from '../../fcl-react'
import { useTokenBalance } from '../../state/wallet/flowHooks'
import { currencyId } from '../../utils/currencyId'
import { Pair } from '../../types'
import CurrencyLogo from '../CurrencyLogo'
import DoubleCurrencyLogo from '../DoubleLogo'
import { Box, Flex, Text, Collapse, Center, useDisclosure, Image, Button } from '@chakra-ui/react'
import ArrowIcon from '../../assets/icon/navigation/arror_collapse.svg'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface PositionCardProps {
  pair: Pair
  border?: string
  needButton?: boolean
  defaultIsOpen?: boolean
}

export default function FullPositionCard({ pair, needButton = true, defaultIsOpen }: PositionCardProps) {
  const { account } = useFclReact()
  const { t } = useTranslation()

  const currency0 = pair.token0
  const currency1 = pair.token1

  // const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen })

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && totalPoolTokens > userPoolBalance
      ? userPoolBalance / totalPoolTokens
      : undefined

  const poolAmounts = usePoolAmounts(pair.liquidityToken)

  const [token0Deposited, token1Deposited] =
    !!pair && !!totalPoolTokens && !!userPoolBalance && !!poolTokenPercentage
      ? [
          poolAmounts[pair.token0.symbol ?? ''] * poolTokenPercentage,
          poolAmounts[pair.token1.symbol ?? ''] * poolTokenPercentage
        ]
      : [undefined, undefined]

  return (
    <>
      <Box
        bg="background.primary"
        borderRadius="lg"
        boxShadow="0px 0px 20px 0px #0000000D"
        rounded="12"
        p="24px"
        mt="1rem"
      >
        <Flex alignItems="center" justify="space-between" flexWrap="wrap" cursor="pointer" onClick={onToggle}>
          <Flex width="60%" flexWrap="wrap" align="center">
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={28} />
            <Text color="font.primary" fontWeight={500} ml="10px" fontSize={16}>
              {!currency0 || !currency1 ? 'Loading' : `${currency0.symbol} / ${currency1.symbol}`}
            </Text>
          </Flex>
          <Box pt="0">
            <Center transition="transform .2s" transform={isOpen ? 'rotate(180deg)' : 'none'}>
              <Image src={ArrowIcon} alt="↓" boxSize="14px" />
            </Center>
          </Box>
        </Flex>
        <Collapse in={isOpen}>
          <Flex width="100%" flexWrap="wrap">
            <Box bg="border.secondary" width={'100%'} height="1px" mt="23px" mb="23px" />
            <Flex justify="space-between" width="100%">
              <Text color="font.primary" fontSize={14} fontWeight={500}>
                {t('liquidity.card.your_token')}
              </Text>
              <Text color="font.primary" fontSize={14} fontWeight={400}>
                {userPoolBalance ? userPoolBalance.toFixed(8) : '-'}
              </Text>
            </Flex>
            <Flex justify="space-between" alignItems="center" width="100%" mt="24px">
              <Text color="font.primary" fontSize={14} fontWeight={500}>
                {t('liquidity.card.pooled', { CURRENCY: currency0.symbol })}
              </Text>
              {token0Deposited ? (
                <Flex flexWrap="wrap" alignItems="center">
                  <CurrencyLogo size="28px" currency={currency0} />
                  <Text fontSize={14} fontWeight={400} ml="8px">
                    {token0Deposited?.toFixed(8)}
                  </Text>
                </Flex>
              ) : (
                '-'
              )}
            </Flex>
            <Flex justify="space-between" alignItems="center" width="100%" mt="24px">
              <Text color="font.primary" fontSize={14} fontWeight={500}>
                {t('liquidity.card.pooled', { CURRENCY: currency1.symbol })}
              </Text>
              {token1Deposited ? (
                <Flex flexWrap="wrap" alignItems="center">
                  <CurrencyLogo size="28px" currency={currency1} />
                  <Text fontSize={14} fontWeight={400} ml="8px">
                    {token1Deposited?.toFixed(8)}
                  </Text>
                </Flex>
              ) : (
                '-'
              )}
            </Flex>
            <Flex justify="space-between" width="100%" mt="24px">
              <Text color="font.primary" fontSize={14} fontWeight={500}>
                {t('liquidity.card.your_share')}
              </Text>
              <Text color="font.primary" fontSize={14} fontWeight={400}>
                {poolTokenPercentage ? (poolTokenPercentage * 100).toFixed(4) + '%' : '-'}
              </Text>
            </Flex>
            {needButton && (
              <Flex justify="space-between" width="100%" mt="24px" fontSize="16px" fontWeight="500">
                <Button
                  variant="primary"
                  size="default"
                  width="48%"
                  as={Link}
                  to={`/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                  fontWeight={500}
                >
                  {t('liquidity.button.add')}
                </Button>
                <Button
                  width="48%"
                  variant="secondary"
                  size="default"
                  as={Link}
                  to={`/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                >
                  {t('liquidity.button.remove')}
                </Button>
              </Flex>
            )}
          </Flex>
        </Collapse>
      </Box>
    </>
  )
}
