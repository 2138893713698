import { CurrencyAmount, JSBI } from '@uniswap/sdk'
import { TokenInfo } from '../../types'
import { useMemo } from 'react'
import ERC20_INTERFACE from '../../constants/abis/erc20'
import { useAllTokens } from '../../hooks/Tokens'
import { useActiveWeb3React } from '../../hooks'
import { useMulticallContract } from '../../hooks/useContract'
import { isEthereumAddress } from '../../utils'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import { USDC_ETHEREUM, TELEPORT_ADDRESS_MAP } from '../../constants'
/**
 * Returns a map of the given addresses to their eventually consistent ETH balances.
 */
export function useETHBalances(
  uncheckedAddresses?: (string | undefined)[]
): { [address: string]: CurrencyAmount | undefined } {
  const multicallContract = useMulticallContract()

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isEthereumAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses]
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map(address => [address])
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results]
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (TokenInfo | undefined)[]
): [{ [tokenAddress: string]: number | undefined }, boolean] {
  const validatedTokens: TokenInfo[] = useMemo(
    () => tokens?.filter((t?: TokenInfo): t is TokenInfo => isEthereumAddress(t?.address) !== false) ?? [],
    [tokens]
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map(vt => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])
  const anyLoading: boolean = useMemo(() => balances.some(callState => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: number | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined
              if (amount) {
                const finalAmount =
                  token.decimals > 8
                    ? JSBI.divide(amount, JSBI.BigInt(10 ** (token.decimals - 8)))
                    : JSBI.multiply(amount, JSBI.BigInt(10 ** (8 - token.decimals)))
                memo[token.address] = parseFloat(finalAmount.toString()) / 10 ** 8
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances]
    ),
    anyLoading
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (TokenInfo | undefined)[]
): { [tokenAddress: string]: number | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: TokenInfo): number | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(account?: string, currencies?: (TokenInfo | undefined)[]): (number | undefined)[] {
  const tokens = useMemo(() => currencies?.filter(currency => currency?.address) ?? [], [currencies])

  const tokenBalances = useTokenBalances(account, tokens)

  return useMemo(
    () =>
      currencies?.map(currency => {
        if (!account || !currency) return undefined
        return currency.address ? tokenBalances[currency.address] : undefined
      }) ?? [],
    [account, currencies, tokenBalances]
  )
}

export function useCurrencyBalance(account?: string, currency?: TokenInfo): number | undefined {
  return useCurrencyBalances(account, [currency])[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: number | undefined } {
  const { account } = useActiveWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}

// Get the usdc pool balance
export function useUSDCTokenBalance(token?: TokenInfo): number {
  const { chainId = 1 } = useActiveWeb3React()
  const address = TELEPORT_ADDRESS_MAP[USDC_ETHEREUM[chainId]?.address]
  const tokenBalances = useTokenBalances(address, [USDC_ETHEREUM[chainId]])
  if (token?.symbol !== 'USDC') return 0
  return tokenBalances?.[USDC_ETHEREUM[chainId].address] ?? 0
}
