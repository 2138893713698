import React from 'react'
import styled from 'styled-components'
import ethereumIcon from '../../assets/images/ethereum-logo.svg'
import flowIcon from '../../assets/images/flow-logo.svg'
import bscIcon from '../../assets/images/bsc-logo.svg'
import aptosIcon from '../../assets/chains/Aptos.svg'
const images: { [tokenName: string]: string } = {
  FLOW: flowIcon,
  ETHEREUM: ethereumIcon,
  BSC: bscIcon,
  APTOS: aptosIcon
}

const IconWrapper = styled.div<{ size?: number }>`
  ${({ theme }) => theme.flexColumnNoWrap};
  align-items: center;
  justify-content: center;
  & > * {
    height: ${({ size }) => (size ? size + 'px' : '32px')};
    width: ${({ size }) => (size ? size + 'px' : '32px')};
  }
`

export default function NetworkLogo({ network, size = 24 }: { network: string; size?: number }) {
  return (
    <IconWrapper size={size}>
      <img src={images[network]} alt={network} />
    </IconWrapper>
  )
}
