const scriptBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodyBSC from 0xTELEPORTBSCBLTADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @{FungibleToken.Vault}

  prepare(accu: auth(BorrowValue) &Account) {
    let blockTokenVaultRef = accu.storage.borrow<auth(FungibleToken.Withdraw) &BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS)
      .capabilities.borrow<&{TeleportCustodyBSC.TeleportUser}>(TeleportCustodyBSC.TeleportAdminTeleportUserPath) ?? panic("failed to borrow TeleportCustodyBSC.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scripts: { [tokenName: string]: string } = {
  BLT: scriptBLT
}

export default scripts
