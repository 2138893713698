import React, { useCallback, useState, useEffect, useMemo } from 'react'
import styled, { css } from 'styled-components'
import { StepID } from '../../../types'
import CongratsBg from '../../../assets/images/ido-congrat.svg'
import ShuffleIcon from '../../../assets/images/shuffle.png'
import BLTIcon from '../../../assets/images/blt-logo.png'
import TUSDTIcon from '../../../assets/images/tusdt-logo.svg'
import discordIcon from '../../../assets/svg/discord-logo.svg'
import twitterIcon from '../../../assets/svg/twitter-logo.svg'
import telegramIcon from '../../../assets/svg/telegram-logo.svg'
import helpIcon from '../../../assets/images/help-icon.svg'
import octopusIcon from '../../../assets/images/octopus.svg'
import PriceBox from './PriceBox'
import { useFclReact } from '../../../fcl-react/useFclReact'
import { useIDOTransactions } from '../../../fcl-react/useIDOTransactions'
import { useProjectState } from '../../../state/IDO/hook'
import { useUserStakedBLT } from '../../../fcl-react/useUserStakedBLT'
import ConfirmIDOActionModal from './ConfirmIDOActionModal'
import { TUSDT } from '../../../constants'
import { useTokenBalance } from '../../../state/wallet/hooks'
import { useIDOAPI } from '../../../fcl-react/useIDOAPI'
import { useIDOVerify } from '../../../hooks/useIDOVerify'
import QuestionHelper from '../../../components/QuestionHelper'
import { CustomLightSpinner } from '../../../theme'
import Circle from '../../../assets/images/loader.svg'
import { useAllTransactions } from '../../../state/transactionsFlow/hooks'
import { useAppDispatch } from '../../../state/IDO/hook'
import { loadUserInfo } from '../../../state/IDO/action'

const Ol = styled.ol`
  padding-left: 20px;
  line-height: 24px;
`
const Ul = styled.ul`
  list-style: disc;
  padding-left: 25px;
  line-height: 24px;
`
const BoxedUl = styled.ul`
  list-style: none;
  padding-left: 0px;
  line-height: 24px;
  & li:before {
    content: '';
    background: #7f7f7f;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 16px;
  }
`
const InlineLink = styled.a`
  color: #000;
  text-decoration: underline;
`
interface ShadowBoxProps {
  width?: string
}
const ShadowBox = styled.div<ShadowBoxProps>`
  background: #ffffff;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  width: ${props => props.width || 'auto'};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 12px;
  `}
`
const PoolPriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    align-items: center;
  `}
`
const PoolSide = styled.div`
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
  `}
`
const ShuffleIconWrapper = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  margin-top: 55px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    transform: rotate(90deg);
    margin: 12px 0 0 0;
  `}
`
interface ButtonProps {
  isDisabled?: boolean
}
const BlueButton = styled.button<ButtonProps>`
  color: #fff;
  height: 44px;
  background: ${props => (props.isDisabled ? '#BCBCBC' : '#3398ff')};
  border-radius: 30px;
  border: none;
  padding: 12px 40px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  text-align: center;
  cursor: ${props => (props.isDisabled ? 'not-allowed' : 'pointer')};
  ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin-left:0;
    margin-top: 20px;
  `}
`
const FlexEndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-top: 0;
  `}
`

const CongratsBadge = styled.div`
  position: relative;
  margin-top: 30px;
  max-width: 605px;
  width: 100%;
  height: 0;
  padding-top: 19.8%;
  background-image: url(${CongratsBg});
  background-size: contain;
  background-repeat: no-repeat;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display: none;
  `}
`
const CongratsBadgeInner = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  padding: 0 20%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`
const MobileCongratsBadge = styled.div`
  padding: 20px;
  margin-top: 30px;
  display: none;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  font-weight: 600;
  line-height: 18px;
  width: 100%;
  background: linear-gradient(94.08deg, #99c0fa 45.27%, #9ad5fc 95.41%);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  & span {
    margin-top: 10px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    display:flex;
  `}
`
const Icon = styled.img<{ size?: number }>`
  border-radius: 50%;
  ${({ size = 24 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`
const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
  `}
`
const SocialApps = styled.div`
  display: flex
  justify-content: center;
`
const SocialAppLink = styled.a`
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  background: #a8aeb4;
  border-radius: 10px;
  padding: 8px;
`
const PoolPredict = styled.div`
  margin-top: 40px;
  font-weight: 500;
  font-size: 16px;
`
const GrayBox = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  width: 100%;
  margin-top: 20px;
  padding: 20px;
`
const GrayBoxContent = styled.div`
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
`
const HelpButton = styled.a`
  height: 26px;
  width: 26px;
  display: block;
  cursor: pointer;
  background-image: url(${helpIcon});
`
const GrayUSDTPrice = styled.div`
  margin-left: 10px;
  font-weight: normal;
  font-size: 16px;
  color: #7f7f7f;
`
interface ColorProps {
  color: string
}
const Error = styled.div<ColorProps>`
  color: ${props => props.color};
`

const links = {
  getusdt: 'https://guide.blocto.app/article/6SGxM5Czev8BAwJlZLlEhh',
  getallocation: 'https://guide.blocto.app/article/blt-staking-program',
  standardpoolhelp: '/',
  unlimitedpoolhelp: '/'
}
const parseDisplayAmount = (amount: number) => amount.toFixed(8).replace(/\.?0+$/g, '')

function KYCContent(
  { telegramUrl, discordUrl, twitterUrl, name, minStakingAmount, website, kycUrl }: any,
  isInitialized: boolean
) {
  const showOngoing = useProjectState(name)?.state?.kyc.showOptions
  const { verifyPassed } = useIDOVerify(minStakingAmount)

  return (
    <div>
      <Ol type="1">
        <li>You are required to pass the {name} KYC prior to the next step.</li>
        <li>Your identity is tied to the address you used during the KYC process.</li>
        <li>
          You may check your KYC status{' '}
          <InlineLink href={kycUrl || website} target="_blank" rel="noopener noreferrer">
            here
          </InlineLink>
          .
        </li>
        <li>Please contact the {name} team if you have any questions regarding KYC.</li>
      </Ol>
      <Bottom>
        <SocialApps>
          {telegramUrl && (
            <SocialAppLink href={telegramUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={telegramIcon} size={24} />
            </SocialAppLink>
          )}

          {discordUrl && (
            <SocialAppLink href={discordUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={discordIcon} size={24} />
            </SocialAppLink>
          )}

          {twitterUrl && (
            <SocialAppLink href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={twitterIcon} size={24} />
            </SocialAppLink>
          )}
        </SocialApps>
        {showOngoing && verifyPassed && isInitialized ? (
          <BlueButton as="a" href={kycUrl || website} target="_blank" rel="noopener noreferrer">
            Go to KYC
          </BlueButton>
        ) : (
          <div />
        )}
      </Bottom>
    </div>
  )
}
function PoolContent({ symbol, iconUrl, name, poolConfig, userInfo, snapshotTimes, minStakingAmount }: any) {
  const showOngoing = useProjectState(name)?.state?.pool.showOptions
  const { stakedLocked, stakedUnlocked, stakedRequestedToUnstake, stakedRequestedToCommit } = useUserStakedBLT()
  const { getValidStake, selectPool, getUserInfo } = useIDOAPI()
  const { verifyPassed } = useIDOVerify(minStakingAmount)
  const [validStake, setValidStake] = useState({ UNLIMITED: '0.0', LIMITED: '0.0' })
  const [perdictPrice, setPerdictPrice] = useState({ unlimited: 0, limited: 0 })
  // const [stakeError, setStakeError] = useState<any>('')
  const [selectingPools, setSelectingPools] = useState(false)
  const allTransactions = useAllTransactions()
  const selectPoolTxs = Object.values(allTransactions).filter(
    tx => tx?.ido?.name === name && tx?.ido?.action === 'SELECT_POOL'
  )
  const dispatch = useAppDispatch()
  const [priceColor, setPriceColor] = useState({ input: '#141414', predict: '#141414' })
  const [modalState, setModalState] = useState<{
    poolType: string
    showConfirm: boolean
    errorMessage: string | undefined
    attemptingTxn: boolean
    txHash: string | undefined
  }>({
    poolType: '',
    showConfirm: false,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })
  const { stakeInfos } = userInfo
  const epochTimes = Number(snapshotTimes) || 0
  const nowTotalStake = stakedLocked + stakedUnlocked

  const disqualified = useMemo(() => {
    let disqualified = false
    if (userInfo?.stakeInfos?.length > 0) {
      for (let i = 1; i < stakeInfos.length; i++) {
        if (stakeInfos[i].stakeAmount < stakeInfos[i - 1].stakeAmount) {
          disqualified = true
          break
        }
      }
    }
    return disqualified
  }, [userInfo, stakeInfos])

  const addStakeNextEpoch = Number(stakedRequestedToCommit) - Number(stakedRequestedToUnstake)

  const calculatePoolPredict = useCallback(
    (inputPrice: number) => {
      if (!!Object.keys(userInfo).length) {
        if (Number(inputPrice) + addStakeNextEpoch < 0 || disqualified) return { unlimited: 0, limited: 0 }
        const calcUnlimited = () => {
          if (Number(validStake?.UNLIMITED) > 0) {
            // Normal situation - already has stake amount in pool
            if (stakeInfos.length === 0) {
              return (
                ((inputPrice + stakedUnlocked) / (Number(validStake.UNLIMITED) + inputPrice + stakedUnlocked)) *
                Number(poolConfig.UNLIMITED.amount)
              )
            } else if (stakeInfos.length >= epochTimes) {
              const averageUnlockStake =
                stakeInfos
                  .slice(epochTimes - 1)
                  .reduce(
                    (acc: number, cur: { stakeAmount: number; lockAmount: number }) =>
                      acc + Math.max(Number(cur.stakeAmount) - Number(cur.lockAmount), 0),
                    0
                  ) / epochTimes
              const unjoinPoolAmount = userInfo.poolType === '' ? averageUnlockStake : 0
              return (
                (averageUnlockStake / (Number(validStake?.UNLIMITED) + unjoinPoolAmount)) *
                Number(poolConfig.UNLIMITED.amount)
              )
            } else {
              const averageUnlockStake =
                stakeInfos.reduce(
                  (acc: number, cur: { stakeAmount: number; lockAmount: number }) =>
                    acc + Math.max(Number(cur.stakeAmount) - Number(cur.lockAmount), 0),
                  Number(inputPrice + stakedUnlocked)
                ) /
                (stakeInfos.length + 1)
              return (
                (averageUnlockStake / (Number(validStake?.UNLIMITED) + averageUnlockStake)) *
                Number(poolConfig.UNLIMITED.amount)
              )
            }
          } else {
            // If no stake amount in pool, assume user takes all
            return inputPrice + stakedUnlocked > 0 ? Number(poolConfig.UNLIMITED.amount) : 0
          }
        }
        const calcStandard = () => {
          if (Number(validStake?.LIMITED)) {
            // Normal situation - already has stake amount in pool
            if (stakeInfos.length === 0) {
              const validUserStakeAmount =
                Number(poolConfig.LIMITED.upperBound) === 0
                  ? inputPrice + nowTotalStake
                  : Math.min(inputPrice + nowTotalStake, poolConfig.LIMITED.upperBound)
              return (
                (validUserStakeAmount / (Number(validStake.LIMITED) + validUserStakeAmount)) * poolConfig.LIMITED.amount
              )
            } else if (stakeInfos.length >= epochTimes) {
              // Last epoch, cannot input
              const averageStake =
                stakeInfos
                  .slice(epochTimes - 1)
                  .reduce(
                    (acc: number, cur: { stakeAmount: string; lockAmount: string }) => acc + Number(cur.stakeAmount),
                    0
                  ) / epochTimes
              const validUserStakeAmount =
                Number(poolConfig.LIMITED.upperBound) === 0
                  ? averageStake
                  : Math.min(averageStake, poolConfig.LIMITED.upperBound)
              const unjoinPoolAmount = userInfo.poolType === '' ? validUserStakeAmount : 0
              return (
                (validUserStakeAmount / (Number(validStake.LIMITED) + unjoinPoolAmount)) * poolConfig.LIMITED.amount
              )
            } else {
              const averageStake =
                stakeInfos.reduce(
                  (acc: number, cur: { stakeAmount: string; lockAmount: string }) => acc + Number(cur.stakeAmount),
                  inputPrice + nowTotalStake
                ) /
                (stakeInfos.length + 1)
              const validUserStakeAmount =
                Number(poolConfig.LIMITED.upperBound) === 0
                  ? averageStake
                  : Math.min(averageStake, poolConfig.LIMITED.upperBound)
              return (
                (validUserStakeAmount / (Number(validStake.LIMITED) + validUserStakeAmount)) * poolConfig.LIMITED.amount
              )
            }
          } else {
            // If no stake amount in pool, assume user takes all
            return inputPrice + nowTotalStake > 0 ? Number(poolConfig.LIMITED.amount) : 0
          }
        }
        return {
          unlimited: Math.round(calcUnlimited() * 10 ** 8) / 10 ** 8,
          limited: Math.round(calcStandard() * 10 ** 8) / 10 ** 8
        }
      }
      return { unlimited: 0, limited: 0 }
    },
    [
      nowTotalStake,
      stakedUnlocked,
      userInfo,
      validStake,
      poolConfig,
      stakeInfos,
      epochTimes,
      disqualified,
      addStakeNextEpoch
    ]
  )
  const handleInput = useCallback(
    (input: string) => {
      setPerdictPrice(calculatePoolPredict(Number(input)))
      if (Number(input) + addStakeNextEpoch < 0 || disqualified) {
        /* @todo: add this back after starly ido finish
        const errorText = `It seems like you've requested to unstake  ${stakedRequestedToUnstake} $BLT in the next epoch. You may be disqualified from participating in the launchpool given the unstake request.`
        setPriceColor({ input: '#d90909', predict: '#d90909' })
        setStakeError(errorText)
        */
      } else if (Number(input) > 0) {
        setPriceColor(prev => ({ ...prev, predict: '#3398FF' }))
      } else {
        setPriceColor(prev => ({ ...prev, predict: '#141414' }))
      }
    },
    [calculatePoolPredict, addStakeNextEpoch, disqualified]
  )
  const handleSelect = useCallback(() => {
    setSelectingPools(true)
    setModalState(prev => ({
      ...prev,
      attemptingTxn: true
    }))
    selectPool(name, modalState.poolType)
      .then(hash => {
        setModalState(prev => ({
          ...prev,
          attemptingTxn: false,
          txHash: hash
        }))
      })
      .catch(error => {
        setSelectingPools(false)
        setModalState(prev => ({
          ...prev,
          attemptingTxn: false,
          txHash: undefined,
          errorMessage: error.message
        }))
      })
  }, [name, modalState, selectPool])
  const handleDismiss = useCallback(() => {
    setModalState({
      poolType: '',
      showConfirm: false,
      txHash: undefined,
      attemptingTxn: false,
      errorMessage: undefined
    })
  }, [setModalState])

  useEffect(() => {
    const hasPendingTxs = selectPoolTxs.some(tx => !tx.receipt)
    const hasExecutedTx = selectPoolTxs.some(tx => tx?.receipt?.statusCode === 0)
    const allTxFailed = selectPoolTxs.every(tx => tx?.receipt?.statusCode === 1)
    if (hasPendingTxs) {
      // No receipt, some select pool tx still running
      setSelectingPools(true)
    } else {
      // Already has select pool tx sealed
      if (hasExecutedTx) {
        // Has success select pool tx, reload userInfo
        if (userInfo.poolType === '') {
          getUserInfo(name)
            .then(info => {
              dispatch(loadUserInfo({ projectName: name, userInfo: info }))
              if (info?.poolType) {
                setSelectingPools(false)
              }
            })
            .catch(() => {
              setSelectingPools(false)
            })
        }
      } else if (allTxFailed) {
        // All select pool tx failed
        setSelectingPools(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectPoolTxs])

  const standardHint = (
    <QuestionHelper
      content={
        <>
          This is an estimation based on your current $BLT in-stake.
          <br /> Standard pool allocation calculation: <br />
          <strong>
            your in-stake $BLT (locked + circulated) in pool/total $BLT in stake in pool = percentage equivalent of your
            allocation.
          </strong>
          <br />
          Beware that any amount in-stake exceeding the standard pool cap will not increase your allocation If and when
          the journey consists of multiple epochs, your average $BLT across epochs will take effect instead.
        </>
      }
      icon={<HelpButton />}
    />
  )

  const unlimitedHint = (
    <QuestionHelper
      content={
        <>
          This is an estimation based on your current $BLT in-stake.
          <br /> Unlimited pool allocation calculation: <br />
          <strong>
            your in-stake $BLT (circulated only) in pool/total $BLT in stake in pool = percentage equivalent of your
            allocation.
          </strong>
          <br />
          If and when the journey consists of multiple epochs, your average $BLT across epochs will take effect instead.
        </>
      }
      icon={<HelpButton />}
    />
  )

  useEffect(() => {
    getValidStake(name).then(data => {
      setValidStake(prev => ({ ...prev, ...data }))
    })
  }, [getValidStake, name])

  useEffect(() => {
    handleInput('0')
  }, [nowTotalStake, handleInput])

  return (
    <div>
      <Ol type="1">
        {!userInfo?.poolType && (
          <>
            <li>Each address can only participate in one pool at a time. </li>
            <li>You cannot cancel or switch pool once you&apos;ve chosen a pool.</li>
            <li>Standard pool accepts both locked and circulated $BLT, Unlimited pool accepts only circulated $BLT.</li>
            <li>
              An admission will be taken when selecting a pool.
              <Ul>
                <li>Standard pool admission: {Number(poolConfig.LIMITED.selectFee)} $BLT.</li>
                <li>Unlimited pool admission: {Number(poolConfig.UNLIMITED.selectFee)} $BLT.</li>
              </Ul>
            </li>
          </>
        )}
        {userInfo.poolType === 'LIMITED' && (
          <>
            <li>Standard pool in-stake cap: {parseFloat(poolConfig.LIMITED.upperBound).toLocaleString()} $BLT.</li>
            <li>The allocation you will receive is calculated in proportion to the total in-stake in this pool.</li>
            {/*
              <li>Unstaking any $BLT will result in disqualification in this stage.</li>
            */}
            <li>Standard pool accepts both locked and circulated $BLT.</li>
          </>
        )}
        {userInfo.poolType === 'UNLIMITED' && (
          <>
            <li>Unlimited pool in-stake cap: none.</li>
            <li>The allocation you will receive is calculated in proportion to the total in-stake in this pool.</li>
            {/*
              <li>Unstaking any $BLT will result in disqualification in this stage.</li>
            */}
            <li>Unlimited pool accepts only circulated $BLT.</li>
          </>
        )}
      </Ol>
      {showOngoing && userInfo.hasOwnProperty('poolType') && verifyPassed ? (
        <PoolPredict>
          Your estimated allocation
          <ConfirmIDOActionModal
            isOpen={modalState.showConfirm}
            attemptingTxn={modalState.attemptingTxn}
            txHash={modalState.txHash}
            onConfirm={handleSelect}
            errorMessage={modalState.errorMessage}
            onDismiss={handleDismiss}
            type="POOL"
            name={name}
          />
          <GrayBox>
            Your $BLT in-stake: {parseDisplayAmount(stakedLocked + stakedUnlocked)} $BLT
            <GrayBoxContent>
              Locked : {parseDisplayAmount(stakedLocked)} $BLT + Circulating : {parseDisplayAmount(stakedUnlocked)} $BLT{' '}
            </GrayBoxContent>
          </GrayBox>
          <PoolPriceWrapper>
            <PoolSide>
              <ShadowBox width="100%">
                <PriceBox
                  icon={BLTIcon}
                  content={
                    stakeInfos?.length >= snapshotTimes
                      ? `${parseDisplayAmount(stakedLocked + stakedUnlocked)} $BLT`
                      : `${parseDisplayAmount(stakedLocked + stakedUnlocked)} +`
                  }
                  showInput={!(stakeInfos?.length >= snapshotTimes)}
                  symbol="$BLT"
                  title="Your stake"
                  onChange={handleInput}
                  color={priceColor.input}
                />
              </ShadowBox>
              {/*
              !!stakeError && (
                <Error color={priceColor.input === '#d90909' ? '#d90909' : '#3398FF'}>{stakeError}</Error>
              )
              */}
            </PoolSide>

            <ShuffleIconWrapper>
              <img src={ShuffleIcon} alt="Shuffle" />
            </ShuffleIconWrapper>

            <PoolSide>
              {(() => {
                switch (userInfo.poolType) {
                  default:
                    return (
                      <>
                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.limited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.limited * poolConfig.LIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Standard pool</strong>}
                            helpButton={standardHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          ></PriceBox>
                        </ShadowBox>

                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.unlimited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.unlimited * poolConfig.UNLIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Unlimited pool</strong>}
                            helpButton={unlimitedHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          ></PriceBox>
                        </ShadowBox>
                      </>
                    )
                  case '':
                    return (
                      <>
                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.limited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.limited * poolConfig.LIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Standard pool</strong>}
                            helpButton={standardHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          >
                            <FlexEndWrapper>
                              <BlueButton
                                onClick={
                                  selectingPools
                                    ? undefined
                                    : () => {
                                        setModalState(prev => ({
                                          ...prev,
                                          poolType: 'LIMITED',
                                          showConfirm: true
                                        }))
                                      }
                                }
                                isDisabled={selectingPools}
                              >
                                {selectingPools ? (
                                  <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                ) : (
                                  'Join standard pool'
                                )}
                              </BlueButton>
                            </FlexEndWrapper>
                          </PriceBox>
                        </ShadowBox>

                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.unlimited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.unlimited * poolConfig.UNLIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Unlimited pool</strong>}
                            helpButton={unlimitedHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          >
                            <FlexEndWrapper>
                              <BlueButton
                                onClick={
                                  selectingPools
                                    ? undefined
                                    : () => {
                                        setModalState(prev => ({
                                          ...prev,
                                          poolType: 'UNLIMITED',
                                          showConfirm: true
                                        }))
                                      }
                                }
                                isDisabled={selectingPools}
                              >
                                {selectingPools ? (
                                  <CustomLightSpinner src={Circle} alt="loader" size="12px" />
                                ) : (
                                  'Join unlimited pool'
                                )}
                              </BlueButton>
                            </FlexEndWrapper>
                          </PriceBox>
                        </ShadowBox>
                      </>
                    )
                  case 'LIMITED':
                    return (
                      <>
                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.limited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.limited * poolConfig.LIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Standard pool</strong>}
                            helpButton={standardHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          />
                        </ShadowBox>
                        {stakeInfos?.length < snapshotTimes && (
                          <FlexEndWrapper>
                            <BlueButton as="a" href={links.getallocation} target="_blank" rel="noopener noreferrer">
                              Get more allocation
                            </BlueButton>
                          </FlexEndWrapper>
                        )}
                      </>
                    )
                  case 'UNLIMITED':
                    return (
                      <>
                        <ShadowBox width="100%">
                          <PriceBox
                            icon={iconUrl}
                            content={
                              <>
                                {`≈ ${perdictPrice.unlimited} $${symbol}`}
                                <GrayUSDTPrice>
                                  ≈ {Math.round(perdictPrice.unlimited * poolConfig.UNLIMITED.exchangeRate)} tUSDT
                                </GrayUSDTPrice>
                              </>
                            }
                            title={<strong>Unlimited pool</strong>}
                            helpButton={unlimitedHint}
                            fullwidth={true}
                            color={priceColor.predict}
                          />
                        </ShadowBox>
                        {stakeInfos?.length < snapshotTimes && (
                          <FlexEndWrapper>
                            <BlueButton as="a" href={links.getallocation} target="_blank" rel="noopener noreferrer">
                              Get more allocation
                            </BlueButton>
                          </FlexEndWrapper>
                        )}
                      </>
                    )
                }
              })()}
            </PoolSide>
          </PoolPriceWrapper>
        </PoolPredict>
      ) : null}
    </div>
  )
}
function DepositContent({ name, userInfo, poolConfig, symbol, minStakingAmount }: any) {
  const showOngoing = useProjectState(name)?.state?.deposit.showOptions
  const { deposit } = useIDOTransactions(name)
  const { chainId } = useFclReact()
  const { verifyPassed } = useIDOVerify(minStakingAmount)
  const [amount, setAmount] = useState('')
  const [depositing, setDepositing] = useState(false)
  const allTransactions = useAllTransactions()
  const depositingTxs = Object.values(allTransactions).filter(
    tx => tx?.ido?.name === name && tx?.ido?.action === 'DEPOSIT'
  )
  const dispatch = useAppDispatch()
  const { getUserInfo } = useIDOAPI()
  const [error, setError] = useState('')
  const [modalState, setModalState] = useState<{
    showConfirm: boolean
    errorMessage: string | undefined
    attemptingTxn: boolean
    txHash: string | undefined
  }>({
    showConfirm: false,
    attemptingTxn: false,
    errorMessage: undefined,
    txHash: undefined
  })
  const tusdtBalance = useTokenBalance(TUSDT[chainId])
  const [quota, deposited] = [Number(userInfo.quota), Number(userInfo.deposited)]
  const quotaMinusDeposited = Math.round((quota - deposited) * 10 ** 8) / 10 ** 8
  const handleDeposit = useCallback(() => {
    if (!deposit) {
      return
    }
    setDepositing(true)
    setModalState(prev => ({
      ...prev,
      attemptingTxn: true
    }))
    deposit(Number(amount))
      .then(hash => {
        setModalState(prev => ({
          ...prev,
          attemptingTxn: false,
          txHash: hash
        }))
      })
      .catch(error => {
        setDepositing(false)
        setModalState(prev => ({
          ...prev,
          attemptingTxn: false,
          txHash: undefined,
          errorMessage: error.message
        }))
      })
  }, [amount, deposit, setModalState])

  const handleDismiss = useCallback(() => {
    setModalState({
      showConfirm: false,
      txHash: undefined,
      attemptingTxn: false,
      errorMessage: undefined
    })
  }, [setModalState])
  const handleChange = useCallback(
    e => {
      setAmount(e)
      if (deposited + Number(e) > quota) {
        setError('You have reached your quota.')
      } else if (e > Math.min(quotaMinusDeposited, Number(tusdtBalance))) {
        setError(
          quotaMinusDeposited > Number(tusdtBalance)
            ? 'You don’t have enough tUSDT balance.'
            : 'You cannot submit an amount that exceeds your allocation.'
        )
      } else {
        setError('')
      }
    },
    [setAmount, deposited, quota, quotaMinusDeposited, tusdtBalance]
  )

  useEffect(() => {
    const hasPendingTxs = depositingTxs.some(tx => !tx.receipt)
    const hasExecutedTx = depositingTxs.some(tx => tx?.receipt?.statusCode === 0)
    const allTxFailed = depositingTxs.every(tx => tx?.receipt?.statusCode === 1)
    if (hasPendingTxs) {
      // No receipt, some deposit tx still running
      setDepositing(true)
    } else {
      // Already has deposit tx sealed
      if (hasExecutedTx) {
        // Has success deposit tx, reload userInfo
        if (!!quota && deposited < quota) {
          const prevDeposit = deposited
          getUserInfo(name)
            .then(info => {
              if (info.deposited > prevDeposit) {
                dispatch(loadUserInfo({ projectName: name, userInfo: info }))
                setDepositing(false)
              }
            })
            .catch(() => {
              setDepositing(false)
            })
        }
      } else if (allTxFailed) {
        // All deposit tx failed
        setDepositing(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositingTxs])

  useEffect(() => {
    if (deposited >= quota) {
      setError('You have reached your quota.')
    }
  }, [quota, deposited])

  return (
    <div>
      <Ol type="1">
        <li>You cannot submit an amount that exceeds your allocation.</li>
        <li>
          This Launchpool accepts tUSDT only.{' '}
          <InlineLink href={links.getusdt} target="_blank" rel="noopener noreferrer">
            How to get tUSDT?
          </InlineLink>
        </li>
      </Ol>
      {showOngoing && userInfo.hasOwnProperty('quota') && verifyPassed ? (
        <div style={{ width: 'fit-content' }}>
          <ConfirmIDOActionModal
            isOpen={modalState.showConfirm}
            attemptingTxn={modalState.attemptingTxn}
            txHash={modalState.txHash}
            onConfirm={handleDeposit}
            errorMessage={modalState.errorMessage}
            onDismiss={handleDismiss}
            type="DEPOSIT"
            amount={amount}
          />
          <ShadowBox>
            <BoxedUl>
              <li>
                Your Launchpool allocation:{' '}
                {Math.round((quota / poolConfig[userInfo.poolType].exchangeRate) * 10 ** 8) / 10 ** 8} ${symbol} (={' '}
                {quota} tUSDT)
              </li>
              <li>Your tUSDT balance: {tusdtBalance} tUSDT</li>
              <li>
                You&apos;ve submitted: {deposited}/{quota} tUSDT
              </li>
            </BoxedUl>
          </ShadowBox>
          <ShadowBox>
            <PriceBox
              icon={TUSDTIcon}
              showInput={true}
              symbol="tUSDT"
              title="Deposit tUSDT"
              maxPrice={String(Math.min(quotaMinusDeposited, Number(tusdtBalance)))}
              onChange={handleChange}
            >
              <div style={{ marginLeft: '20px', width: '100%' }}>
                <BlueButton
                  onClick={
                    !!error || depositing || !Number(amount)
                      ? undefined
                      : () => {
                          setModalState(prev => ({
                            ...prev,
                            showConfirm: true
                          }))
                        }
                  }
                  isDisabled={!!error || depositing || !Number(amount)}
                >
                  {depositing ? <CustomLightSpinner src={Circle} alt="loader" size="12px" /> : 'Submit'}
                </BlueButton>
              </div>
            </PriceBox>
            {error ? <Error color="#D90909">{error}</Error> : null}
          </ShadowBox>
        </div>
      ) : null}
    </div>
  )
}
function CompleteContent({ name, symbol, iconUrl, userInfo }: any) {
  const showOngoing = userInfo.deposited && userInfo.distributed
  const { account } = useFclReact()
  const calculatedAmount = userInfo.deposited || 0
  const displayAmount = calculatedAmount.toLocaleString()
  return showOngoing ? (
    <div>
      <Ol type="1">
        <li>
          {displayAmount} ${symbol} have been distributed to your wallet ({account}).
        </li>
        <li>You can check it in your Blocto app by adding the assest card.</li>
      </Ol>
      <CongratsBadge>
        <CongratsBadgeInner>
          <span>Congrats! The {name} Launchpool allocation</span>
          <span style={{ fontSize: '1.75em' }}>
            <img src={iconUrl} alt={symbol} width="28px" /> {displayAmount} ${symbol}
          </span>
        </CongratsBadgeInner>
      </CongratsBadge>
      <MobileCongratsBadge>
        <img src={octopusIcon} alt="Octopus" width="92px" />
        <span>Congrats! The {name} Launchpool allocation</span>
        <span style={{ fontSize: '1.75em' }}>
          <img src={iconUrl} alt={symbol} width="28px" /> {displayAmount} ${symbol}
        </span>
      </MobileCongratsBadge>
    </div>
  ) : (
    `Exact timing and details to be confirmed by ${name} closer to the time to sync with other $${symbol} launches.`
  )
}

export const StepContent = {
  [StepID.kyc]: KYCContent,
  [StepID.pool]: PoolContent,
  [StepID.deposit]: DepositContent,
  [StepID.complete]: CompleteContent
}
