import { Currency } from '@uniswap/sdk'
import React, { useCallback, useState } from 'react'
import { ReactComponent as ArrowDown } from '../../assets/images/arrow-down-black.svg'
import { ReactComponent as Plus } from '../../assets/images/plus-black.svg'
import { ReactComponent as PlusWhite } from '../../assets/images/plus-white.svg'
import ReactGA from 'react-ga'
import { RouteComponentProps } from 'react-router'
import { OutlineCard } from '../../components/Card'
import TransactionConfirmationModal, { ConfirmationModalContent } from '../../components/TransactionConfirmationModal'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import DoubleCurrencyLogo from '../../components/DoubleLogo'
import { AddRemoveTabs } from '../../components/NavigationTabs'
import FullPositionCard from '../../components/PositionCard'
import { RowFixed } from '../../components/Row'
import { TransactionResponse } from '../../types'

import Slider from '../../components/Slider'
import CurrencyLogo from '../../components/CurrencyLogo'
import { useFclReact, useRemoveLiquidity } from '../../fcl-react'
import { useCurrency } from '../../hooks/Tokens'

import { useTransactionAdder } from '../../state/transactionsFlow/hooks'
import { currencyId } from '../../utils/currencyId'
import useDebouncedChangeHandler from '../../utils/useDebouncedChangeHandler'
import AppBody from '../AppBody'
import { Wrapper } from '../Pool/styleds'
import { useBurnActionHandlers } from '../../state/burn/hooks'
import { useDerivedBurnInfo, useBurnState } from '../../state/burn/hooks'
import { Field } from '../../state/burn/actions'
import { useWalletModalFlowToggle } from '../../state/application/hooks'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { Box, Flex, Grid, Text, Tabs, TabList, Tab } from '@chakra-ui/react'
import Button from '../../components/ButtonPrimary'
import { useTranslation } from 'react-i18next'

export default function RemoveLiquidity({
  history,
  match: {
    params: { currencyIdA, currencyIdB }
  }
}: RouteComponentProps<{ currencyIdA: string; currencyIdB: string }>) {
  const { t } = useTranslation()
  const [currencyA, currencyB] = [useCurrency(currencyIdA) ?? undefined, useCurrency(currencyIdB) ?? undefined]
  const { account, chainId, fcl } = useFclReact()
  const tokens = {
    [Field.CURRENCY_A]: currencyA,
    [Field.CURRENCY_B]: currencyB
  }

  // toggle wallet when disconnected
  const toggleWalletModal = useWalletModalFlowToggle()

  // burn state
  const { independentField, typedValue } = useBurnState()
  const { pair, parsedAmounts, error } = useDerivedBurnInfo(
    tokens ?? undefined,
    currencyA ?? undefined,
    currencyB ?? undefined
  )
  const { onUserInput: _onUserInput } = useBurnActionHandlers()
  const isValid = !error

  // modal and loading
  const [showConfirm, setShowConfirm] = useState<boolean>(false)
  const [showDetailed, setShowDetailed] = useState<boolean>(false)
  const [attemptingTxn, setAttemptingTxn] = useState(false) // clicked confirm

  // txn values
  const [txHash, setTxHash] = useState<string>('')
  const [allowedSlippage] = useUserSlippageTolerance()

  const formattedAmounts = {
    [Field.LIQUIDITY_PERCENT]:
      parsedAmounts[Field.LIQUIDITY_PERCENT] === 0
        ? '0'
        : parsedAmounts[Field.LIQUIDITY_PERCENT] < 0.01
        ? '<1'
        : parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0),
    [Field.LIQUIDITY]:
      independentField === Field.LIQUIDITY ? typedValue : parsedAmounts[Field.LIQUIDITY]?.toFixed(8) ?? '',
    [Field.CURRENCY_A]:
      independentField === Field.CURRENCY_A ? typedValue : parsedAmounts[Field.CURRENCY_A]?.toFixed(8) ?? '',
    [Field.CURRENCY_B]:
      independentField === Field.CURRENCY_B ? typedValue : parsedAmounts[Field.CURRENCY_B]?.toFixed(8) ?? ''
  }

  const atMaxAmount = parsedAmounts[Field.LIQUIDITY_PERCENT] === 1

  // wrapped onUserInput to clear signatures
  const onUserInput = useCallback(
    (field: Field, typedValue: string) => {
      return _onUserInput(field, typedValue)
    },
    [_onUserInput]
  )

  const onLiquidityInput = useCallback((typedValue: string): void => onUserInput(Field.LIQUIDITY, typedValue), [
    onUserInput
  ])
  const onCurrencyAInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_A, typedValue), [
    onUserInput
  ])
  const onCurrencyBInput = useCallback((typedValue: string): void => onUserInput(Field.CURRENCY_B, typedValue), [
    onUserInput
  ])

  // tx sending
  const addTransaction = useTransactionAdder()
  const removeLiquidity = useRemoveLiquidity()

  async function onRemove() {
    if (!chainId || !fcl || !account || !pair || !removeLiquidity) throw new Error('missing dependencies')

    const { [Field.CURRENCY_A]: currencyAmountA, [Field.CURRENCY_B]: currencyAmountB } = parsedAmounts
    if (!currencyAmountA || !currencyAmountB) {
      throw new Error('missing currency amounts')
    }

    if (!currencyA || !currencyB) throw new Error('missing tokens')
    const liquidityAmount = parsedAmounts[Field.LIQUIDITY]
    if (!liquidityAmount) throw new Error('missing liquidity amount')

    setAttemptingTxn(true)

    removeLiquidity(pair, liquidityAmount).then((response: TransactionResponse) => {
      setAttemptingTxn(false)

      addTransaction(response, {
        summary:
          'Remove ' +
          parsedAmounts[Field.CURRENCY_A]?.toFixed(4) +
          ' ' +
          currencyA?.symbol +
          ' and ' +
          parsedAmounts[Field.CURRENCY_B]?.toFixed(4) +
          ' ' +
          currencyB?.symbol
      })

      setTxHash(response.transactionId || '')

      ReactGA.event({
        category: 'Liquidity',
        action: 'Remove',
        label: [currencyA?.symbol, currencyB?.symbol].join('/')
      })
    })
  }

  function modalHeader() {
    return (
      <Grid gap="16px" position="relative">
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          bg="background.tertiary"
          borderRadius="12px"
          p="17px 16px"
        >
          <Text fontSize="24px" fontWeight={600} color="font.primary">
            {parsedAmounts[Field.CURRENCY_A]?.toFixed(6)}
          </Text>
          <Flex flexWrap="wrap">
            <CurrencyLogo currency={currencyA} size={'24px'} />
            <Text color="font.primary" fontSize={14} fontWeight={500} ml="10px">
              {currencyA?.symbol}
            </Text>
          </Flex>
        </Flex>
        <Flex justify="center" position="absolute" left="50%" transform="translateX(-50%)" top="15%">
          <PlusWhite />
        </Flex>
        <Flex
          align="center"
          justify="space-between"
          w="100%"
          bg="background.tertiary"
          borderRadius="12px"
          p="17px 16px"
        >
          <Text fontSize="24px" fontWeight={600} color="font.primary">
            {parsedAmounts[Field.CURRENCY_B]?.toFixed(6)}
          </Text>
          <Flex flexWrap="wrap">
            <CurrencyLogo currency={currencyB} size={'24px'} />{' '}
            <Text color="font.primary" fontSize={14} fontWeight={500} ml="10px">
              {currencyB?.symbol}
            </Text>
          </Flex>
        </Flex>
        <Flex w="100%">
          <Text fontSize="16px" fontWeight={500} color="font.primary">
            {`${currencyA?.symbol}/${currencyB?.symbol} Pool Tokens`}
          </Text>
        </Flex>
        <Text fontSize="12px" fontWeight={400} color="font.secondary">
          {t('liquidity.output_estimated_hint', { PERCENT: allowedSlippage / 100 })}
        </Text>
      </Grid>
    )
  }

  function modalBottom() {
    return (
      <>
        {pair && (
          <>
            <Flex flexWrap="wrap" bg="background.tertiary" p="16px" mb="20px" borderRadius={12}>
              <Flex justify="space-between" w="100%" pt="4px">
                <Text fontWeight="500" fontSize="14px" color="font.primary">
                  {t('liquidity.modal.uni_burned', {
                    CURRENCY_A_SYMBOL: currencyA?.symbol,
                    CURRENCY_B_SYMBOL: currencyB?.symbol
                  })}
                </Text>
                <Flex>
                  <DoubleCurrencyLogo currency0={currencyA} currency1={currencyB} size={24} />
                  <Text fontWeight="400" fontSize="14px" color="font.primary">
                    {parsedAmounts[Field.LIQUIDITY]?.toFixed(6)}
                  </Text>
                </Flex>
              </Flex>
              <Flex justify="space-between" w="100%" pt="16px">
                <Text fontWeight="500" fontSize="14px" color="font.primary">
                  {t('liquidity.modal.price')}
                </Text>
                <Flex>
                  <Text fontWeight="400" fontSize="14px" color="font.primary">
                    1 {currencyA?.symbol} = {currencyA && pair?.price ? pair?.price.toFixed(8) : '-'}{' '}
                    {currencyB?.symbol}
                  </Text>
                </Flex>
              </Flex>
              <Flex justify="flex-end" w="100%" pt="4px">
                <Flex>
                  <Text fontWeight="400" fontSize="14px" color="font.primary">
                    1 {currencyB?.symbol} = {currencyB && pair?.price ? (1.0 / pair?.price).toFixed(8) : '-'}{' '}
                    {currencyA?.symbol}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </>
        )}
        <Button onClick={onRemove} height="54px" br="12px">
          <Text fontWeight={500} fontSize={20}>
            Confirm
          </Text>
        </Button>
      </>
    )
  }

  const pendingText = t('liquidity.modal.removePendingText', {
    CURRENCY_A_AMOUNT: parsedAmounts[Field.CURRENCY_A]?.toFixed(8),
    CURRENCY_A_SYMBOL: currencyA?.symbol,
    CURRENCY_B_AMOUNT: parsedAmounts[Field.CURRENCY_B]?.toFixed(8),
    CURRENCY_B_SYMBOL: currencyB?.symbol
  })

  const liquidityPercentChangeCallback = useCallback(
    (value: number) => {
      onUserInput(Field.LIQUIDITY_PERCENT, value.toString())
    },
    [onUserInput]
  )

  const handleSelectCurrencyA = useCallback(
    (currency: Currency) => {
      if (currencyIdB && currencyId(currency) === currencyIdB) {
        history.push(`/remove/${currencyId(currency)}/${currencyIdA}`)
      } else {
        history.push(`/remove/${currencyId(currency)}/${currencyIdB}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )
  const handleSelectCurrencyB = useCallback(
    (currency: Currency) => {
      if (currencyIdA && currencyId(currency) === currencyIdA) {
        history.push(`/remove/${currencyIdB}/${currencyId(currency)}`)
      } else {
        history.push(`/remove/${currencyIdA}/${currencyId(currency)}`)
      }
    },
    [currencyIdA, currencyIdB, history]
  )

  const handleDismissConfirmation = useCallback(() => {
    setShowConfirm(false)
    // if there was a tx hash, we want to clear the input
    if (txHash) {
      onUserInput(Field.LIQUIDITY_PERCENT, '0')
    }
    setTxHash('')
  }, [onUserInput, txHash])

  const [innerLiquidityPercentage, setInnerLiquidityPercentage] = useDebouncedChangeHandler(
    Number.parseInt(parsedAmounts[Field.LIQUIDITY_PERCENT].toFixed(0)),
    liquidityPercentChangeCallback
  )

  return (
    <>
      <AppBody>
        <AddRemoveTabs creating={false} adding={false} />
        <Wrapper>
          <TransactionConfirmationModal
            isOpen={showConfirm}
            onDismiss={handleDismissConfirmation}
            attemptingTxn={attemptingTxn}
            hash={txHash ? txHash : ''}
            content={() => (
              <ConfirmationModalContent
                title={t('liquidity.modal.receiving')}
                onDismiss={handleDismissConfirmation}
                topContent={modalHeader}
                bottomContent={modalBottom}
              />
            )}
            pendingText={pendingText}
          />
          <Grid gap="16px">
            <OutlineCard>
              <Grid gap="20px">
                <Flex justify="space-between" align="center">
                  <Text color="font.tertiary" fontSize={14} fontWeight={400}>
                    {t('liquidity.remove.amount')}
                  </Text>
                  <Tabs
                    variant="soft-rounded"
                    tabIndex={showDetailed ? 0 : 1}
                    onChange={() => {
                      setShowDetailed(!showDetailed)
                    }}
                  >
                    <TabList bg="interaction.secondary" borderRadius="100px">
                      <Tab
                        p="4px 10px"
                        fontSize="14px"
                        fontWeight="500"
                        _selected={{ color: 'font.inverse', bg: 'interaction.primary' }}
                        _focus={{
                          boxShadow: 'none'
                        }}
                      >
                        {t('liquidity.remove.detail')}
                      </Tab>
                      <Tab
                        p="4px 10px"
                        fontSize="14px"
                        fontWeight="500"
                        _selected={{ color: 'font.inverse', bg: 'interaction.primary' }}
                        _focus={{
                          boxShadow: 'none'
                        }}
                      >
                        {t('liquidity.remove.simple')}
                      </Tab>
                    </TabList>
                  </Tabs>
                </Flex>
                <Flex align="flex-end">
                  <Text color="font.primary" fontSize={24} fontWeight={600}>
                    {formattedAmounts[Field.LIQUIDITY_PERCENT]}%
                  </Text>
                </Flex>
                {!showDetailed && (
                  <>
                    <Flex pl={formattedAmounts[Field.LIQUIDITY_PERCENT] === '0' ? '10px' : 'none'}>
                      <Slider value={innerLiquidityPercentage} onChange={setInnerLiquidityPercentage} />
                    </Flex>
                    <Flex justify="space-between" gap="8px">
                      <Button
                        background="interaction.secondary"
                        color="font.primary"
                        br="8px"
                        height="36px"
                        p="8px"
                        onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '25')}
                        width="25%"
                      >
                        25%
                      </Button>
                      <Button
                        background="interaction.secondary"
                        color="font.primary"
                        br="8px"
                        height="36px"
                        p="8px"
                        onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '50')}
                        width="25%"
                      >
                        50%
                      </Button>
                      <Button
                        background="interaction.secondary"
                        color="font.primary"
                        br="8px"
                        p="8px"
                        height="36px"
                        onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '75')}
                        width="25%"
                      >
                        50%
                      </Button>
                      <Button
                        background="interaction.secondary"
                        color="font.primary"
                        br="8px"
                        height="36px"
                        p="8px"
                        onClick={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                        width="25%"
                      >
                        100%
                      </Button>
                    </Flex>
                  </>
                )}
              </Grid>
            </OutlineCard>
            {!showDetailed && (
              <>
                <Flex justify="center">
                  <ArrowDown />
                </Flex>
                <OutlineCard>
                  <Grid gap="10px">
                    <Flex justify="space-between">
                      <Text color="font.primary" fontSize={16} fontWeight={500}>
                        {formattedAmounts[Field.CURRENCY_A] || '-'}
                      </Text>
                      <RowFixed>
                        <CurrencyLogo currency={currencyA} />
                        <Text
                          ml="6px"
                          color="font.primary"
                          fontSize={16}
                          fontWeight={500}
                          id="remove-liquidity-tokena-symbol"
                        >
                          {currencyA?.symbol}
                        </Text>
                      </RowFixed>
                    </Flex>
                    <Flex justify="space-between">
                      <Text color="font.primary" fontSize={16} fontWeight={500}>
                        {formattedAmounts[Field.CURRENCY_B] || '-'}
                      </Text>
                      <Flex>
                        <CurrencyLogo currency={currencyB} />
                        <Text ml="6px" fontSize={16} fontWeight={500} id="remove-liquidity-tokenb-symbol">
                          {currencyB?.symbol}
                        </Text>
                      </Flex>
                    </Flex>
                  </Grid>
                </OutlineCard>
              </>
            )}

            {showDetailed && (
              <>
                <CurrencyInputPanel
                  value={formattedAmounts[Field.LIQUIDITY]}
                  onUserInput={onLiquidityInput}
                  onMax={() => {
                    onUserInput(Field.LIQUIDITY_PERCENT, '100')
                  }}
                  showMaxButton={!atMaxAmount}
                  disableCurrencySelect
                  currency={pair?.liquidityToken}
                  pair={pair}
                  id="liquidity-amount"
                />
                <Flex justify="center">
                  <ArrowDown />
                </Flex>
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_A]}
                  onUserInput={onCurrencyAInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  showMaxButton={!atMaxAmount}
                  currency={currencyA}
                  label={t('liquidity.remove.output')}
                  onCurrencySelect={handleSelectCurrencyA}
                  id="remove-liquidity-tokena"
                />
                <Flex justify="center">
                  <Plus />
                </Flex>
                <CurrencyInputPanel
                  hideBalance={true}
                  value={formattedAmounts[Field.CURRENCY_B]}
                  onUserInput={onCurrencyBInput}
                  onMax={() => onUserInput(Field.LIQUIDITY_PERCENT, '100')}
                  showMaxButton={!atMaxAmount}
                  currency={currencyB}
                  label={t('liquidity.remove.output')}
                  onCurrencySelect={handleSelectCurrencyB}
                  id="remove-liquidity-tokenb"
                />
              </>
            )}
            {pair && (
              <div style={{ padding: '10px 2px' }}>
                <Flex justify="space-between">
                  <Text color="font.primary" fontSize={14} fontWeight={500}>
                    {t('liquidity.remove.price')}
                  </Text>
                  <Text color="font.primary" fontSize={14} fontWeight={400}>
                    1 {currencyA?.symbol} = {currencyA && pair?.price ? pair.price.toFixed(8) : '-'} {currencyB?.symbol}
                  </Text>
                </Flex>
                <Flex justify="space-between">
                  <Text />
                  <Text color="font.primary" fontSize={14} fontWeight={400}>
                    1 {currencyB?.symbol} = {currencyB && pair?.price ? (1.0 / pair?.price).toFixed(8) : '-'}{' '}
                    {currencyA?.symbol}
                  </Text>
                </Flex>
              </div>
            )}
            <Box position="relative">
              {!account ? (
                <Button height="54px" br="12px" onClick={toggleWalletModal}>
                  {t('liquidity.button.connect')}
                </Button>
              ) : (
                <Button height="54px" br="12px" onClick={() => setShowConfirm(true)} disabled={!isValid}>
                  <Text fontSize="16px" fontWeight="600">
                    {error || t('liquidity.button.remove')}
                  </Text>
                </Button>
              )}
            </Box>
          </Grid>
        </Wrapper>
      </AppBody>

      {pair ? (
        <Box minWidth="20rem" maxWidth="400px" mt="1rem" width="100%">
          <FullPositionCard pair={pair} needButton={false} />
        </Box>
      ) : null}
    </>
  )
}
