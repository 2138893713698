import { APTOS_NODE_URL } from './env'

export async function getTransactionByHash(hash: string) {
  try {
    const result = await fetch(`${APTOS_NODE_URL}/transactions/by_hash/${hash}`).then(response => response.json())
    return result
  } catch (error) {
    return error
  }
}
