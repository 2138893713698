import React from 'react'
import styled from 'styled-components'
import stepCheck from '../../../assets/images/stepCheck.svg'
import stepFail from '../../../assets/images/stepFail.svg'
import stepCheckLight from '../../../assets/images/stepCheckLight.svg'
import stepFailLight from '../../../assets/images/stepFailLight.svg'
import { useProjectState, useAppDispatch } from '../../../state/IDO/hook'
import { StepState, StepID } from '../../../types'
import { updateOpenSection } from '../../../state/IDO/action'
import SwapStepName from './SwapStepName'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import isBetween from 'dayjs/plugin/isBetween'
import Countdown from 'react-countdown'

interface StateProps {
  state?: StepState
  ongoing?: boolean
}
const Nav = styled.nav`
  position: relative;
`
const Ol = styled.ol`
  display: flex;
  -webkit-box-align: center;
  align-items: flex-start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  list-style-type: none;
  z-index: 1;
  padding: 0;
  margin: 0;
`
const Li = styled.li<StateProps>`
  display: inline-flex;
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  &:not(:first-child):before {
    content: '';
    display: block;
    position: absolute;
    top: 17px;
    left: ${props => (props.state !== StepState.unactive ? '-50%' : '0')};
    transform: translateY(-50%);
    width: ${props => (props.state !== StepState.unactive ? '100%' : '50%')};
    height: 6px;
    background: ${props =>
      props.state === StepState.unactive
        ? '#f9f9f9'
        : props.ongoing
        ? 'linear-gradient(90deg, #99C0FA 0%, #99C8FB 50%, #9AD5FC 100%)'
        : '#99C0FA'};
  }
  &:not(:last-child):after {
    content: '';
    display: block;
    position: absolute;
    top: 17px;
    left: 50%;
    transform: translateY(-50%);
    width: 50%;
    height: 6px;
    background-color: #f9f9f9;
  }
`
const Button = styled.a`
  background: none;
  border: none;
  cursor: pointer;
`
const StepCircle = styled.div<StateProps>`
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  width: 34px;
  height: 34px;
  border-radius: 9999px;
  z-index: 1;
  position: relative;
  border-width: ${props => (props.state === StepState.ongoing || props.state === StepState.unactive ? '7px' : '0px')};
  border-style: solid;
  border-color: ${props => (props.state === StepState.ongoing ? '#9AD5FC' : props.ongoing ? '#BCBCBC' : '#f9f9f9')};
  background: #fff;
  background-image: url(${props =>
    props.state === StepState.passed
      ? props.ongoing
        ? stepCheckLight
        : stepCheck
      : props.state === StepState.failed
      ? props.ongoing
        ? stepFailLight
        : stepFail
      : null});
`
const StepLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin-top: 20px;
`
const StepTime = styled.div<StateProps>`
  margin-top: 6px;
  font-style: normal;
  font-weight: ${props => (props.ongoing ? '600' : 'normal')};
  font-size: 14px;
  line-height: 18px;
  color: ${props => (props.ongoing ? '#141414' : '#7f7f7f')};
`

const endInRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return 'Ended!'
  } else {
    return (
      <span>
        End in - {days}d:{hours}h:{minutes}m:{seconds.toString().padStart(2, '0')}s
      </span>
    )
  }
}

const startAtRenderer = ({ days, hours, minutes, seconds, completed }: any) => {
  if (completed) {
    return 'Started!'
  } else {
    return (
      <span>
        Start at - {days}d:{hours}h:{minutes}m:{seconds.toString().padStart(2, '0')}s
      </span>
    )
  }
}

const displayTime = (startTime: number, endTime: number) => {
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  if (!startTime) {
    return ''
  }
  if (dayjs().isAfter(dayjs.unix(endTime))) {
    return `Closed`
  } else {
    return `${dayjs.unix(startTime).format('YYYY-MM-DD HH:mm')} (UTC${
      dayjs().utcOffset() < 0 ? dayjs().utcOffset() / 60 : '+' + dayjs().utcOffset() / 60
    })`
  }
}

export const Step = ({ id, projectName }: { id: StepID; projectName: string }) => {
  const stepOrder = Object.keys(useProjectState(projectName).state) as StepID[]
  const thisStep = useProjectState(projectName).state[id]
  const lastStep = useProjectState(projectName).state[stepOrder[stepOrder.indexOf(id) - 1]]
  dayjs.extend(isBetween)
  const dispatch = useAppDispatch()

  return (
    <Li state={thisStep.state} ongoing={thisStep.ongoing}>
      <Button
        onClick={() => {
          dispatch(updateOpenSection({ id: id, open: true, projectName: projectName }))
          document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' })
        }}
      >
        <StepCircle state={thisStep.state} ongoing={thisStep.ongoing} />
      </Button>
      <StepLabel>
        {SwapStepName(id)}
        <StepTime ongoing={thisStep.ongoing}>
          {lastStep?.state !== StepState.failed && thisStep.ongoing ? (
            <Countdown date={new Date(thisStep.endTime * 1000)} renderer={endInRenderer} />
          ) : lastStep?.state !== StepState.failed &&
            dayjs().isBetween(dayjs.unix(lastStep?.endTime || 0), dayjs.unix(thisStep.startTime)) ? (
            <Countdown date={new Date(thisStep.startTime * 1000)} renderer={startAtRenderer} />
          ) : (
            displayTime(thisStep.startTime, thisStep.endTime)
          )}
        </StepTime>
      </StepLabel>
    </Li>
  )
}

export const StepsContainer = ({ children }: any) => {
  return (
    <div>
      <Nav>
        <Ol>{children}</Ol>
      </Nav>
    </div>
  )
}
