import React from 'react'
import styled from 'styled-components'
import { Box } from '@chakra-ui/react'
// TODO - ask about this component use for ?
export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  background: ${({ theme }) => theme.bg1};
  box-shadow: 0 10px 20px 15px rgba(0, 0, 0, 0.02);
  border-radius: 20px;
  padding: 1rem;
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children }: { children: React.ReactNode }) {
  return (
    <Box
      position="relative"
      maxWidth="420px"
      width="100%"
      bgColor="white"
      borderRadius="20px"
      p="24px"
      boxShadow="0px 0px 20px 0px #2325280D"
    >
      {children}
    </Box>
  )
}
