import React from 'react'
import styled, { css } from 'styled-components'
import dayjs from 'dayjs'
import Box from '../layout/Box'
import StatusLabel from '../StatusLabel'
import discordIcon from '../../../assets/svg/discord-logo.svg'
import twitterIcon from '../../../assets/svg/twitter-logo.svg'
import telegramIcon from '../../../assets/svg/telegram-logo.svg'
import { Project } from '../../../state/IDO/action'

const Root = styled(Box)`
  min-height: 320px;
  display: flex;
  flex-direction: column;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 2.25rem;
  margin-left: 1.25rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 16px 0;
  `}
`

const TitleGroup = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `}
`

const Descriptions = styled.ul`
  flex: 1;
  padding-left: 28px;
  & > li {
    line-height: 1.25rem;
    margin-top: 0.5rem;
  }
`

const Header = styled.div`
  display: flex;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 34px;

  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    margin-bottom: 38px;
  `}
`

const Icon = styled.img<{ size?: number }>`
  border-radius: 50%;
  ${({ size = 24 }) => css`
    width: ${size}px;
    height: ${size}px;
  `}
`

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
  `}
`

const Websites = styled.div`
  display: flex
  justify-content: center;
  margin-top: 30px;
`

const WebsiteLink = styled.a`
  display: inline-block;
  color: #0076e2;
  text-decoration: none;
  padding: 10px 30px;
  background: #f0f8ff;
  border-radius: 20px;
  margin: 0 10px;
  transition: all 0.2s;
  &:hover {
    opacity: 0.7;
  }
`

const SocialApps = styled.div`
  display: flex
  justify-content: center;
  margin-top: 30px;
`

const SocialAppLink = styled.a`
  display: inline-block;
  text-decoration: none;
  margin: 0 10px;
  width: 40px;
  height: 40px;
  background: #a8aeb4;
  border-radius: 10px;
  padding: 8px;
`

const MainBlock = ({
  iconUrl,
  name,
  status,
  start,
  end,
  descriptions,
  website,
  flowscanUrl,
  telegramUrl,
  discordUrl,
  twitterUrl
}: Project) => {
  const offset = (new Date().getTimezoneOffset() * -1) / 60
  const utc = `UTC${offset >= 0 ? '+' : '-'}${offset}`
  return (
    <Root>
      <Header>
        <TitleGroup>
          <Icon src={iconUrl} size={50} />
          <Title>{name} Launchpool</Title>
        </TitleGroup>
        <StatusLabel status={status}>{status}</StatusLabel>
      </Header>
      <Descriptions>
        <li>
          {start ? dayjs(new Date(start * 1000)).format('YYYY-MM-DD HH:mm') : '?'} ~{' '}
          {end ? dayjs(new Date(end * 1000)).format('YYYY-MM-DD HH:mm') : '?'} ({utc})
        </li>
        {descriptions.map(description => (
          <li key={description}>{description}</li>
        ))}
      </Descriptions>
      <Bottom>
        <Websites>
          <WebsiteLink href={website} target="_blank" rel="noopener noreferrer">
            Website
          </WebsiteLink>
          <WebsiteLink href={flowscanUrl} target="_blank" rel="noopener noreferrer">
            Flowscan
          </WebsiteLink>
        </Websites>
        <SocialApps>
          {telegramUrl && (
            <SocialAppLink href={telegramUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={telegramIcon} size={24} />
            </SocialAppLink>
          )}

          {discordUrl && (
            <SocialAppLink href={discordUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={discordIcon} size={24} />
            </SocialAppLink>
          )}

          {twitterUrl && (
            <SocialAppLink href={twitterUrl} target="_blank" rel="noopener noreferrer">
              <Icon src={twitterIcon} size={24} />
            </SocialAppLink>
          )}
        </SocialApps>
      </Bottom>
    </Root>
  )
}

export default MainBlock
