const script = `\
import BloctoIdo from 0xIDOADDRESS

access(all) fun main(name: String, addr: Address): BloctoIdo.UserInfo? {
	let activityOpt = BloctoIdo.getActivity(name)
	if activityOpt == nil {
		return nil
	}
	return activityOpt!.userInfos[addr]
}
`

export default script
