import { TokenInfo } from '../../types'
import { useMemo } from 'react'
import { useAllTokenBalances } from '../../state/wallet/hooks'

// compare two token amounts with highest one coming first
function balanceComparator(balanceA?: number, balanceB?: number) {
  if (balanceA && balanceB) {
    return balanceA > balanceB ? -1 : balanceA === balanceB ? 0 : 1
  } else if (balanceA && balanceA > 0) {
    return -1
  } else if (balanceB && balanceB > 0) {
    return 1
  }
  return 0
}

function getTokenComparator(balances: {
  [tokenAddress: string]: number | undefined
}): (tokenA: TokenInfo, tokenB: TokenInfo) => number {
  return function sortTokens(tokenA: TokenInfo, tokenB: TokenInfo): number {
    // -1 = a is first
    // 1 = b is first

    // sort by balances
    const balanceA = balances[tokenA.address]
    const balanceB = balances[tokenB.address]

    const balanceComp = balanceComparator(balanceA, balanceB)
    if (balanceComp !== 0) return balanceComp

    if (tokenA.symbol && tokenB.symbol) {
      // sort by symbol
      return tokenA.symbol.toLowerCase() < tokenB.symbol.toLowerCase() ? -1 : 1
    } else {
      return tokenA.symbol ? -1 : tokenB.symbol ? -1 : 0
    }
  }
}

export function useTokenComparator(inverted: boolean): (tokenA: TokenInfo, tokenB: TokenInfo) => number {
  const balances = useAllTokenBalances()
  const comparator = useMemo(() => getTokenComparator(balances ?? {}), [balances])
  return useMemo(() => {
    if (inverted) {
      return (tokenA: TokenInfo, tokenB: TokenInfo) => comparator(tokenA, tokenB) * -1
    } else {
      return comparator
    }
  }, [inverted, comparator])
}
