const scriptUSDT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedTetherToken from 0xTELEPORTEDUSDTADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: auth(BorrowValue) &Account) {
    let teleportUserRef = getAccount(0xTELEPORTUSDTADMINADDRESS).capabilities
        .borrow<&{TeleportedTetherToken.TeleportUser}>(/public/teleportedTetherTokenTeleportUser)
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedTetherToken.Vault>(from: /storage/teleportedTetherTokenVault)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- vault, to: targetAddress.decodeHex())
  }
}
`

const scriptREVV = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import REVV from 0xREVVTOKENADDRESS
import TeleportCustody from 0xREVVTELEPORTCUSTODYADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: auth(BorrowValue) &Account) {
    let teleportUserRef = getAccount(0xTELEPORTREVVADMINADDRESS).capabilities
        .borrow<&{TeleportCustody.TeleportUser}>(TeleportCustody.TeleportUserPublicPath)
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &REVV.Vault>(from: REVV.RevvVaultStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- (vault as! @REVV.Vault), to: targetAddress.decodeHex())
  }
}
`

const scriptSPRT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import TeleportedSportiumToken from 0xTELEPORTED_SPRT_ADDRESS

transaction(amount: UFix64, targetAddress: String) {
  prepare(signer: auth(BorrowValue) &Account) {
    let teleportUserRef = getAccount(0xTELEPORT_SPRT_ADMIN_ADDRESS).capabilities
        .borrow<&{TeleportedSportiumToken.TeleportUser}>(/public/TeleportedSportiumTokenTeleportUser)
        ?? panic("Could not borrow a reference to TeleportUser")

    let vaultRef = signer.storage.borrow<auth(FungibleToken.Withdraw) &TeleportedSportiumToken.Vault>(from: TeleportedSportiumToken.TokenStoragePath)
        ?? panic("Could not borrow a reference to the vault resource")

    let vault <- vaultRef.withdraw(amount: amount);

    teleportUserRef.teleportOut(from: <- vault, to: targetAddress.decodeHex())
  }
}
`

const scriptBLT = `\
import FungibleToken from 0xFUNGIBLETOKENADDRESS
import BloctoToken from 0xBLTADDRESS
import TeleportCustodyEthereum from 0xTELEPORT_ETHEREUM_BLT_ADDRESS

transaction(amount: UFix64, to: String) {

  let vault: @{FungibleToken.Vault}

  prepare(accu: auth(BorrowValue) &Account) {
    let blockTokenVaultRef = accu.storage.borrow<auth(FungibleToken.Withdraw) &BloctoToken.Vault>(from: BloctoToken.TokenStoragePath)
			?? panic("Could not borrow reference to the owner's Vault!")

    self.vault <- blockTokenVaultRef.withdraw(amount: amount)
  }

  execute {
    let teleportUserRef = getAccount(0xTELEPORTBLTADMINADDRESS).capabilities
      .borrow<&{TeleportCustodyEthereum.TeleportUser}>(TeleportCustodyEthereum.TeleportAdminTeleportUserPath) 
      ?? panic("failed to borrow TeleportCustodyEthereum.TeleportUser")
    teleportUserRef.lock(from: <- self.vault, to: to.decodeHex())
  }
}
`

const scripts: { [tokenName: string]: string } = {
  tUSDT: scriptUSDT,
  REVV: scriptREVV,
  SPRT: scriptSPRT,
  BLT: scriptBLT
}

export default scripts
